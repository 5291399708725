import React from 'react'
import styled, { css } from 'styled-components'
import { motion, useTransform, useMotionValue } from 'framer-motion'
import { breakpoints } from '../../../utils/breakpoints'

const List = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  transform-origin: 50% 100%;
  overflow: scroll;
`

const ArrowSection = styled.div<{ direction: 'left' | 'right' }>`
  ${({ direction }) => {
    const leftArrowStyle = css`
      background: -moz-linear-gradient(360deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: -webkit-linear-gradient(360deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(360deg, to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    `

    const rightArrowStyle = css`
      background: -moz-linear-gradient(
        -360deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 93%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
        -360deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 93%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        -360deg,
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 93%,
        rgba(255, 255, 255, 1) 100%
      );
    `

    return css`
      display: none;

      @media (${breakpoints.desktop.min}) {
        display: inherit;
        position: absolute;
        width: 100px;
        height: 100%;
        background: #000;
        z-index: 4;
        top: 0;
        bottom: 0;
        left: ${direction === 'left' ? 0 : 'inherit'};
        right: ${direction === 'right' ? 0 : 'inerith'};
        ${direction === 'left' ? leftArrowStyle : rightArrowStyle};
      }
    `
  }}
`

const Wrapper = styled.div`
  position: relative;
  overflow: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

interface Props {
  children: React.ReactNode
  isInfinite?: boolean
}

const Scroll = (props: Props) => {
  const { isInfinite = false, children } = props

  const x = useMotionValue(0)
  const input = [-100, 0, 100]

  const output = [1, 1, 0]
  const opacity = useTransform(x, input, output)

  return (
    <Wrapper>
      <List style={{ x, opacity }} transition={{ duration: 2, loop: Infinity }}>
        {children}
      </List>

      {isInfinite && (
        <>
          <ArrowSection direction="left" />
          <ArrowSection direction="right" />
        </>
      )}
    </Wrapper>
  )
}

export default Scroll
