const activeEnv = process.env.GATSBY_ACTIVE_ENV || ''
const excludedEnvironments = ['prod', 'production']

type LogType = 'trace' | 'debug' | 'info' | 'warn' | 'error'

const commonStyles = 'font-weight: bold;'

const logTypeParams = {
  trace: { label: '🔎 TRACE', style: `${commonStyles} color: #2969b3` },
  debug: { label: '🐞 DEBUG', style: `${commonStyles} color: #7627b3` },
  info: { label: '📓 INFO', style: `${commonStyles} color: #2f9e24` },
  warn: { label: '🚧 WARNING', style: `${commonStyles} color: #f59300` },
  error: { label: '🔥 ERROR', style: `${commonStyles} color: #ad0000` },
}

// eslint-disable-next-line no-console
const customLog = (label: string, styles: string, ...args: any[]) => console.log(`%c ${label}`, styles, ...args)

const devLog = (type: LogType) => (...args: any[]) =>
  excludedEnvironments.includes(activeEnv)
    ? () => null
    : customLog(logTypeParams[type].label, logTypeParams[type].style, ...args)

export const log = {
  trace: devLog('trace'),
  debug: devLog('debug'),
  info: devLog('info'),
  warn: devLog('warn'),
  error: devLog('error'),
}
