const utmParamNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export const useUtmQueryParams = () => {
  const params =
    typeof window !== 'undefined' && window.location.search ? new URLSearchParams(window.location.search) : null

  const utmQuery = utmParamNames
    .map((paramName) => ({ paramName, value: params?.get(paramName) }))
    .filter((utmParam) => utmParam.value)
    .reduce<string>((query, utmParam) => `${query}${utmParam.paramName}=${utmParam.value}&`, '')

  const appendUtmQuery = (url: string) => {
    if (utmQuery === '') return url
    return `${url}${url.includes('?') ? '' : '?'}${utmQuery ?? ''}`
  }
  return { utmQuery, appendUtmQuery }
}
