/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react'
import { getCartId, getOrderId } from '../../../utils/store'
import { SignInReturn, useAuth } from '../../../context/Auth'
import { Error } from '../../../types'
import handleError from './utils'

interface FormData {
  email: string
  password: string
}

interface Handlers {
  onSend?: () => void
  onError?: () => void
  onFinished?: (signIngResponse?: SignInReturn) => void
  onCloseModal?: () => void
  userLoginCartHandler?: (a: any) => Promise<void>
}

interface LoadingStates {
  loading?: boolean
  setLoading?: (isLoading: boolean) => void
  setIsLoadingUserData?: (isLoadingUserData: boolean) => void
}

type Props = Handlers & LoadingStates

const useSignIn = ({
  onFinished,
  onCloseModal = () => {},
  onSend = () => {},
  onError = () => {},
  setLoading,
  loading,
  setIsLoadingUserData,
  userLoginCartHandler,
}: Props) => {
  const [authError, setAuthError] = useState<Error | null>(null)
  const isMounted = useRef(true)

  const { onSignIn } = useAuth()

  const errorMessage = authError?.description

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  async function submit({ email, password }: FormData) {
    try {
      if (loading || !setLoading) return

      onSend()
      setLoading(true)
      setAuthError(null)

      const response = await onSignIn({ username: email, password }, false)
      const orderId = getOrderId()
      const cartId = getCartId()

      if (setIsLoadingUserData) setIsLoadingUserData(true)
      onCloseModal()

      if (userLoginCartHandler) {
        await userLoginCartHandler({
          orderId,
          cartId,
          customerEmail: response.data.email,
          onFinished,
        })
      } else if (onFinished) onFinished(response)
    } catch (err) {
      setAuthError(handleError(err, onError, email))
    } finally {
      if (isMounted.current && setLoading) {
        setLoading(false)
      }
    }
  }
  return { errorMessage, submit }
}

export default useSignIn
