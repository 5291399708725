import React from 'react'

const EyesCloseIcon = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>eye-close</title>
    <path d="M15.773 12l4.227 4.213c0-0.067 0-0.147 0-0.213 0-2.209-1.791-4-4-4-0.080 0-0.147 0-0.227 0zM10.040 13.067l2.067 2.067c-0.067 0.28-0.107 0.56-0.107 0.867 0 2.209 1.791 4 4 4 0.293 0 0.587-0.040 0.867-0.107l2.067 2.067c-0.893 0.44-1.88 0.707-2.933 0.707-3.682 0-6.667-2.985-6.667-6.667 0-1.053 0.267-2.040 0.707-2.933zM2.667 5.693l3.64 3.64c-2.2 1.733-3.933 4-4.973 6.667 2.307 5.853 8 10 14.667 10 2.067 0 4.040-0.4 5.84-1.12l0.573 0.56 3.893 3.893 1.693-1.693-23.64-23.64zM16 9.333c3.682 0 6.667 2.985 6.667 6.667 0 0.853-0.173 1.68-0.48 2.427l3.907 3.907c2-1.667 3.6-3.853 4.573-6.333-2.307-5.853-8-10-14.667-10-1.867 0-3.653 0.333-5.333 0.933l2.893 2.867c0.76-0.293 1.573-0.467 2.44-0.467z" />
  </svg>
)

export default EyesCloseIcon
