import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import {
  Button,
  Product,
  getAuth,
  TextField,
  StoredCustomer,
  useAuth,
  saveUserAvailabilitySkus,
  toCurrency,
  getStoredDistributionCenter,
} from '@ecommerce/shared'
import { Icon } from '../../../Icon/Icon'
import {
  Wrapper,
  ProductCard,
  ProductImage,
  ProductCardContent,
  ProductCardTitle,
  ProductCardPrice,
} from './NotifyModal.styled'

export interface NotifyModalProps {
  isSuccess?: boolean
  isLoading?: boolean
}

const emailSchema = yup.string().email('Debes ingresar un email valido')
const nameSchema = yup.string().matches(/^[a-zA-Z\u00C0-\u017F\u0027\s]+$/, 'Debe ingresar un nombre válido')

type FormValue = { value?: string; errorMessage?: string }

type Props = { product?: Product; onCancel?: () => void }

const NotifyModal = ({ product, onCancel }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [formValues, setFormValues] = useState<{ email?: FormValue; name?: FormValue }>()

  const isAuth = getAuth()
  const { state: authState, setAvailabilities } = useAuth()
  const currentDistributionCenter = getStoredDistributionCenter()

  const canSubmit = !isAuth
    ? !!formValues?.email?.value && !formValues?.email?.errorMessage && !!formValues?.name?.value
    : true

  const validateEmail = async () => {
    try {
      await emailSchema.validate(formValues?.email?.value)
      return true
    } catch (error) {
      setFormValues((prev) => ({
        ...prev,
        email: { ...(prev?.email ?? {}), errorMessage: (error as Error).message ?? 'Debe ingresar un email válido' },
      }))
      return false
    }
  }

  const validateName = async () => {
    try {
      await nameSchema.validate(formValues?.name?.value)
      return true
    } catch (error) {
      setFormValues((prev) => ({
        ...prev,
        name: { ...(prev?.name ?? {}), errorMessage: (error as Error).message ?? 'Debe ingresar un nombre válido' },
      }))
      return false
    }
  }

  const getPayload = (): StoredCustomer => {
    const customerName = isAuth ? `${authState.firstName} ${authState.lastName}` : formValues?.name?.value
    const customerEmail = isAuth ? authState.email : formValues?.email?.value
    const clayerId = authState.ownerId.length > 0 ? authState.ownerId : undefined

    if (!currentDistributionCenter?.slug || !customerName || !customerEmail) {
      throw new Error('city, customerName and customerEmail are required')
    }

    return {
      clayerId,
      slugLocation: currentDistributionCenter?.slug,
      name: customerName,
      email: customerEmail,
      favoriteSkus: authState.favoriteSkus,
      skusAvailabilityList: [Number(product?.skuCode)],
    }
  }

  const onAdd = async () => {
    try {
      if (!canSubmit) return
      if (isSuccess) {
        return onCancel ? onCancel() : null
      }

      if (!isAuth) {
        const emailIsValid = await validateEmail()
        const nameIsValid = await validateName()

        if (!nameIsValid || !emailIsValid) return
      }

      setIsLoading(true)
      setIsSuccess(false)
      setIsError(false)

      await saveUserAvailabilitySkus(getPayload())

      if (isAuth) {
        setAvailabilities([...new Set([...(authState?.availabilitySkus ?? []), Number(product?.skuCode)])])
      }

      setIsSuccess(true)
    } catch (error) {
      setIsSuccess(false)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isAuth) {
      onAdd()
    }
  }, [])

  const authMessage = () => {
    return (
      <div>
        <span>Te enviaremos un correo a </span>
        <span>{authState.email}</span>
        <span> cuando este producto vuelva a estar disponible en el sitio.</span>
      </div>
    )
  }

  const [resultHeading, resultText] = React.useMemo(() => {
    if (!isAuth && isSuccess) {
      return ['Ya tenemos tus datos', 'Te enviaremos un correo cuando este producto esté nuevamente disponible']
    }

    if (isAuth && isSuccess) {
      return ['', authMessage()]
    }

    if (isError) {
      return ['Ha habido un error agregando el producto', 'Por favor intenta de nuevo']
    }

    return [null, null]
  }, [isSuccess, isAuth, isError])

  const renderSubmitButton = () => {
    let text: string
    if (isSuccess) text = 'Seguir comprando'
    else text = 'Continuar'

    return (
      <Button
        className="buttons-continue"
        onClick={onAdd}
        isLoading={isLoading}
        isDisabled={isLoading || !product || !canSubmit}
      >
        {text}
      </Button>
    )
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = e.currentTarget

    setFormValues((prev) => ({ ...(prev ?? {}), [inputName]: { value } }))
  }

  const onPrevent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Wrapper
      isLoading={isLoading}
      isSuccess={isSuccess}
      onMouseUp={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => onPrevent(e)}
    >
      <div className="inner">
        <div className="top-modal">
          <button
            onClick={() => (onCancel ? onCancel() : null)}
            disabled={isLoading}
            type="button"
            title="Cerrar"
            className="close"
          >
            <Icon iconId="close" />
          </button>
          <p className="title">Notificarme cuando esté disponible</p>
        </div>
        {!isAuth && (isSuccess || isError) ? (
          <div className="result-indicator">
            <div>
              <Icon
                className={`is-${isSuccess ? 'success' : 'error'}`}
                iconId={isSuccess ? 'success' : 'error_outline'}
              />
            </div>
            <div>
              <p>{resultHeading}</p>
              <span>{resultText}</span>
            </div>
          </div>
        ) : null}
        {product && (
          <ProductCard>
            <ProductImage alt={product.title} src={product.thumbnail ?? product.image} />
            <ProductCardContent>
              <ProductCardTitle>{product.title}</ProductCardTitle>
              <div className="product-card-bottom">
                <ProductCardPrice>{toCurrency(product.price > 1 ? product.price : 0)}</ProductCardPrice>
                <Button
                  className="product-card-button"
                  onMouseUp={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  disabled
                  isDisabled
                >
                  Agregar
                </Button>
              </div>
            </ProductCardContent>
          </ProductCard>
        )}
        {!isAuth && (
          <div className="guest-form">
            <TextField
              onChange={onInputChange}
              className="guest-form-input"
              placeholder="Nombre"
              name="name"
              errorMessage={formValues?.name?.errorMessage}
              status={formValues?.name?.errorMessage ? 'error' : undefined}
              disabled={isSuccess}
              autoComplete="off"
            />
            <TextField
              onChange={onInputChange}
              className="guest-form-input"
              placeholder="Correo electrónico"
              name="email"
              errorMessage={formValues?.email?.errorMessage}
              status={formValues?.email?.errorMessage ? 'error' : undefined}
              disabled={isSuccess}
              autoComplete="off"
              autoCapitalize="nope"
            />
          </div>
        )}
        {isAuth && isSuccess ? <div className="result-auth">{resultText}</div> : null}
        <div className="buttons">
          {renderSubmitButton()}

          {!isAuth && !isSuccess && (
            <Button
              onClick={() => (onCancel && !isLoading ? onCancel() : null)}
              isDisabled={isLoading}
              btnType={!isAuth && isSuccess ? 'primary' : 'secondary'}
            >
              {isSuccess ? 'Cerrar' : 'Cancelar'}
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default NotifyModal
