import { AxiosResponse } from 'axios'

import customerBffFetch from './customer-bff-fetch'
import { Country, CustomerGroup } from '../../..'

export const getCustomerGroup = async ({ customerGroupId, country }: { customerGroupId: string; country: Country }) => {
  const { data }: AxiosResponse<CustomerGroup> = await customerBffFetch(
    `/customer-group/${country}/${customerGroupId}`,
    {
      method: 'GET',
    },
  )
  return data
}
