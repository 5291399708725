import { useEffect } from 'react'
import {
  useLocation,
  Market,
  DistributionCenter,
  Zone,
  setStoredDistributionCenter,
  setStoredZone,
  setStoredMarket,
  getStoredMarket,
  getStoredZone,
} from '@ecommerce/shared'
import { getMarketByZone } from '@ecommerce/chile-customer-webapp/src/config/siteBuild/utils'

export interface PageProps {
  pageContext?: {
    markets?: Market[]
    currentZone?: Zone
    currentMarket?: Market
    currentDistributionCenter?: DistributionCenter
    distributionCenters?: DistributionCenter[]
  }
  location?: {
    pathname: string
  }
}
type Fetcher<T> = () => Promise<T | undefined>
type Setter<T> = (value: T) => void

export const useInitCitiesInContext = (pageProps: PageProps) => {
  const { setMarkets, setZones, setDistributionCenters } = useLocation()

  const getQueryZone = () => {
    if (typeof window !== 'undefined') {
      const query = new URLSearchParams(window.location.search)
      return query.get('city')
    }
    return null
  }

  const findZoneByName = (zones: Zone[], zoneName: string): Promise<Zone | undefined> => {
    return Promise.resolve(zones.find((zone) => zone.name === zoneName))
  }

  const findDistributionCenterById = (
    distributionCenters: DistributionCenter[],
    distributionCenterId: number,
  ): Promise<DistributionCenter | undefined> => {
    return Promise.resolve(
      distributionCenters.find((distributionCenter) => distributionCenter.id === distributionCenterId),
    )
  }

  const tryFetchAndStore = async <T,>(fetcher: Fetcher<T>, setter: Setter<T>) => {
    const item = await fetcher()
    if (item === undefined) return false
    setter(item)
    return true
  }
  const setStoredLocationFromQueryParams = async (props: PageProps) => {
    const zoneName = getQueryZone()
    if (!zoneName) return

    if (!(await tryFetchAndStore(() => getMarketByZone(props.pageContext?.markets || [], zoneName), setStoredMarket)))
      return

    const storedMarket = getStoredMarket()
    if (!storedMarket) return

    if (!(await tryFetchAndStore(() => findZoneByName(storedMarket.zones, zoneName), setStoredZone))) return

    const storedZone = getStoredZone()
    if (!storedZone) return

    await tryFetchAndStore(
      () => findDistributionCenterById(storedMarket.distributionCenters, storedZone.distributionCenterId),
      setStoredDistributionCenter,
    )
  }

  const pageContextHasMarkets = !!(
    pageProps.pageContext &&
    pageProps.pageContext.markets &&
    pageProps.pageContext.markets.length
  )
  useEffect(() => {
    if (pageContextHasMarkets) {
      const distributionCenters = pageProps.pageContext?.markets?.map((market) => market.distributionCenters)
      if (distributionCenters) {
        setDistributionCenters(distributionCenters.flat())
      }
      setMarkets(pageProps.pageContext?.markets || [])
      const zones = pageProps.pageContext?.markets
        ?.map((market) =>
          market?.zones.map((zone) => {
            return {
              ...zone,
              market: market.name,
              marketId: market.id,
              marketName: market.name,
            }
          }),
        )
        .flat()
      if (zones) {
        const hashZones = zones.reduce((acc, current) => {
          /* @ts-ignore */
          acc[current.name] = current
          return acc
        }, {})
        setZones(hashZones)
      }
    }
  }, [])

  useEffect(() => {
    if (getQueryZone()) {
      setStoredLocationFromQueryParams(pageProps)
    }
  }, [getQueryZone])
}
