import { ProductCart, Product } from '../types'
import { log } from './log'
import { toCurrencyNumber } from './i18n'
import { capitalize } from './format'
import { ShoppingCartState } from '../context/ShoppingCart/context'

export interface EventGMT extends Record<string, unknown | undefined> {
  event: string
  pageTitle?: string
  pageUrl?: string
  actionField?: Record<string, any>
}

const ecommerceClearEvent = {
  ecommerce: null,
}

const formatActionFieldObject = (actionField: Record<string, any>) => {
  const list = actionField.list ? { item_list_name: capitalize(actionField.list, true) } : {}
  const option = actionField.option ? { option: capitalize(actionField.option, true) } : {}
  return { ...actionField, ...list, ...option }
}

type BannerEventParams = {
  campaign: string
  position: number
  city: string
}

declare let window: Window & {
  dataLayer: { push: (eventObj: EventGMT | typeof ecommerceClearEvent) => void } | undefined
}

function dataLayerPush(data: EventGMT, logLabel?: string) {
  log.info(logLabel ?? 'dataLayer.push', data)
  if (window && window.dataLayer) {
    window.dataLayer.push(ecommerceClearEvent)
    window.dataLayer.push(data)
  }
}

export function sendDataToGTM({ pageUrl = window.location.href, ...restEvent }: EventGMT) {
  dataLayerPush({ ...restEvent, pageUrl }, `sendDataToGTM - ${restEvent.event}`)
}

export function sendDataWithTitleToGTM({ pageUrl = window.location.href, ...restEvent }: EventGMT, label?: string) {
  dataLayerPush(
    { ...restEvent, pageUrl, pageTitle: document.title },
    `${label ?? 'sendDataWithTitleToGTM'} - ${restEvent.event}`,
  )
}

export function sendProductsToGTM(args: EventGMT & { products: ProductCart[] }) {
  const { pageUrl = window.location.href, products, event, actionField, ...restEvent } = args

  dataLayerPush(
    {
      event: actionField?.event ?? event,
      ecommerce: {
        ...restEvent,
        pageUrl,
        items: products.map((product) => ({
          item_name: product.title,
          item_id: product.skuCode,
          price: product.price,
          item_brand: product.brandName,
          item_category: product.categoryName ?? null,
          item_category2: product.packing ?? null,
          item_category3: product.tags ?? null,
          item_variant: product.size ?? null,
          quantity: product.quantity ?? 1,
        })),
      },
    },
    `sendProductsToGTM - ${actionField?.event ?? event}`,
  )
}

export function sendCheckoutEventToGTM(
  products: ProductCart[],
  state: ShoppingCartState,
  isBolivia: boolean,
  actionField?: EventGMT['actionField'],
) {
  sendProductsToGTM({
    event: 'checkout',
    products,
    currency: isBolivia ? 'BOB' : 'CLP',
    value: toCurrencyNumber(state.globalRawTotal - (state.globalTotalPromotion ?? 0), isBolivia),
    coupon: state.couponCode,
    ...(actionField ? { actionField: formatActionFieldObject(actionField) } : {}),
  })
}

export function sendAddProductToGTM(product: Product, isBolivia: boolean, quantity?: number) {
  sendDataWithTitleToGTM({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: product.title,
          item_id: product.skuCode,
          price: toCurrencyNumber(product.price, isBolivia),
          item_brand: product.brandName,
          item_category: product.categoryName ?? null,
          item_category2: product.packing ?? null,
          item_category3: product.tags ?? null,
          item_variant: product.size ?? null,
          quantity: quantity ?? 1,
        },
      ],
    },
  })
}

export function sendRemoveProductToGTM(products: (Product & { quantity?: number })[], isBolivia: boolean) {
  sendDataWithTitleToGTM({
    event: 'remove_from_cart',
    ecommerce: {
      items: products.map((product) => ({
        item_name: product.title,
        item_id: product.skuCode,
        price: toCurrencyNumber(product.price, isBolivia),
        item_brand: product.brandName,
        item_category: product.categoryName ?? null,
        item_category2: product.packing ?? null,
        item_category3: product.tags ?? null,
        item_variant: product.size ?? null,
        quantity: product.quantity ?? 1,
      })),
    },
  })
}

export function sendSelectItemToGTM(product: Product, productListName: string, isBolivia: boolean) {
  sendDataWithTitleToGTM({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_name: product.title,
          item_id: product.skuCode,
          price: toCurrencyNumber(product.price, isBolivia),
          item_brand: product.brandName,
          item_category: product.categoryName ?? null,
          item_category2: product.packing ?? null,
          item_category3: product.tags ?? null,
          item_variant: product.size ?? null,
          item_list_name: capitalize(productListName, true),
        },
      ],
    },
  })
}

export function sendViewItemToGTM(product: Product, isBolivia: boolean) {
  sendDataWithTitleToGTM({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: product.title,
          item_id: product.skuCode,
          price: toCurrencyNumber(product.price, isBolivia),
          item_brand: product.brandName,
          item_category: product.categoryName ?? null,
          item_category2: product.packing ?? null,
          item_category3: product.tags ?? null,
          item_variant: product.size ?? null,
        },
      ],
    },
  })
}

export function sendViewItemListToGTM(
  args: Partial<EventGMT> & { products: Product[]; currency: string; list?: string },
) {
  const { products, currency, list, ...restEvent } = args

  dataLayerPush(
    {
      event: 'view_item_list',
      ecommerce: {
        item_list_name: list ? capitalize(list, true) : null,
        ...restEvent,
        ...(currency ? { currency } : {}),
        items: products.map((product) => ({
          item_name: product.title,
          item_id: product.skuCode,
          price: product.price ?? null,
          item_brand: product.brandName ?? null,
          item_category: product.categoryName ?? null,
          item_category2: product.packing ?? null,
          item_category3: product.tags ?? null,
          item_variant: product.size ?? null,
          ...(list ? { item_list_name: capitalize(list, true) } : {}),
        })),
      },
    },
    `sendViewItemListToGTM`,
  )
}

export function sendViewPromotionToGTM(promotions: { item_id: string; item_name: string; creative_name: string }[]) {
  sendDataWithTitleToGTM(
    {
      event: 'view_promotion',
      ecommerce: {
        items: promotions,
      },
    },
    'sendViewPromotionToGTM',
  )
}

export function sendSelectPromotionToGTM(promotion: {
  item_id: string
  item_name: string
  creative_name: string
  position: string
}) {
  sendDataWithTitleToGTM(
    {
      event: 'select_promotion',
      ecommerce: {
        promotion_name: promotion.item_name,
        items: [promotion],
      },
    },
    'sendSelectPromotionToGTM',
  )
}

export const sendBannerEventToGTM = ({ campaign, position, city }: BannerEventParams) => {
  return sendDataToGTM({
    event: 'banner',
    'b-campaña': campaign,
    'b-pos': position,
    'b-ciudad': city,
  })
}
