/* eslint-disable @typescript-eslint/no-empty-function  */
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useState } from 'react'
import { sendRegisterEvent } from '@ecommerce/chile-customer-webapp/src/utils/events'
import { signUp } from '../../../services/SignUp'
import { FormData } from './index'
import { Country, Error as ErrorObject } from '../../../types'
import { getBaseUrl, handleError, redirectUrl } from './utils'
import { getMarkets } from '../../../services/Location/cities'
import { useLocation } from '../../../context/Location'

interface Handlers {
  onSend?: () => void
  onError?: () => void
  onFinished?: (data: FormData) => void
  onFormatRedirectURL?: (data: FormData) => string
  onClickSignUpConfirm?: () => void
}

interface LoadingStates {
  loading?: boolean
  preHome?: boolean
  showConfirmation?: boolean
}

type Props = Handlers & LoadingStates

const useSignUp = ({
  onSend = () => {},
  onError = () => {},
  onFinished = () => {},
  onFormatRedirectURL,
  onClickSignUpConfirm = () => {},
  loading,
  preHome,
  showConfirmation = true,
}: Props) => {
  const { isBolivia } = useLocation()

  // TODO: Quitar el recaptcha
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [error, setError] = useState<ErrorObject | null>(null)
  const [formData, setFormData] = useState({})
  const [confirmationEmail, setConfirmationEmail] = useState('')
  const [renderConfirmation, setRenderConfirmation] = useState(false)
  const [loadingConfirmation, setLoadingConfirmation] = useState(false)

  const handleSuccessfulRegistration = (data: FormData) => {
    setFormData(data)
    if (showConfirmation) setRenderConfirmation(true)
    setConfirmationEmail(data.email)
    onClickSignUpConfirm()
    onFinished(data)
  }

  const handleRegistrationError = (err: any, data: FormData) => {
    handleError(err, data)
    setError({ code: '400', description: 'Error al crear usuario' })
  }
  const submit = async (data: FormData) => {
    if (loading) return
    if (!executeRecaptcha) throw Error('cannot execute Recaptcha')
    if (error) setError(null)
    onSend()

    const {
      firstName,
      lastName,
      dni,
      email,
      password,
      city,
      market,
      birthdate,
      dniComplement,
      documentType,
      billingName,
    } = data

    try {
      setLoadingConfirmation(true)
      const reCaptchaToken = await executeRecaptcha('signup')

      await signUp({
        firstName,
        lastName,
        rut: dni,
        email,
        password,
        city,
        market,
        birthdate,
        dniComplement,
        documentType,
        billingName,
        redirectUrl: redirectUrl(preHome, getBaseUrl, onFormatRedirectURL, data),
        reCaptchaToken,
      })

      handleSuccessfulRegistration(data)

      const { data: markets } = await getMarkets({ country: isBolivia() ? Country.BOLIVIA : Country.CHILE })
      const selectedMarket = markets.find((mk) => mk.id === parseInt(data?.market.toString(), 10))
      const selectedZone = selectedMarket?.zones.find((zone) => zone.id === parseInt(data?.city.toString(), 10))

      sendRegisterEvent(null, {
        primaryKey: data.email,
        _name: `${data.firstName} ${data.lastName}`,
        _email: data.email,
        _birthdate: data.birthdate,
        _city: selectedZone?.name,
        _dni: data.dni,
        invitado: false,
        region: selectedMarket?.name,
        comuna: selectedZone?.name,
      })
    } catch (err) {
      handleRegistrationError(err, data)
    } finally {
      setLoadingConfirmation(false)
      onError()
    }
  }

  return {
    error,
    formData,
    confirmationEmail,
    renderConfirmation,
    loadingConfirmation,
    submit,
  }
}

export default useSignUp
