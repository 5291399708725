import {
  useShoppingCart,
  getProductsBySkus,
  log,
  Market,
  slugify,
  getStoredDistributionCenter,
  useCart,
} from '@ecommerce/shared'

export const useCartRefresh = () => {
  const {
    state: { byHash },
    addProduct,
    empty: emptyCart,
  } = useShoppingCart()

  const { setDeletedProducts } = useCart()

  const refreshCart = async (newMarket?: Market) => {
    const currentDistributionCenter = getStoredDistributionCenter()
    const cart = { ...byHash }
    const skus = Object.keys(cart)
    if (!newMarket || !skus || !skus.length) return
    const refreshedProducts = await getProductsBySkus({
      slugLocation: slugify(currentDistributionCenter?.commerceLayer.stockLocation.name ?? ''),
      skus,
    })
    emptyCart()

    refreshedProducts.map((product) => {
      if (product.hasStock) {
        log.trace('replacing product ', product.title)
        return addProduct({ product, quantity: cart[product.skuCode].quantity })
      }
      return null
    })

    const deleteProducts = refreshedProducts.filter((product) => !product.hasStock)
    setDeletedProducts(deleteProducts)
  }
  return { refreshCart }
}
