import styled from 'styled-components'
import { Image } from '@ecommerce/shared'
import { breakpoints } from '@ecommerce/shared/src/utils/breakpoints'
import { modalMountAnimation } from '../utils'
import { NotifyModalProps } from '.'

export const Wrapper = styled.div<NotifyModalProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${modalMountAnimation} 0.1s linear forwards;
  height: 100vh;

  .inner {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius};
    display: flex;
    flex-direction: column;
    max-width: 341px;
    width: 100%;
    min-height: 320px;
    position: relative;
    margin: 0 16px;
    gap: 27px;
  }

  .top-modal {
    padding: 29px 30.5px 0 30.5px;
  }

  .close {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 30px;
    right: 30px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    margin: 0;
    margin-top: 16px;
    font-weight: 700;
    font-size: 24px;
    max-width: 250px;
  }

  .product-card-button {
    width: 100%;
  }

  .buttons {
    padding: 0 30.5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 27px;

    &-continue {
      background: ${({ isSuccess, theme }) => isSuccess && theme.colors.red};
    }

    button {
      padding: 0 25px;
    }
  }

  .result-indicator {
    font-weight: 400;
    display: flex;
    gap: 15px;
    margin: 0 30.5px;

    p {
      font-size: 16px;
      margin: 0;
      width: auto;
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.platinum80};
      display: block;
      width: 100%;
    }

    svg {
      width: 24px;
      height: 24px;

      &.is-success {
        fill: ${({ theme }) => theme.colors.green};
      }

      &.is-error {
        fill: ${({ theme }) => theme.colors.error};
      }
    }
  }

  .result-auth {
    margin: 0 30.5px;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black};
    span:nth-child(2) {
      font-weight: bold;
    }
  }

  .guest-form {
    margin: 0 30.5px;

    &-input {
      margin-bottom: 18px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .inner {
      min-width: 435px;
      width: 435px;
    }

    .top-modal {
      padding: 29px 47.5px 0 47.5px;
    }

    .guest-form,
    .result-auth,
    .result-indicator {
      margin: 0 44px;
    }

    .title {
      max-width: unset;
      margin-top: 0;
    }

    .buttons {
      flex-direction: row;
      justify-content: center;
      gap: 30px;

      button {
        min-width: 140px;
      }
    }
  }
`

export const ProductCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 175px;
  padding: 15px 16px 15px 12px;
  margin: 0 auto;
`

export const ProductImage = styled(Image)`
  width: 50%;
`

export const ProductCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 13px;
  width: calc(100% - 145px);
`

export const ProductCardTitle = styled.p`
  color: ${({ theme }) => theme.colors.platinum80};
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: -0.06px;
  height: 42px;
  margin: 0;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`

export const ProductCardPrice = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.25px;
  margin: 0 5px;
`
