import { ByHash, TotalsObject } from '../types'

export const calculateCartTotals = (byHash: ByHash) => {
  return Object.values(byHash).reduce(
    (totals, line) => {
      return {
        globalQuantity: totals.globalQuantity + line.quantity,
        globalRawTotal: totals.globalRawTotal + line.rawPrice * line.quantity,
        globalTotal: totals.globalTotal + line.price * line.quantity,
        globalTotalDiscounted: totals.globalTotalDiscounted + (line.rawPrice - line.price) * line.quantity,
        globalCouponDiscounted: totals.globalCouponDiscounted,
      }
    },
    { globalQuantity: 0, globalRawTotal: 0, globalTotal: 0, globalTotalDiscounted: 0, globalCouponDiscounted: 0 },
  ) as TotalsObject
}

export const percentageToAmount = (percent: number, total: number) => {
  return Math.round((percent * total) / 100)
}
