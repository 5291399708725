import React, { useState } from 'react'
import loadable from '@loadable/component'
import {
  createOrderAndGetId,
  getCustomerLastFilteredOrder,
  setOrderId,
  updateOrderMetadata,
  useShoppingCart,
  useLocation,
  getCustomerEmail,
  ShoppingCartModel,
  useFirestore,
  CleanCLOrder,
  log,
  getStoredMarket,
  setOrderCustomer,
  Country,
  getStoredDistributionCenter,
} from '@ecommerce/shared'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../utils/sentry'

const ConfirmationAlert = loadable(() => import('../components/ConfirmationAlert'))

const updateMetadataAndSetCustomer = async ({
  orderId,
  cartId,
  customerEmail,
  country,
}: {
  orderId: string
  cartId: string
  customerEmail: string
  country: Country
}) =>
  Promise.all([
    updateOrderMetadata({ orderId, metadata: { cartId }, country }),
    setOrderCustomer({ orderId, customerEmail }),
  ])

export const useUserLoginHandler = () => {
  const [userLoginAlertState, setUserLoginAlertState] = useState<{ isOpen: boolean }>({ isOpen: false })
  const [lastCustomerOrderCartId, setLastCustomerOrderCartId] = useState('')
  const [lastCustomerOrderId, setLastCustomerOrderId] = useState('')
  const [storeCartId, setStoreCartId] = useState('')
  const {
    state: { country },
  } = useLocation()

  const { createShoppingCartAndSetId, setCartId } = useShoppingCart()
  const { fetchFsShoppingCart, getLastValidActiveCartByIdList } = useFirestore()
  const createShoppingCart = async (orderId: string) => {
    try {
      const id = await createShoppingCartAndSetId()
      await updateOrderMetadata({ orderId, metadata: { cartId: id }, country })
    } catch (e) {
      log.error('ERROR', e.message)
      if (e && e.code === 'permission-denied') return
      sendMessageToSentry({
        message: `Firestore: Failed to create cart`,
        page: 'global - useUserLoginHandler',
        source: ErrorSource.Firestore,
        level: ErrorLevel.Error,
        metadata: {
          error: e,
        },
      })
    }
  }

  const openChooseCartAlert = (localCartId: string, clayerOrderId: string, clayerCartId: string) => {
    setStoreCartId(localCartId)
    setLastCustomerOrderId(clayerOrderId)
    setLastCustomerOrderCartId(clayerCartId)
    setUserLoginAlertState({ isOpen: true })
  }

  const createOrderAndSetCartId = async (cartId: string, customerEmail: string) => {
    const orderId = await createOrderAndGetId()
    setOrderId(orderId)
    await updateMetadataAndSetCustomer({ orderId, customerEmail, country, cartId })
  }

  const cartAuthHandler = async (
    localCartId: string,
    clayerOrderId: string,
    clayerCartId: string,
    customerEmail: string,
  ) => {
    const getCartData = async (cartId: string): Promise<[boolean, boolean]> => {
      const doc = await fetchFsShoppingCart(cartId)
      if (!doc) return [false, false]
      const data = doc.data() as ShoppingCartModel
      const { exists } = doc
      const hasSkus = exists ? Object.entries(data.byHash).length > 0 : false
      return [exists, hasSkus]
    }
    const [localCartExists, localCartHasSkus] = await getCartData(localCartId)
    const [clayerCartExists, clayerCartHasSkus] = await getCartData(clayerCartId)

    setOrderId(clayerOrderId)

    if (localCartExists && clayerCartExists) {
      if (localCartHasSkus && clayerCartHasSkus) {
        openChooseCartAlert(localCartId, clayerOrderId, clayerCartId)
        return
      }
      if (localCartHasSkus && !clayerCartHasSkus) {
        setCartId(localCartId)
        await updateMetadataAndSetCustomer({
          orderId: clayerOrderId,
          cartId: localCartId,
          country,
          customerEmail,
        })
      }
      if (!localCartHasSkus && clayerCartHasSkus) {
        setCartId(clayerCartId)
        return
      }
      if (!localCartHasSkus && !clayerCartHasSkus) {
        await updateMetadataAndSetCustomer({
          orderId: clayerOrderId,
          cartId: localCartId,
          country,
          customerEmail,
        })
        return
      }
    }
    if (clayerCartExists && !localCartExists) {
      setCartId(clayerCartId)
    }
    if (!clayerCartExists && localCartExists) {
      await updateMetadataAndSetCustomer({
        orderId: clayerOrderId,
        cartId: localCartId,
        country,
        customerEmail,
      })
      return
    }
    if (!clayerCartExists && !localCartExists) {
      await createShoppingCart(clayerOrderId)
    }
  }

  const userLoginCartHandler = async (params: {
    orderId?: string
    cartId?: string
    customerEmail: string
    onFinished?: () => Promise<void>
  }) => {
    try {
      const { orderId, cartId, customerEmail, onFinished } = params

      const finish = async () => {
        if (onFinished) await onFinished()
      }

      if (cartId) setStoreCartId(cartId)

      const email = customerEmail ?? getCustomerEmail()

      const currentCity = getStoredMarket()
      const currentDistributioncenter = getStoredDistributionCenter()

      const orders = await getCustomerLastFilteredOrder(
        email,
        currentDistributioncenter?.commerceLayer.market.number || 0,
        country,
      )
      log.trace('last customer orders:', orders)

      let order: CleanCLOrder | null = null

      try {
        const lastActivePendingCart = await getLastValidActiveCartByIdList(
          orders.pending.map((pendingOrder) => pendingOrder.metadata?.cartId || ''),
        )
        log.trace('Last active pending cart:', lastActivePendingCart)
        if (lastActivePendingCart) {
          order =
            orders.pending.find((pendingOrder) => pendingOrder.metadata?.cartId === lastActivePendingCart.id) ?? null
          log.info('Last Active cart: ', lastActivePendingCart)
        } else {
          const lastActiveDraftCart = await getLastValidActiveCartByIdList(
            orders.draft.map((draftOrder) => draftOrder.metadata?.cartId || ''),
          )
          log.trace('Last draft cart:', lastActiveDraftCart)
          if (lastActiveDraftCart) {
            log.info('Last Active cart: ', lastActiveDraftCart)
            order = orders.draft.find((draftOrder) => draftOrder.metadata?.cartId === lastActiveDraftCart.id) ?? null
          }
        }
      } catch (e) {
        log.error(e)
        sendMessageToSentry({
          message: `Firestore: on user login get carts`,
          page: 'global - useUserLoginHandler',
          source: ErrorSource.Firestore,
          level: ErrorLevel.Warning,
          metadata: {
            error: e,
          },
        })
      }

      log.info('Last Active order: ', order)

      if (cartId && orderId) {
        if (!order) {
          log.trace(`Cart recovery branch 1`)
          await createOrderAndSetCartId(cartId, email ?? getCustomerEmail())
          finish()
          return
        }

        const orderCartId = order?.metadata?.cartId

        if (orderCartId) {
          if (orderCartId !== cartId) {
            log.trace('Cart recovery branch 2')
            await cartAuthHandler(cartId, order.id, orderCartId, customerEmail ?? getCustomerEmail())
            finish()
            return
          }
          log.trace('Cart recovery branch 3')
          setOrderId(order.id)
          finish()
          return
        }
        log.trace('Cart recovery branch 3')
        setOrderId(order.id)
        await updateMetadataAndSetCustomer({
          orderId: order.id,
          cartId,
          country,
          customerEmail,
        })
        finish()
        return
      }
      if (orderId && !cartId) {
        if (!order) {
          log.trace('Cart recovery branch 5')
          await createShoppingCart(orderId)
          finish()
          return
        }
        const orderCartId = order?.metadata?.cartId

        if (orderCartId) {
          log.trace('Cart recovery branch 6')
          setCartId(orderCartId)
          setOrderId(order.id)
          finish()
          return
        }
        log.trace('Cart recovery branch 7')
        await createShoppingCart(order.id)
        finish()
        return
      }
      if (!orderId && cartId) {
        if (!order) {
          log.trace('Cart recovery branch 8')
          await createOrderAndSetCartId(cartId, email ?? getCustomerEmail())
          finish()
          return
        }
        const orderCartId = order?.metadata?.cartId

        if (orderCartId) {
          if (orderCartId !== cartId) {
            log.trace('Cart recovery branch 9')
            await cartAuthHandler(cartId, order.id, orderCartId, customerEmail ?? getCustomerEmail())
            finish()
            return
          }
          log.trace('Cart recovery branch 10')
          setOrderId(order.id)
          finish()
          return
        }
        log.trace('Cart recovery branch 11')
        setOrderId(order.id)
        await updateMetadataAndSetCustomer({
          orderId: order.id,
          cartId,
          country,
          customerEmail,
        })
        finish()
        return
      }
      if (!orderId && !cartId) {
        if (!order) {
          log.trace('Cart recovery branch 12')
          const id = await createOrderAndGetId()
          setOrderId(id)
          await createShoppingCart(id)
          finish()
          return
        }

        const orderCartId = order?.metadata?.cartId

        if (orderCartId) {
          log.trace('Cart recovery branch 13')
          setCartId(orderCartId)
          setOrderId(order.id)
          finish()
          return
        }
        log.trace('Cart recovery branch 14')
        setOrderId(order.id)
        await createShoppingCart(order.id)
        finish()
      }
    } catch (e) {
      sendMessageToSentry({
        message: `Firestore: on user login`,
        page: 'global - useUserLoginHandler',
        source: ErrorSource.Firestore,
        level: ErrorLevel.Error,
        metadata: {
          error: e,
        },
      })
    }
  }

  const onChooseCart = async (option: 'clayer' | 'firestore', onFinished?: () => void) => {
    if (option === 'clayer') {
      if (lastCustomerOrderCartId !== '' && lastCustomerOrderId !== '') {
        setOrderId(lastCustomerOrderId)
        setCartId(lastCustomerOrderCartId)
      }
    }
    if (option === 'firestore') {
      if (lastCustomerOrderId !== '' && storeCartId !== '') {
        setOrderId(lastCustomerOrderId)
        await updateOrderMetadata({ orderId: lastCustomerOrderId, metadata: { cartId: storeCartId }, country })
      }
    }
    setUserLoginAlertState({ isOpen: false })
    if (onFinished) onFinished()
  }

  const UserLoginHandlerAlertComponent = (props: { onFinished?: () => void }) => (
    <>
      {userLoginAlertState.isOpen && (
        <ConfirmationAlert
          onCancel={() => onChooseCart('clayer', props.onFinished)}
          onConfirm={() => onChooseCart('firestore', props.onFinished)}
          confirmButtonText="Mantener carrito actual"
          cancelButtonText="Recuperar de sesión anterior"
          text="Tienes un carrito en una sesión previa, ¿Con cuál deseas continuar?"
        />
      )}
    </>
  )
  return {
    userLoginAlertState,
    setUserLoginAlertState,
    UserLoginHandlerAlertComponent,
    userLoginCartHandler,
  }
}
