/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, ReactNode, useCallback, useMemo } from 'react'

interface CartStatusState {
  isLoadingTotalPrice: boolean
  isLoadingSuggestedSkus: boolean
  errorLoadingSuggestedSkus: boolean
}

const initialState = {
  isLoadingTotalPrice: false,
  isLoadingSuggestedSkus: false,
  errorLoadingSuggestedSkus: false,
}

type Action =
  | { type: 'SET_IS_LOADING_TOTAL_PRICE'; isLoading: boolean }
  | { type: 'SET_IS_LOADING_SUGGESTED_SKUS'; isLoading: boolean }
  | { type: 'SET_ERROR_LOADING_SUGGESTED_SKUS'; hasError: boolean }

const cartStatusReducer = (state: CartStatusState, action: Action) => {
  switch (action.type) {
    case 'SET_IS_LOADING_TOTAL_PRICE': {
      return {
        ...state,
        isLoadingTotalPrice: action.isLoading,
      }
    }
    case 'SET_IS_LOADING_SUGGESTED_SKUS': {
      return {
        ...state,
        errorSuggestedSkus: false,
        isLoadingSuggestedSkus: action.isLoading,
      }
    }
    case 'SET_ERROR_LOADING_SUGGESTED_SKUS': {
      return {
        ...state,
        errorSuggestedSkus: true,
        isLoadingSuggestedSkus: false,
      }
    }
    default: {
      return state
    }
  }
}

interface CartStatusContextProps {
  isLoadingTotalPrice: boolean
  isLoadingSuggestedSkus: boolean
  errorLoadingSuggestedSkus: boolean
  setIsLoadingTotalPrice: (isLoading: boolean) => void
  setIsLoadingSuggestedSkus: (isLoading: boolean) => void
  setErrorLoadingSuggestedSkus: (hasError: boolean) => void
}

export const CartStatusContext = React.createContext<CartStatusContextProps>({
  isLoadingSuggestedSkus: initialState.isLoadingSuggestedSkus,
  isLoadingTotalPrice: initialState.isLoadingTotalPrice,
  errorLoadingSuggestedSkus: initialState.errorLoadingSuggestedSkus,
  setIsLoadingTotalPrice: () => {},
  setIsLoadingSuggestedSkus: () => {},
  setErrorLoadingSuggestedSkus: () => {},
})

CartStatusContext.displayName = 'StatusContext'

export const CartStatusProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(cartStatusReducer, initialState)

  const setIsLoadingTotalPrice = useCallback((isLoading: boolean) => {
    dispatch({ type: 'SET_IS_LOADING_TOTAL_PRICE', isLoading })
  }, [])

  const setIsLoadingSuggestedSkus = useCallback((isLoading: boolean) => {
    dispatch({ type: 'SET_IS_LOADING_SUGGESTED_SKUS', isLoading })
  }, [])

  const setErrorLoadingSuggestedSkus = useCallback((hasError: boolean) => {
    dispatch({ type: 'SET_ERROR_LOADING_SUGGESTED_SKUS', hasError })
  }, [])

  const value = useMemo<CartStatusContextProps>(
    () => ({
      ...state,
      setIsLoadingSuggestedSkus,
      setIsLoadingTotalPrice,
      setErrorLoadingSuggestedSkus,
    }),
    [state],
  )

  return <CartStatusContext.Provider value={value}>{children}</CartStatusContext.Provider>
}

export const useCartStatus = () => {
  const context = React.useContext(CartStatusContext)
  if (Object.values(context).some((value) => value === undefined)) {
    throw new Error('useCartStatus must be used within a CartStatusProvider')
  }
  return context
}
