import styled from 'styled-components'
import { hexToRGBA, toCssPrefix } from '../../../utils/format'

export const { toPrefix, cssPrefix } = toCssPrefix('FormGuest__')

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  .${cssPrefix} {
    &input {
      margin-bottom: 16px;
    }
    &status {
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-weight: 325;
      margin-bottom: 24px;
      span {
        margin-right: 8px;
      }
    }
    &submit {
      margin: 16px 0 8px 0;
    }
  }
`
export const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 8px;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
`

export const Subtitle = styled.span`
  font-size: 13px;
  font-weight: 325;
  margin-bottom: 32px;
`

export const CheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 16px;
  width: 100%;

  .CheckboxOption__ {
    &label {
      margin: 0;
      font-size: 12px;
    }
    &input {
      margin-right: 12px;
    }
    &anchor {
      text-decoration-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.black};
    }
  }
  span {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.platinum80};
    font-size: 13px;
  }
`
