export enum ReturnabilityType {
  'NONE' = 'NONE',
  'RETURNABLE' = 'RETURNABLE',
  'RETURNABLE_PLUS_BOTTLE' = 'RETURNABLE_PLUS_BOTTLE',
  'RETURNABLE_PLUS_BOTTLE_PLUS_BOX' = 'RETURNABLE_PLUS_BOTTLE_PLUS_BOX',
}

// Tag of returnabilityLabel attribute in Algolia
export const DISPONSABLE_TAG_CL = 'DESECHABLE'
export const DISPONSABLE_TAG_BO = 'DESECHABLE'
export const RETURNABLE_TAG_CL = 'RETORNA TU ENVASE'
export const RETURNABLE_TAG_BO = 'RETORNA TU BOTELLA'
export const RETURNABLE_PLUS_BOTTLE_TAG_CL = 'PRODUCTO + ENVASE'
export const RETURNABLE_PLUS_BOTTLE_TAG_BO = 'PRODUCTO + BOTELLA'
export const RETURNABLE_PLUS_BOTTLE_PLUS_BOX_TAG_CL = 'PRODUCTO + ENVASE + JABA'
export const RETURNABLE_PLUS_BOTTLE_PLUS_BOX_TAG_BO = 'PRODUCTO + BOTELLA + CAJA'

const types: Record<ReturnabilityType, string[]> = {
  [ReturnabilityType.NONE]: [''],
  [ReturnabilityType.RETURNABLE]: [RETURNABLE_TAG_CL, RETURNABLE_TAG_BO],
  [ReturnabilityType.RETURNABLE_PLUS_BOTTLE]: [RETURNABLE_PLUS_BOTTLE_TAG_CL, RETURNABLE_PLUS_BOTTLE_TAG_BO],
  [ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX]: [
    RETURNABLE_PLUS_BOTTLE_PLUS_BOX_TAG_CL,
    RETURNABLE_PLUS_BOTTLE_PLUS_BOX_TAG_BO,
  ],
}

type TGetReturnability = {
  returnabilityLabel: string | undefined
}

export const getReturnabilityType = ({ returnabilityLabel }: TGetReturnability): ReturnabilityType => {
  if (!returnabilityLabel) {
    return ReturnabilityType.NONE
  }

  const matchingType = Object.entries(types).find(([, value]) => value.includes(returnabilityLabel))

  return matchingType ? (matchingType[0] as ReturnabilityType) : ReturnabilityType.NONE
}
