import { navigate } from 'gatsby'

/**
 * Handle network errors and redirect to error page
 */
const networkErrorHandler = async (): Promise<void> => {
  if (typeof window === 'undefined') return
  if (window.navigator.onLine) return

  const exclude = /(checkout)/g

  if (!window.location.pathname.match(exclude)) {
    await navigate('/network-error/')
  }
}

export default networkErrorHandler
