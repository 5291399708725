import React from 'react'
import styled, { css } from 'styled-components'
import { hexToRGBA } from '../../../utils/format'

type Props = {
  title: string
  icon: React.ReactNode
  buttonIcon?: React.ReactNode
  onButtonClick?: (args: unknown) => unknown
  className?: string
  isButtonDisabled?: boolean
}

const StyledCardTitle = styled.div<{ isButtonDisabled: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  min-height: 60px;
  background: ${({ theme }) => hexToRGBA(theme.colors.grey60, 0.5)};
  width: 100%;

  svg {
    margin-right: 16px;
    width: 30px;
    height: 30px;

    path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.black};
    font-size: 18px;
    width: 100%;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    width: 25px;
    height: 25px;
    margin-right: 8px;

    ${({ isButtonDisabled }) =>
      !isButtonDisabled &&
      css`
        cursor: pointer;
      `}

    svg {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const CardTitle = ({ title, icon, buttonIcon, onButtonClick, className, isButtonDisabled = false }: Props) => (
  <StyledCardTitle className={className} isButtonDisabled={isButtonDisabled}>
    {icon}
    <span>{title}</span>
    {buttonIcon ? (
      <button type="button" onClick={onButtonClick}>
        {buttonIcon}
      </button>
    ) : null}
  </StyledCardTitle>
)

export default CardTitle
