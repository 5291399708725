import { AxiosPromise } from 'axios'
import fetch from './fetch'

const headers = {
  Accept: 'application/vnd.api+json',
}

const patchHeaders = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
}

interface GetCustomerResponse {
  data: {
    id: string
    attributes: {
      email: string
      metadata: {
        dni: string
        firstName: string
        lastName: string
        birthdate: string
        city: number
        market: number
        registered: boolean
        favoriteSkus?: number[]
        documentType: string
        billingName: string
        dniComplement: string
        isUpdated: boolean
      }
    }
    relationships: {
      customer_group?: {
        data: {
          id: string
        }
      }
    }
  }
}

interface CustomerAttributes {
  email?: string
  password?: string
  metadata?: {
    dni?: string
    firstName?: string
    lastName?: string
    birthdate?: string
    city?: number
    market?: number
    registered?: boolean
    documentType?: string
    billingName?: string
    dniComplement?: string
    isUpdated?: boolean
  }
}

export function getCustomer(params: { ownerId: string }): AxiosPromise<GetCustomerResponse> {
  return fetch(`/api/customers/${params.ownerId}?include=customer_group`, {
    headers: { ...headers },
    method: 'GET',
  })
}

export function updateCustomer(params: {
  ownerId: string
  customer: CustomerAttributes
}): AxiosPromise<GetCustomerResponse> {
  return fetch(`/api/customers/${params.ownerId}`, {
    headers: patchHeaders,
    method: 'PATCH',
    data: {
      data: {
        type: 'customers',
        id: params.ownerId,
        attributes: {
          ...params.customer,
        },
      },
    },
  })
}

const customerApi = {
  getCustomer,
  updateCustomer,
}

export default customerApi
