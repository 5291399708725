import React from 'react'
import { Product, ProductPromotion } from '../../../types'
import { toCurrency } from '../../../utils/i18n'
import { percentageToAmount } from '../../../utils/prices'
import { PromotionTagStyled } from './styled'

interface Props {
  product: Product
  promotion?: ProductPromotion
  className?: string
  type: string
}

export const PromotionTag = ({ product, promotion, className, type }: Props) => {
  const useRawPrice = promotion?.isRaw && product.rawPrice > 0

  let discountText = promotion?.productCardLabel

  if (discountText === '$calculatedPrice' && promotion?.discountPercentage) {
    const amount = percentageToAmount(promotion?.discountPercentage, useRawPrice ? product.rawPrice : product?.price)

    discountText = toCurrency(amount)
  }

  return <PromotionTagStyled className={className} promo={discountText} tag="promo" type={type} />
}
