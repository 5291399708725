import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoints } from '../../../utils/breakpoints'

export type StyleProps = {
  align?: 'center' | 'left' | 'right'
  className?: string
}

const PromotionHelpText = styled.p<StyleProps>`
  font-size: 11px;
  width: 160px;
  color: ${({ theme }) => theme.colors.error};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  order: 3;
  ${({ align = 'center' }) => {
    let margin = `4px auto`

    if (align === 'left') margin = `4px 0 0`

    return css`
      margin: ${margin};
    `
  }}

  svg {
    margin-right: 3px;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    ${({ align = 'center' }) => {
      let margin = '4px auto -22px;'

      if (align === 'left') margin = '6px 0 0 8px;'

      return css`
        margin: ${margin};
      `
    }}
  }
`

type Props = {
  text: string
} & StyleProps

export default ({ text, align, className = '' }: Props) => {
  return (
    <PromotionHelpText className={className} align={align}>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10ZM5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
          fill="#9C0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7.29999C5.25642 7.29999 5.46775 7.10697 5.49664 6.8583L5.5 6.79999V4.99999C5.5 4.72385 5.27614 4.49999 5 4.49999C4.74358 4.49999 4.53225 4.69301 4.50336 4.94168L4.5 4.99999V6.79999C4.5 7.07613 4.72386 7.29999 5 7.29999Z"
          fill="#9C0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.0045 3.70001C5.28064 3.70001 5.5045 3.47615 5.5045 3.20001C5.5045 2.94359 5.31148 2.73226 5.06281 2.70338L5.0045 2.70001C4.72386 2.70001 4.5 2.92387 4.5 3.20001C4.5 3.45643 4.69302 3.66777 4.94169 3.69665L5.0045 3.70001Z"
          fill="#9C0000"
        />
      </svg>
      {text}
    </PromotionHelpText>
  )
}
