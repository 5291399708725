import { useState } from 'react'
import { FormattedAddress, DistrictState } from '../types'
import config from '../config'
import { Market } from '../services/Location/cities'

export const parseMarketData = (markets: Market[], market: string) => {
  const data = markets.filter((mk) => mk.name === market)[0]

  return { market: data?.name, locations: data?.zones ?? [] }
}

type Dispatcher = React.Dispatch<React.SetStateAction<DistrictState>>
type Context = 'checkout' | 'my-addresses'

type Params = {
  market: Market
  editingAddress: FormattedAddress | null
  context?: Context
  markets: Market[]
}

type Return = {
  districtState: DistrictState
  setDistrictState: Dispatcher
  resetDistrictState: () => void
}

type UseDistricts = ({ ...params }: Params) => Return

export const useDistricts: UseDistricts = ({ editingAddress, market, context = 'checkout', markets }) => {
  const regions = parseMarketData(markets, editingAddress?.marketName ?? market.name)
  const zone = market.zones.filter((z) => z.id === editingAddress?.zoneId)[0]

  const [districtState, setDistrictState] = useState<DistrictState>({
    checkZone: regions !== undefined,
    hasError: false,
    selectedZone: zone,
  })

  const resetDistrictState = () => setDistrictState((prev) => ({ ...prev, selectedZone: undefined }))

  return { districtState, setDistrictState, resetDistrictState }
}
