import { AxiosPromise } from 'axios'
import fetch, { headers } from './fetch'
import { CLRetrieveResponse } from './types'

interface AddPaymentSourceParams {
  orderId: string
  paymentOptionId: number
  referenceOrigin?: string
  reference?: string
}

export function addPaymentSource({
  orderId,
  paymentOptionId,
  referenceOrigin,
  reference,
}: AddPaymentSourceParams): AxiosPromise<CLRetrieveResponse> {
  const data = {
    data: {
      type: 'wire_transfers',
      attributes: {
        reference_origin: referenceOrigin,
        reference,
        metadata: {
          paymentOptionId,
        },
      },
      relationships: {
        order: {
          data: {
            type: 'orders',
            id: orderId,
          },
        },
      },
    },
  }
  return fetch('api/wire_transfers', { data, headers, method: 'POST' })
}
