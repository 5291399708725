import { css } from 'styled-components'

export const hasScrollbarStyles = css`
  overflow: auto;
  scrollbar-width: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 100%;
    display: initial;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbb;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
`
