import { createGlobalStyle } from 'styled-components'
import GothamBookWOFF from '../fonts/Gotham-Book.woff'
import GothamBookWOFF2 from '../fonts/Gotham-Book.woff2'
import GothamBoldWOFF from '../fonts/Gotham-Bold.woff'
import GothamBoldWOFF2 from '../fonts/Gotham-Bold.woff2'

export default createGlobalStyle`
  @font-face {
    font-family: 'Gotham';
    src: local('Gotham'),
      url(${GothamBookWOFF2}) format('woff2'),
      url(${GothamBookWOFF}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: local('Gotham'), 
      url(${GothamBoldWOFF2}) format('woff2'),
      url(${GothamBoldWOFF}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
  }
`
