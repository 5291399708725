import { createGlobalStyle, css } from 'styled-components'
import { breakpoints } from '../utils/breakpoints'
import { baseInput } from './baseForms'

const styles = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 16px;
  }

  body {
    margin: 0;
    height: 100%;
    width: 100%;

    .global-container {
      @media (${breakpoints.desktop.min}) {
        max-width: 1440px;
      }
    }

    .grecaptcha-badge {
      visibility: hidden;
      display: none;
    }

    * {
      /*  Scrollbar  */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    span,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label,
    button,
    input,
    input::placeholder,
    textarea,
    textarea::placeholder,
    table,
    ul,
    div,
    ol,
    li {
      font-family: 'Gotham', sans-serif;
    }

    button {
      outline: none;
    }

    input {
      overflow: visible;
      margin: 0;
      padding: 0;
    }
  }
`

export default createGlobalStyle`${styles}`
