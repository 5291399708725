import { combineReducers } from '@reduxjs/toolkit'
import filters from './filterSlice'
import search from './searchStateSlice'
import promotions from './promotionStateSlice'
import returnability from './returnabilityStateSlice'

const reducer = combineReducers({
  filters,
  search,
  promotions,
  returnability,
})

export default reducer
