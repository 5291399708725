export enum PromotionType {
  PRICE_REDUCTION = 'price_reduction',
  PERCENTAGE_DISCOUNT = 'percentage_discount',
  AMOUNT_DISCOUNT = 'amount_discount',
  MXN = 'mxn',
  MXPRICE = 'mxprice',
  X_UNIT = 'x_unit',
  A_DISCOUNTS_B = 'a_discounts_b',
  A_PERCENTAGE_DISCOUNTS_B = 'a_percentage_discounts_b',
  GIFT_CARD = 'gift_card',
}

export enum PromotionStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  EXPIRED = 'expired',
}

export enum PromotionInteractionType {
  CUMULATIVE = 'cumulative',
  NON_CUMULATIVE = 'non_cumulative',
  UNIQUE = 'unique',
}

export type StoredPromotion = {
  uuid: string
  slugLocations: string[]
  discountPercentage?: number
  name: string
  isRaw?: boolean
  shoppingCartLabel?: string
  catalogDetail?: string
  skus: number[]
  discountSkus?: number[]
  startsAt: string
  expiresAt: string
  startsAtTime: number
  exp: number
  type: PromotionType
  interactionType?: PromotionInteractionType
  coupons?: string[]
  activationQuantity?: number
  discountQuantity?: number
  maxActivationsPerOrder?: number
  minActivationAmount?: number
  maxDiscountAmount?: number
  usageLimit: number
  productCardLabel?: string
  productDetailTitle?: string
  productDetailDescription?: string
  productDetailInfoText?: string
  couponsUsageLimit?: number
  couponsPerCustomerUsageLimit?: number
  discountUnits?: number
  useAmountDiscountLabel?: boolean
  status: PromotionStatus
  commerceLayerIds?: string[]
}

export type CLayerExternalDiscountRequestBody = {
  data: CLayerExternalDiscountRequestOrder
  included: CLayerExternalDiscountInclude[]
}

export type CLayerExternalDiscountRequestOrder = {
  id: string
  attributes: {
    total_amount_cents: number
    subtotal_amount_cents: number
    discount_amount_cents: number
    total_taxable_amount_cents: number
    coupon_code?: string
    metadata?: Record<string, any>
  }
}

export type CLayerExternalDiscountInclude = CLayerLineItemInclude | CLayerMarketInclude

export type CLayerLineItemInclude = CLayerInclude<
  'line_items',
  {
    sku_code: string
    name: string
    quantity: number
    unit_amount_cents: number
    discount_breakdown?: { [key: string]: { cents: number; weight: number } }
    metadata: {
      price?: number
      originalPrice?: number
      isGift?: boolean
    }
    item_type: string | 'skus'
  }
>

export type CLayerMarketInclude = CLayerInclude<
  'markets',
  { number: number; metadata: Record<string, string | number> }
>

export type CLayerInclude<Type, Attributes> = {
  id: string
  type: Type
  attributes: Attributes
}

export type OrderFormatter<T> = (
  params: T,
) => {
  lineItems: FormattedLineItem[]
  relatedPromotions: StoredPromotion[]
}

export type FormattedLineItem = {
  sku: string
  name: string
  price: number
  originalPrice?: number
  quantity: number
  discountQuantity?: number
  isGift?: boolean
  id?: string
}

export type EventQuoteCities = {
  id: string
  name: string
}