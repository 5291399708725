/**
 * Format date
 * @param date - FORMAT: Y-M-D
 */
export const toWeekDay = (date: string) => {
  // Split date
  const [year, month, day] = date.split('-')

  // Parse to int
  const parsedYear = parseInt(year)
  const parsedMonth = parseInt(month)
  const parsedDay = parseInt(day)

  // Parse to date
  const parsed = new Date(parsedYear, parsedMonth - 1, parsedDay)

  // Set label
  let label = parsed.toLocaleDateString('es-cl', { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '')
  label = `${label[0].toUpperCase()}${label.slice(1)}`

  return { label, weekDay: parsed.getDay() }
}

const msToSeconds = 1000
const msToMinutes = msToSeconds * 60
const msToHours = msToMinutes * 60
const msToDays = msToHours * 24

export const getTimeToDate = (targetDate: Date) => {
  const diff = new Date(targetDate).valueOf() - new Date().valueOf()
  const days = Math.floor(diff / msToDays)
  const hours = Math.floor((diff % msToDays) / msToHours)
  const minutes = Math.floor((diff % msToHours) / msToMinutes)
  const seconds = Math.floor((diff % msToMinutes) / msToSeconds)
  return { days, hours, minutes, seconds, diff }
}
