/*  eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useState } from 'react'
import { ErrorLevel, ErrorSource, sendMessageToSentry } from '@ecommerce/chile-customer-webapp/src/utils/sentry'
import { getCartId, getOrderId, getStoredMarket } from '../../../utils/store'
import { FormGuestData } from './index'
import { CLOrder } from '../../../services/CommerceLayer'
import { useAuth } from '../../../context/Auth'
import { useLocation } from '../../../context/Location'
import { Market } from '../../../services/Location/cities'
import { Error as ErrorObject } from '../../../types'

interface Handlers {
  onError?: () => void
  onFinished: (metadata?: Partial<CLOrder['attributes']['metadata']>, email?: string) => Promise<void>
  setIsLoadingUserData?: (isLoadingUserData: boolean) => void
}

interface States {
  loading?: boolean
  willCheckout?: boolean
  currentCity?: Market
}

type Props = Handlers & States

const useGuestSignUp = ({
  onError,
  onFinished,
  loading,
  willCheckout,
  currentCity = undefined,
  setIsLoadingUserData,
}: Props) => {
  const {
    state: { country },
  } = useLocation()

  const { onGuest, setGuest } = useAuth(country)

  const [authError, setAuthError] = useState<ErrorObject | null>(null)

  const handleOnError = (error: any, data: FormGuestData) => {
    const cartId = getCartId()
    const city = getStoredMarket()
    const orderId = getOrderId()

    setAuthError({
      code: error?.message,
      status: 400,
      metadata: error,
      description: 'Ha habido un error, por favor intenta de nuevo.',
    })

    sendMessageToSentry({
      page: 'shared-formCheckoutGuest',
      message: `onGuest request error: ${error?.message}`,
      source: ErrorSource.CLayer,
      level: ErrorLevel.Error,
      metadata: {
        ...error,
        params: {
          ...data,
          willCheckout: !!willCheckout,
          currentCity,
        },
        cartId,
        city,
        orderId,
      },
    })

    if (onError) onError()
  }

  const handleOnGuest = async (data: FormGuestData) => {
    const { email, firstName, lastName, dni, birthdate, documentType, billingName, dniComplement } = data
    const metadata = { firstName, lastName, dni, birthdate, documentType, billingName, dniComplement }
    try {
      if (setIsLoadingUserData) setIsLoadingUserData(true)
      if (willCheckout) {
        await onFinished(metadata, email)
        setGuest({ firstName, lastName })
      } else {
        await onGuest({
          firstName,
          lastName,
          rut: dni,
          email,
          birthdate,
          documentType,
          billingName,
          dniComplement,
          willCheckout: !!willCheckout,
          currentCity,
        })
        await onFinished(metadata, email)
      }
    } catch (error) {
      handleOnError(error as Error, data)
    }
  }

  const submit = useCallback(
    async (data: FormGuestData) => {
      window.scrollTo(0, 0)
      setAuthError(null)
      if (!loading && onFinished) {
        try {
          await handleOnGuest(data)
        } catch (error) {
          handleOnError(error as Error, data)
        }
      }
    },
    [onFinished, loading, willCheckout, currentCity, onError],
  )

  return { authError, submit }
}

export default useGuestSignUp
