import { useState, useEffect } from 'react'

export const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(typeof window === 'undefined' ? 0 : window.innerHeight)
  const hasWindow = typeof window !== 'undefined'
  useEffect(() => {
    if (!hasWindow) return
    const onResize = () => {
      setViewportHeight(window.innerHeight)
    }
    window.removeEventListener('resize', onResize)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [hasWindow])
  return { viewportHeight, viewportHeightCss: viewportHeight ? `${viewportHeight}px` : '100vh' }
}
