import { AxiosResponse } from 'axios'
import fetch from './fetch'
import { CLSkuListResponse } from '../CommerceLayer/types'
import secrets from '../../config'

const { COUNTRY } = secrets

export const getPricedSku = async (sku: string, marketNumber: number) => {
  const response: AxiosResponse<CLSkuListResponse> = await fetch(
    `/sku?country=${COUNTRY}&marketNumber=${marketNumber}&skuCode=${sku}&include=prices`,
  )
  return response
}

export const getSkuByCode = async (sku: string, marketNumber: number) => {
  const response: AxiosResponse<CLSkuListResponse> = await fetch(
    `/sku?country=${COUNTRY}&marketNumber=${marketNumber}&skuCode=${sku}`,
  )
  return response
}

export const getSuggestedSkus = async (skus: string[], zoneName: string) => {
  const response: AxiosResponse<string[]> = await fetch(
    `/suggested-skus?country=${COUNTRY}&skuCodes=${skus}&zoneName=${zoneName}`,
  )
  return response
}
