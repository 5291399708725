import { navigate, PageProps } from 'gatsby'
import { toHash } from '@ecommerce/shared'

const utmParamNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export const navigateWithUTMQueryParams = ({
  location,
  prevLocation,
}: {
  location: PageProps['location']
  prevLocation: PageProps['location']
}) => {
  const prevSearch: { name: string; value: string }[] = []
  new URLSearchParams(location && location.search ? location.search : '').forEach((val, key) => {
    prevSearch.push({ name: key, value: val })
  })
  const utmParams = toHash(
    prevSearch.filter((param) => utmParamNames.includes(param.name)),
    'name',
  )
  const newSearch: { name: string; value: string }[] = []
  new URLSearchParams(prevLocation && prevLocation.search ? prevLocation.search : '').forEach((val, key) => {
    newSearch.push({ name: key, value: val })
  })
  const restParams = toHash(newSearch, 'name')
  if (
    prevLocation &&
    prevLocation.pathname &&
    Object.keys(utmParams).length &&
    !Object.keys(restParams).some((paramName) => utmParamNames.includes(paramName))
  ) {
    const newQuery = Object.values({ ...utmParams, ...restParams }).reduce(
      (query, param) => `${query}&${param.name}=${param.value}`,
      '?',
    )
    navigate(`${prevLocation.pathname}${newQuery}`, { replace: true })
  }
}
