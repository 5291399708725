import * as jwt from 'jsonwebtoken'

type BaseTokenPayload = { exp: number }

export const decodeToken = <Payload = BaseTokenPayload>(token: string) => {
  return jwt.decode(token) as Payload
}

export const checkTokenExpiration = (token: string): boolean => {
  const decoded = decodeToken(token)
  const tokenExpirationDate = new Date((decoded?.exp ?? 1) * 1000).getTime()

  return tokenExpirationDate <= Date.now()
}
