import { graphql, useStaticQuery } from 'gatsby'

export interface ContentfulLocationSelectorMessageQueryResult {
  allContentfulGlobal: {
    edges: { node: { locationSelectorMessage: string } }[]
  }
}

const extractMessage = (queryResult: ContentfulLocationSelectorMessageQueryResult) => {
  if (!queryResult.allContentfulGlobal.edges.length) return { locationSelectorMessage: '' }
  return {
    locationSelectorMessage: queryResult.allContentfulGlobal.edges[0].node.locationSelectorMessage ?? '',
  }
}

export const useLocationSelectorMessageQuery = () => {
  return extractMessage(
    useStaticQuery(graphql`
      query LocationSelectorMessageQuery {
        allContentfulGlobal {
          edges {
            node {
              locationSelectorMessage
            }
          }
        }
      }
    `),
  )
}
