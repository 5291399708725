import React from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import AlertMessage from '@ecommerce/chile-customer-webapp/src/components/AlertMessage'
import Button from '../../atoms/Button'
import TextField from '../../molecules/TextField'
import { ALink, Wrapper, WrapperNoAccount, toPrefix, Title, Subtitle, Recovery, Separator } from './styled'
import useEnterKeyPress from '../../../hooks/useEnterKeyPress'
import { SignInReturn } from '../../../context/Auth'
import useSignIn from './useSignIn'

interface FormData {
  email: string
  password: string
}

const schema = Yup.object().shape({
  email: Yup.string().email('El formato de email no es el correcto').required('Debes completar este campo'),
  password: Yup.string().required('Debes completar este campo'),
})

export interface Props {
  onFinished: ((signIngResponse?: SignInReturn) => void) | ((signInResponse?: SignInReturn) => Promise<void>)
  onClickResetPassword: (e: React.MouseEvent) => void
  onClickSignUp?: (e: React.MouseEvent) => void
  showNoAccount?: boolean
  userLoginCartHandler?: (a: any) => Promise<void>
  onCloseModal?: () => void
  onSend?: () => void
  onError?: () => void
  setLoading?: (isLoading: boolean) => void
  loading?: boolean
  isCheckout?: boolean
  setIsLoadingUserData?: (isLoadingUserData: boolean) => void
  useModal?: boolean
}

const FormSignIn = (props: Props) => {
  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const { submit, errorMessage } = useSignIn({
    onFinished: props.onFinished,
    onCloseModal: props.onCloseModal,
    onSend: props.onSend,
    onError: props.onError,
    setLoading: props.setLoading,
    loading: props.loading,
    setIsLoadingUserData: props.setIsLoadingUserData,
    userLoginCartHandler: props.userLoginCartHandler,
  })

  const { onClickSignUp, onClickResetPassword, showNoAccount = true, loading, isCheckout } = props

  const watchFields = watch()

  const isSubmitDisabled = !watchFields.email || !watchFields.password || !!Object.keys(errors).length

  const onButtonClick = (event: React.MouseEvent, onClick?: (e: React.MouseEvent) => void) => {
    if (!loading) {
      if (onClick) onClick(event)
    }
  }

  useEnterKeyPress(() => handleSubmit(submit))

  return (
    <>
      <Wrapper onSubmit={handleSubmit(submit)}>
        {!props.useModal && <Title>Iniciar sesión</Title>}
        <Subtitle>Si ya tienes una cuenta ingresa iniciando sesión</Subtitle>
        {errorMessage && (
          <AlertMessage type="error" className={toPrefix('status')}>
            {errorMessage}
          </AlertMessage>
        )}
        <TextField
          className={toPrefix('input')}
          label="Correo electrónico"
          placeholder="Ingresa tu correo electrónico"
          name="email"
          type="email"
          status={!errors.email ? undefined : 'error'}
          errorMessage={errors.email?.message}
          ref={register}
          autoCapitalize="nope"
        />
        <TextField
          className={toPrefix('input')}
          label="Contraseña"
          placeholder="Ingresa tu contraseña"
          name="password"
          type="password"
          status={!errors.password ? undefined : 'error'}
          errorMessage={errors.password?.message}
          ref={register}
        />
        <Recovery>
          <div>
            <span>¿Olvidaste tu contraseña?</span>
            <ALink
              data-testid="reset-password"
              disabled={loading}
              onClick={(e) => onButtonClick(e, onClickResetPassword)}
            >
              Recuperar
            </ALink>
          </div>
        </Recovery>
        <Button
          data-test="signin-submit"
          whileTap={loading ? {} : { scale: 0.9 }}
          type="submit"
          isDisabled={loading || isSubmitDisabled}
          isLoading={loading}
        >
          Iniciar sesión
        </Button>
      </Wrapper>
      {showNoAccount && onClickSignUp ? (
        <WrapperNoAccount>
          <Separator />
          <span>¿No tienes una cuenta?</span>
          <Button
            data-test="signin-signup"
            className={toPrefix('submit transparent')}
            whileTap={loading ? {} : { scale: 0.9 }}
            type="submit"
            btnType="secondary"
            isDisabled={false}
            disabled={loading}
            onClick={(e) => onButtonClick(e, onClickSignUp)}
          >
            Crea tu cuenta
          </Button>
        </WrapperNoAccount>
      ) : null}
    </>
  )
}

export default FormSignIn
