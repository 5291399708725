import React, { ReactNode } from 'react'
import ErrorPage from '../templates/ErrorPage'
import { sendMessageToSentry, ErrorLevel } from '../utils/sentry'

export default class ErrorBoundary extends React.Component<{}, { error: any | null }> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: any, errorInfo: { [x: string]: any }) {
    this.setState({ error })

    sendMessageToSentry({
      message: `ErrorBoundary: ${error?.message}`,
      level: ErrorLevel.Critical,
      tags: { errorBoundary: `true` },
      metadata: { ...(errorInfo ?? {}), error },
    })
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage
          leftContent={{
            title: 'Error',
            text: 'Ha ocurrido un error en el servidor.',
          }}
          image="https://images.ctfassets.net/16npdkkoi5mj/36n0Vlu1TuVrFRs2IbgOO2/406a7d94d4f4d705911f9c29418232cb/error-500-mesa-de-trabajo-1.svg?h=250"
        />
      )
    }

    return this.props.children
  }
}
