import { TagType, useResolution } from '@ecommerce/shared'
import React from 'react'
import { Icon } from '../Icon/Icon'
import { BlackTagText, FridayTagText, TagText, TagWrapper } from './styled'

interface TagProps {
  tag: string
  type?: string
  className?: string
  promo?: string
}

export const Tag = ({ tag, type, className, promo }: TagProps) => {
  const { isMobile } = useResolution()

  const tagValue = TagType[tag as keyof typeof TagType]
  const getIcon = () => {
    switch (tagValue) {
      case TagType.new:
        return 'new'
      case TagType.cyber:
        return 'cyber'
      case TagType.outstanding:
        return 'outstanding'
      case TagType.black_friday:
        return 'black-friday'
      default:
        return 'top-selling'
    }
  }

  const getIconSize = (): number => (isMobile ? 12 : 16)

  const getTitleTag = () => tagValue

  const blackFridayTag = () => {
    if (tag !== 'black_friday') return null
    const blackFridayText = tagValue.split(' ')
    return (
      <>
        <BlackTagText type={type}>{blackFridayText[0]}</BlackTagText>
        <FridayTagText type={type}>{blackFridayText[1]}</FridayTagText>
      </>
    )
  }

  const tagContent = promo || blackFridayTag() || getTitleTag()

  return (
    <TagWrapper className={className} type={type} tagType={tagValue}>
      {tag !== 'promo' && <Icon iconId={getIcon()} size={getIconSize()} />}
      <TagText type={type}>{tagContent}</TagText>
    </TagWrapper>
  )
}
