import React, { ReactNode, useEffect, useReducer } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import config from '../../config'
import { FirebaseState, FirebaseContext, DispatchContext } from './context'

import reducer from './reducer'
import { actionSetFirebaseInstance, actionSetUserId } from './actions'

export function FirebaseProvider(props: { children: ReactNode }) {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, FirebaseState)

  const firebaseConfig = {
    ...{
      apiKey: config.FIREBASE_API_KEY,
      authDomain: config.FIREBASE_AUTH_DOMAIN,
      projectId: config.FIREBASE_PROJECT_ID,
      appId: config.FIREBASE_APP_ID,
    },
    ...(config.FIREBASE_DATABASE_URL
      ? {
          databaseURL: config.FIREBASE_DATABASE_URL,
        }
      : {}),
  }

  const getFirebase = () => {
    if (typeof window !== 'undefined') {
      if (state.firebaseInstance) return state.firebaseInstance
      const app = firebase.initializeApp(firebaseConfig)

      if (typeof config.FIREBASE_SIMULATOR_PORT === 'number') {
        app.firestore().useEmulator('localhost', config.FIREBASE_SIMULATOR_PORT)
      }

      app
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() =>
          app
            .auth()
            .signInAnonymously()
            .then((response) => {
              actionSetUserId(dispatch)(response.user?.uid ?? '')
              actionSetFirebaseInstance(dispatch)(app)
            }),
        )

      return null
    }
    return null
  }

  useEffect(() => {
    getFirebase()
  }, [])

  return (
    <FirebaseContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </FirebaseContext.Provider>
  )
}

export function useFirebase() {
  const state = React.useContext(FirebaseContext)
  const dispatch = React.useContext(DispatchContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider')
  }

  return {
    state,
    getFirebase: () => state.firebaseInstance,
    setFirebaseInstance: actionSetFirebaseInstance(dispatch),
  }
}
