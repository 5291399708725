import fetcher from './fetch'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}
interface SignUpParams {
  firstName: string
  lastName: string
  rut: string
  email: string
  password: string
  city: number | string
  market?: number | string
  birthdate: string
  redirectUrl: string
  reCaptchaToken?: string
  billingName?: string
  documentType?: string
  dniComplement?: string
}

interface GuestParams {
  firstName?: string
  lastName?: string
  rut?: string
  email?: string
  reCaptchaToken?: string
  billingName?: string
  documentType?: string
  dniComplement?: string
  birthdate?: string
  city?: number
}

interface CustomerDniExist {
  dni: string
}

interface CustomerDniAndEmailExist {
  dni: string
  email: string
}

export function signUp(params: SignUpParams) {
  const data = { ...params }
  return fetcher('/signup', { data, headers, method: 'POST' })
}

export function updateGuest(params: GuestParams) {
  const data = { ...params }
  return fetcher('/updateCustomerGuest', { data, headers, method: 'POST' })
}

export function checkCustomerDniExist(params: CustomerDniExist) {
  return fetcher(`/customers/check-by-dni?dni=${params.dni}`, { headers, method: 'GET' })
}

export function checkCustomerDniAndEmailExist(params: CustomerDniAndEmailExist) {
  return fetcher(`/customers/check-by-dni-and-email?dni=${params.dni}&email=${params.email}`, {
    headers,
    method: 'GET',
  })
}

export function checkUserBlackList(params: CustomerDniAndEmailExist) {
  return fetcher(`/customers/check-black-list?dni=${params.dni}&email=${params.email}`, {
    headers,
    method: 'GET',
  })
}

export function checkCustomerNitIsValid({ nit }: { nit: string }) {
  return fetcher(`/customers/check-nit?nit=${nit}`, { headers, method: 'GET' })
}
