import { useEffect } from 'react'

const useEnterKeyPress = (onSubmit: () => void) => {
  function handleEnterPress(e: KeyboardEvent) {
    if (e.code === 'Enter') onSubmit()
  }

  useEffect(() => {
    window.addEventListener('keypress', handleEnterPress)

    return () => window.removeEventListener('keypress', handleEnterPress)
  }, [])

  return { handleEnterPress }
}

export default useEnterKeyPress
