import { createContext } from 'react'
import firebase from 'firebase/app'
import { FirebaseActions } from './reducer'

export interface FirebaseState {
  firebaseInstance: null | ReturnType<typeof firebase.initializeApp>
  userId: string
}

export const FirebaseState = {
  firebaseInstance: null,
  userId: '',
}

export const FirebaseContext = createContext<FirebaseState>(FirebaseState)
export type FirebaseDispatch = (action: FirebaseActions) => void
export const DispatchContext = createContext<FirebaseDispatch | undefined>(undefined)
