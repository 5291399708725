import { graphql, useStaticQuery } from 'gatsby'
import { ThemeExtension } from '@ecommerce/shared'
import { ContentfulTheme } from '../../types/PgPages'

export interface ContentfulThemeQueryResult {
  allContentfulGlobal: {
    edges: { node: { theme: ContentfulTheme } }[]
  }
}

export const contentfulThemeToThemeExtension = (contentfulTheme: ContentfulTheme): ThemeExtension => {
  const {
    bodyBackground,
    bodyBackgroundAlt,
    bodyText,
    alternativeBodyTextColor,
    brandDefault,
    brandTextDefault,
    productViewWrapperBackground,
    productViewContainerBackground,
    layoutBackground,
    buttonBackgroundPrimaryColor,
    buttonPrimaryColor,
    iconsColor,
    iconsHoverColor,
  } = contentfulTheme

  return {
    brand: {
      default: brandDefault,
    },
    brandText: {
      default: brandTextDefault,
    },
    background: {
      body: bodyBackground,
      bodyAlternative: bodyBackgroundAlt,
      layout: layoutBackground,
    },
    productView: {
      wrapperBackground: productViewWrapperBackground,
      containerBackground: productViewContainerBackground,
    },
    bodyText,
    alternativeBodyTextColor,
    buttonBackgroundPrimaryColor,
    buttonPrimaryColor,
    iconsColor,
    iconsHoverColor,
  }
}

const extractContentfulTheme = (queryResult: ContentfulThemeQueryResult) => {
  if (!queryResult.allContentfulGlobal.edges.length) return undefined
  return queryResult.allContentfulGlobal.edges[0].node.theme
}

export const useGlobalThemeQuery = () => {
  const theme = extractContentfulTheme(
    useStaticQuery(graphql`
      query GlobalThemeQuery {
        allContentfulGlobal {
          edges {
            node {
              theme {
                bodyBackground
                bodyBackgroundAlt
                bodyText
                alternativeBodyTextColor
                brandDefault
                brandTextDefault
                iconsColor
                iconsHoverColor
                productViewWrapperBackground
                productViewContainerBackground
                layoutBackground
                buttonBackgroundPrimaryColor
                buttonPrimaryColor
                contentful_id
              }
            }
          }
        }
      }
    `),
  )
  const themeExtension = theme ? contentfulThemeToThemeExtension(theme) : undefined
  return { themeExtension }
}
