import React, { SVGAttributes } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import './icon_sprite.svg'

export type StyleButtonProps = {
  fillColor?: keyof DefaultTheme['colors']
  strokeColor?: keyof DefaultTheme['colors']
  size?: number | string
  sizeUnit?: string
  sizeWidth?: number | string
  sizeHeight?: number | string
}

const SvgElement = styled.svg<StyleButtonProps>`
  fill: ${(props) => {
    if (!props.fillColor) return 'currentColor'
    if (props.theme.colors[props.fillColor]) return props.theme.colors[props.fillColor]
    return props.fillColor
  }};
  ${(props) => {
    if (props.strokeColor && props.theme.colors[props.strokeColor])
      return `stroke: ${props.theme.colors[props.strokeColor]}`
  }};

  height: ${(props) => (props.sizeHeight || props.size || 32) + (props.sizeUnit || 'px')};
  width: ${(props) => (props.sizeWidth || props.size || 32) + (props.sizeUnit || 'px')};
`

export type IconId =
  | 'add'
  | 'addcircle_fill'
  | 'addcircle_outline'
  | 'arrow_down'
  | 'arrow_left'
  | 'arrow_right'
  | 'arrow_up'
  | 'bookmark'
  | 'calendar'
  | 'cart_0'
  | 'cart_1'
  | 'cart_2'
  | 'cart_3'
  | 'cart_4'
  | 'checklist'
  | 'chevron_down'
  | 'chevron_left'
  | 'chevron_right'
  | 'chevron_up'
  | 'close'
  | 'creditcard'
  | 'error_outline'
  | 'eye_close'
  | 'eye_open'
  | 'facebook'
  | 'grid'
  | 'help'
  | 'home'
  | 'info_fill'
  | 'info_outline'
  | 'info_alt'
  | 'instagram'
  | 'loading'
  | 'location'
  | 'logout'
  | 'mail_subject'
  | 'menu'
  | 'minus'
  | 'minuscircle_fill'
  | 'minuscircle_outline'
  | 'Promociones'
  | 'search'
  | 'filter_fill'
  | 'filter_outline'
  | 'order_clipboard'
  | 'product_list'
  | 'refresh'
  | 'sort'
  | 'star_fill'
  | 'star_outline'
  | 'success'
  | 'trash'
  | 'truck'
  | 'user'
  | 'warning_fill'
  | 'warning_outline'
  | 'cocktail'
  | 'ingredients'
  | 'mix'
  | 'whatsapp'
  | 'facebook-outline'
  | 'twitter'
  | 'link'
  | 'mail'
  | 'share'
  | 'qr'
  | 'download'
  | 'help-fill'
  | 'heart'
  | 'heart_outline'
  | 'grape'
  | 'meal'
  | 'thermometer'
  | 'valley'
  | 'glass-cup'
  | 'logo-liquors'
  | 'Coupon'
  | 'logo-cl'
  | 'logo-bo'
  | 'instagram-footer'
  | 'facebook-footer'
  | 'youtube-footer'
  | 'twitter-footer'
  | 'invoice'
  | 'gift'
  | 'returnability'
  | 'edit'
  | 'top-selling'
  | 'cyber'
  | 'new'
  | 'outstanding'
  | 'add-cart'
  | 'decrease-cart'
  | 'ph'
  | 'acidity'
  | 'alcohol'
  | 'residual-sugar'
  | 'aging'
  | 'black-friday'
  | 'gift-card'

export interface IconProps extends SVGAttributes<SVGElement>, StyleButtonProps {
  iconId: IconId
}

export const Icon = (props: IconProps) => {
  return (
    <SvgElement {...props}>
      <use xlinkHref={`#icon_sprite_icon-${props.iconId}`} />
    </SvgElement>
  )
}
