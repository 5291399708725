import styled from 'styled-components'
import Anchor from '../../atoms/Anchor'
import { hexToRGBA, toCssPrefix } from '../../../utils/format'

export const { toPrefix, cssPrefix } = toCssPrefix('SignInForm__')

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  .${cssPrefix} {
    &input {
      margin-bottom: 24px;
    }
    &status {
      margin: -8px 0 24px;
    }
  }
`

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 8px;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
`

export const Subtitle = styled.span`
  font-size: 13px;
  font-weight: 325;
  margin-bottom: 32px;
`

export const Recovery = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 325;
  margin-bottom: 24px;
  span {
    margin-right: 8px;
  }
`

export const ALink = styled(Anchor)`
  font-size: 13px;
  width: auto;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.red};
`

export const WrapperNoAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  span {
    font-size: 13px;
    font-weight: 325;
    margin-bottom: 16px;
  }
  button {
    width: 100%;
  }
`

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.platinum60};
  width: 100%;
  height: 1px;
  margin: 24px 0;
`
