import { ErrorLevel, ErrorSource, sendMessageToSentry } from '@ecommerce/chile-customer-webapp/src/utils/sentry'
import { FormData } from './index'
import { stripTrailingSlash } from '../../../utils/forms'
import { log } from '../../../utils/log'

export const redirectUrl = (
  preHome: boolean | undefined,
  baseUrl: string,
  onFormatRedirectURL: ((data: FormData) => string) | undefined,
  data: FormData,
) => {
  if (preHome) return baseUrl
  if (typeof onFormatRedirectURL === `function`) return onFormatRedirectURL(data)
  return `${baseUrl}/cuenta-activada`
}

export const getBaseUrl = stripTrailingSlash(typeof window !== `undefined` ? window.location.origin : '')

export const handleError = (err: any, data: FormData) => {
  log.error(err)
  const errorPayload = JSON.parse(err?.response?.config?.data)
  delete errorPayload?.password
  sendMessageToSentry({
    message: 'Error on Signup',
    level: ErrorLevel.Error,
    source: ErrorSource.SIGNUP_SERVICE,
    page: window.location.pathname,
    metadata: {
      ...err?.response,
      message: err?.message,
      errorPayload,
      requestPayload: data,
    },
  })
}
