import styled, { css } from 'styled-components'
import { breakpoints } from '@ecommerce/shared/src/utils/breakpoints'
import { TagType } from '@ecommerce/shared'

interface StyledProps {
  tagType: string
  type?: string
}

const commonTagStyles = () => css`
  min-height: 25px;
  height: 25px;
  padding-block: 4px;
  padding-inline: 8px;
  border-radius: 5px;
`

const commonTagTextStyles = () => {
  return css`
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
  `
}

export const TagWrapper = styled.div<StyledProps>`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-height: 20px;
  height: 20px;
  padding-block: 4px;
  padding-inline: 6px;
  border-radius: 2px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    ${() => {
      return commonTagStyles()
    }}
  }

  ${({ tagType }) => {
    if (TagType.new === tagType)
      return css`
        background: linear-gradient(180deg, #1b020d 0%, #020922 100%);
      `
    if (TagType.cyber === tagType)
      return css`
        background: linear-gradient(270deg, #2e0c6b 0%, #236af4 100%);
      `
    if (TagType.outstanding === tagType)
      return css`
        background: #2c0a5d;
      `
    if (TagType.top_selling === tagType)
      return css`
        background: #236af4;
      `
    if (TagType.black_friday === tagType)
      return css`
        background: linear-gradient(90deg, #0d1b37 0%, #5e11a8 100%);
      `
  }}
`

export const TagText = styled.p<Omit<StyledProps, 'tagType'>>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.1px;
  margin-block: 0;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    ${() => {
      return commonTagTextStyles()
    }}
  }

  ${({ type }) => {
    if (type === 'large') {
      return commonTagTextStyles()
    }
  }}
`

export const BlackTagText = styled.span<Omit<StyledProps, 'tagType'>>`
  font-weight: bold;
  @media screen and (${breakpoints.tabletPortrait.min}) {
    ${() => {
      return commonTagTextStyles()
    }}
  }
  ${({ type }) => {
    if (type === 'large') {
      return commonTagTextStyles()
    }
  }}
`

export const FridayTagText = styled.span<Omit<StyledProps, 'tagType'>>`
  font-weight: lighter;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    ${() => {
      return commonTagTextStyles()
    }}
  }
  ${({ type }) => {
    if (type === 'large') {
      return commonTagTextStyles()
    }
  }}
`
