import { keyframes } from 'styled-components'

export const skeletonAnimation = keyframes`
	0% {
		opacity: 1;
	}
	50%{
		opacity: 0.55;
	}
	100% {
		opacity: 1;
	}
`
