import React from 'react'
import styled from 'styled-components'

type StyleProps = {
  tipPosition?: 'left' | 'right'
  verticalPosition?: 'top' | 'bottom'
}

type Props = {
  children: React.ReactNode
  className?: string
} & StyleProps

const StyledTooltip = styled.div<StyleProps>`
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  min-width: 135px;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 9px;
    clip-path: ${({ verticalPosition }) =>
      verticalPosition === 'top' ? 'polygon(50% 0%, 0% 100%, 100% 100%)' : 'polygon(50% 100%, 0 0, 100% 0%)'};
    background: ${({ theme }) => theme.colors.black};
    ${({ tipPosition }) => (tipPosition === 'left' ? 'left:16px;' : 'right: 16px;')}
    ${({ verticalPosition }) => (verticalPosition === 'top' ? 'top:-8px;' : 'bottom:-8px;')}
  }
`

const Tooltip = ({ children, className = '', tipPosition = 'left', verticalPosition = 'top' }: Props) => (
  <StyledTooltip tipPosition={tipPosition} className={className} verticalPosition={verticalPosition}>
    {children}
  </StyledTooltip>
)

export default Tooltip
