import * as React from 'react'
import styled from 'styled-components'

const ContainerText = styled.span<StylesProps>`
  color: ${(props) => (props.color ? props.color : '#fff')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
  line-height: 1.31;
  letter-spacing: 0.1px;
  position: ${(props) => (props.position ? props.position : 'static')};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
`

interface StylesProps {
  color: string
  fontSize?: number
  position?: string
  width?: number
}
interface Props extends StylesProps {
  text: string
}

const Text = (props: Props) => {
  const { text, color = '#fff', fontSize = 16, position = 'none', width } = props
  return (
    <ContainerText color={color} fontSize={fontSize} position={position} width={width}>
      {text}
    </ContainerText>
  )
}

export default Text
