import React from 'react'
import { toCssPrefix } from '@ecommerce/shared'
import styled from 'styled-components'
import Collapse from '../../Collapse'

const { cssPrefix } = toCssPrefix('Collapse__')

const CollapsibleContainer = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  .${cssPrefix} {
    &title {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1px;
      font-weight: 600;
    }
  }
`
interface CollapseProps {
  label: string
  children: React.ReactElement | React.ReactElement[]
}

const FooterCollapse = ({ label, children }: CollapseProps) => {
  const ref = React.useRef<HTMLElement>(null)

  return (
    <CollapsibleContainer data-testid="footer-collapse">
      <Collapse label={label}>{React.Children.map(children, (child) => React.cloneElement(child, { ref }))}</Collapse>
    </CollapsibleContainer>
  )
}

export default FooterCollapse
