import { Market } from '../services/Location/cities'

export enum ZIndex {
  default,
  low, // overlapping components
  medium, // sidebar
  high, // modals
  veryHigh, // app shell
  ultraHigh, // global modals or dialogs
  emergency,
}

export const cityChangeMessage =
  'Al cambiar de ubicación, tu carro de compras podría sufrir modificaciones (por disponibilidad y/o precio).'
