import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Country } from '@ecommerce/shared'
import { BaseRootStateType } from '../types'
import secrets from '../../config/secrets'

const { COUNTRY } = secrets

const isBolivia = COUNTRY === Country.BOLIVIA

interface FilterPayload {
  categoryNames: string[]
}

interface PromotionState {
  searchState: {
    configure: {
      filters: string
      hitsPerPage: number
      query: string
      facetFilters: [string[]]
    }
    refinementList: { [key: string]: string[] }
  }
}

const initialState: PromotionState = {
  searchState: {
    configure: {
      filters: `${
        isBolivia ? 'hasStock:true AND ' : ''
      } (hasDiscount:true OR specialDiscount:PROMOCIÓN) AND NOT isDummy:true`,
      hitsPerPage: 96,
      query: '',
      facetFilters: [[]],
    },
    refinementList: {},
  },
}

export const promotionStateSlice = createSlice({
  name: 'algolia/promotionState',
  initialState,
  reducers: {
    setPromotionsFilters: (state, action: PayloadAction<FilterPayload>) => {
      const { categoryNames } = action.payload

      if (categoryNames && Array.isArray(categoryNames) && categoryNames.length > 0) {
        state.searchState.configure.facetFilters[0] = Array.from(
          new Set([
            ...categoryNames.map((cat) => `mainCategoryName:${cat}`),
            ...state.searchState.configure.facetFilters[0],
          ]),
        )
      }
    },
    setRefinementList: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      state.searchState.refinementList = {
        ...state.searchState.refinementList,
        ...action.payload,
      }
    },
    resetRefinementLists: (state) => {
      state.searchState.refinementList = initialState.searchState.refinementList
    },
  },
})

export const { resetRefinementLists, setPromotionsFilters, setRefinementList } = promotionStateSlice.actions

export const selectPromotionConfigure = (state: BaseRootStateType) => state.algolia.promotions.searchState.configure
export const selectPromotionFacetFilters = (state: BaseRootStateType) =>
  state.algolia.promotions.searchState.configure.facetFilters

export const selectPromotionsRefinementList = (state: BaseRootStateType) =>
  state.algolia.promotions.searchState.refinementList

export const selectSearchState = (state: BaseRootStateType) => state.algolia.search.searchState

export type searchStateSliceType = typeof promotionStateSlice

export default promotionStateSlice.reducer
