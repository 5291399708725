import { DistributionCenter, Market, Zone } from '../../services/Location/cities'
import { Country, ShippingCost } from '../../..'
import { ByHashZone, LocationDispatch } from './context'

export function actionSetMarkets(dispatch: LocationDispatch) {
  return (markets: Market[]) => dispatch({ type: 'SET_MARKETS', markets })
}

export function actionSetZones(dispatch: LocationDispatch) {
  return (zones: ByHashZone) => dispatch({ type: 'SET_ZONES', zones })
}

export function actionSetCountry(dispatch: LocationDispatch) {
  return (country: Country) => dispatch({ type: 'SET_COUNTRY', country })
}

export function actionSetZone(dispatch: LocationDispatch) {
  return (zone: Zone) => dispatch({ type: 'SET_CURRENT_ZONE', zone })
}
export function actionSetMarket(dispatch: LocationDispatch) {
  return (market: Market) => dispatch({ type: 'SET_CURRENT_MARKET', market })
}
export function actionSetDistributionCenters(dispatch: LocationDispatch) {
  return (distributionCenters: DistributionCenter[]) =>
    dispatch({ type: 'SET_DISTRIBUTION_CENTERS', distributionCenters })
}
export function actionSetDistributionCenter(dispatch: LocationDispatch) {
  return (distributionCenter: DistributionCenter) =>
    dispatch({ type: 'SET_CURRENT_DISTRIBUTION_CENTER', distributionCenter })
}

export function actionSetDistributionCenterVersion(dispatch: LocationDispatch) {
  return (distributionCenterVersion: number) =>
    dispatch({
      type: 'SET_DISTRIBUTION_CENTER_VERSION',
      distributionCenterVersion,
    })
}

export function actionSetShippingCost(dispathc: LocationDispatch) {
  return (shippingCost: ShippingCost) =>
    dispathc({
      type: 'SET_SHIPPING_COST',
      shippingCost,
    })
}
