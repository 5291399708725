import React from 'react'

const SuccessIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5303 4.13657C5.36636 1.98838 8.3967 1.30371 10.978 2.45386C11.3143 2.60372 11.4654 2.99783 11.3156 3.33415C11.1657 3.67046 10.7716 3.82162 10.4353 3.67177C8.3934 2.76195 5.99627 3.30355 4.54387 5.00286C3.09146 6.70218 2.92976 9.1544 4.14646 11.0297C5.36315 12.905 7.66841 13.7567 9.81211 13.1229C11.8994 12.5057 13.3494 10.6234 13.4254 8.46155L13.4285 8.28567V7.75996C13.4285 7.39177 13.727 7.09329 14.0952 7.09329C14.4371 7.09329 14.7189 7.35065 14.7574 7.68221L14.7619 7.75996V8.28605C14.7603 11.112 12.9001 13.6002 10.1902 14.4015C7.4802 15.2027 4.56601 14.1261 3.02792 11.7554C1.48983 9.38474 1.69424 6.28476 3.5303 4.13657Z"
      fill="#12C269"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6236 3.24311C13.8838 2.98263 14.3059 2.98241 14.5664 3.24263C14.8068 3.48284 14.8255 3.86098 14.6223 4.12261L14.5669 4.18544L8.85259 9.90544C8.61231 10.146 8.234 10.1646 7.97238 9.96116L7.90955 9.90568L6.19526 8.19139C5.93491 7.93104 5.93491 7.50893 6.19526 7.24858C6.43558 7.00826 6.81374 6.98977 7.07527 7.19312L7.13807 7.24858L8.38 8.49066L13.6236 3.24311Z"
      fill="#12C269"
    />
  </svg>
)

export default SuccessIcon
