import React, { useEffect, useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import DocumentType from '@ecommerce/shared/src/components/molecules/DocumentType'
import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'
import useDebounce from '@ecommerce/shared/src/hooks/useDebounce'
import Button from '../../atoms/Button'
import TextField from '../../molecules/TextField'
import Checkbox from '../../atoms/Checkbox'
import { useAuth } from '../../../context/Auth'
import { Market } from '../../../services/Location/cities'
import { getSchema } from '../AuthWidget/libs'
import { formatRut } from '../../../utils/forms'
import { getAuth } from '../../../utils/store'
import { useLocation } from '../../../context/Location'
import { Wrapper, toPrefix, CheckboxOption, Title, Subtitle } from './styled'
import useEnterKeyPress from '../../../hooks/useEnterKeyPress'
import { CLOrder } from '../../../services/CommerceLayer/types'
import BirthDateInput from '../FormSignUp/BirthDateInput'
import useCheckEmail from '../FormSignUp/useCheckEmail'
import useCheckEmailBolivia from '../FormSignUp/useCheckEmailBolivia'
import useGuestSignUp from './useGuestSignUp'
import InfoMessage from '../../atoms/InfoMessage'

const { documentTypes } = secrets

export interface FormGuestData {
  firstName: string
  lastName: string
  dni: string
  email: string
  birthdate: string
  documentType?: string
  dniComplement?: string
  billingName?: string
}

export interface Props {
  onFinished: (metadata?: Partial<CLOrder['attributes']['metadata']>, email?: string) => Promise<void>
  onClickSignIn: () => void
  onClickSignUp: () => void
  willCheckout?: boolean
  currentCity?: Market
  onSend?: () => void
  onError?: () => void
  setLoading: (isLoading: boolean) => void
  setIsLoadingUserData?: (isLoadingUserData: boolean) => void
  loading: boolean
  isCheckout?: boolean
  title?: string
  subtitle?: string
}

const FormGuest = (props: Props) => {
  const {
    willCheckout,
    loading,
    onError,
    onFinished,
    title = 'Continua como invitado',
    subtitle = 'Completa tus datos',
    setIsLoadingUserData,
  } = props
  const {
    isBolivia,
    state: { country },
  } = useLocation()

  const [selectedDocumentType, setSelectedDocumentType] = useState('')
  const [isBillingName, setIsBillingName] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [dniValue, setDniValue] = useState('')

  const emailDebounce = useDebounce(emailValue, 1000)
  const dniDebounce = useDebounce(dniValue, 1000)

  const prevDni: string | number = ''

  const yupSchema = getSchema(isBolivia(), false, selectedDocumentType)

  const methods = useForm<FormGuestData>({
    resolver: yupResolver(yupSchema),
    mode: 'all',
  })

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    reset,
    trigger,
    setError,
    getValues,
    clearErrors,
  } = methods

  const watchFields = watch()

  const { state: authState } = useAuth(country)

  const { checkEmail, isChecking, setIsChecking } = useCheckEmail({
    getValues,
    setError,
    clearErrors,
  })

  const { checkEmailBolivia, isCheckingBolivia, setIsCheckingBolivia } = useCheckEmailBolivia({
    getValues,
    setError,
    clearErrors,
  })

  const { authError, submit } = useGuestSignUp({
    onError,
    onFinished,
    loading,
    willCheckout,
    setIsLoadingUserData,
  })

  const errorMessage = authError?.description
  const isSubmitDisabled =
    !watchFields.firstName ||
    !watchFields.lastName ||
    !watchFields.dni ||
    !watchFields.email ||
    !watchFields.birthdate ||
    !!Object.keys(errors).length

  useEffect(() => {
    register({ name: 'birthdate' })
    register({ name: 'billingName' })
  }, [register])

  useEffect(() => {
    reset({
      ...watchFields,
      documentType: isBolivia() ? 'ci' : 'rut',
    })
  }, [reset])

  useEffect(() => {
    if (!isBillingName) {
      setValue('billingName', `${watchFields.firstName} ${watchFields.lastName}`, { shouldDirty: true })
    } else {
      setValue('billingName', '', { shouldDirty: true })
    }
  }, [isBillingName])

  useEffect(() => {
    if (getAuth()) {
      onFinished()
    }
  }, [authState])

  useEffect(() => {
    if (getValues().dni !== undefined) {
      setDniValue(getValues().dni)
    }
  }, [getValues().dni])

  useEffect(() => {
    if (emailValue !== '' && dniValue !== '') {
      if (isBolivia()) {
        checkEmailBolivia()
      } else {
        checkEmail()
      }
    }
  }, [emailDebounce, dniDebounce])

  useEnterKeyPress(() => handleSubmit(submit))

  function handleDniChange(dni: string) {
    let value = dni
    clearErrors('dni')
    if (!isBolivia()) value = dni.length === 0 || dni === '-' || dni === ' ' ? '' : formatRut(dni)
    return setValue('dni', value)
  }

  const onBillingNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('billingName', value, { shouldDirty: true })
    await trigger('billingName')
  }

  const onDateChange = async (date: string) => {
    setValue('birthdate', date)
    await trigger('birthdate')
  }

  return (
    <FormProvider {...methods}>
      <Wrapper onSubmit={handleSubmit(submit)}>
        {title && <Title>{title}</Title>}
        <Subtitle>{subtitle}</Subtitle>

        <Controller
          control={control}
          name="firstName"
          defaultValue=""
          render={({ value, onChange }) => (
            <TextField
              data-testid="firstname-guest"
              maxLength={isBolivia() ? 50 : undefined}
              className={toPrefix('input')}
              label="Nombre"
              placeholder="Ingresa tu nombre"
              value={value || ''}
              type="text"
              status={!errors.firstName ? undefined : 'error'}
              errorMessage={errors.firstName?.message}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          defaultValue=""
          render={({ value, onChange }) => (
            <TextField
              data-testid="lastname-guest"
              maxLength={isBolivia() ? 50 : undefined}
              className={toPrefix('input')}
              label="Apellido"
              placeholder="Ingresa tu apellido"
              value={value || ''}
              type="text"
              status={!errors.lastName ? undefined : 'error'}
              errorMessage={errors.lastName?.message}
              onChange={onChange}
            />
          )}
        />

        {isBolivia() && (
          <>
            <CheckboxOption>
              <Checkbox
                disabled={!watchFields.firstName && !watchFields.lastName}
                onChange={() => setIsBillingName(!isBillingName)}
                className="CheckboxOption__input"
                checked={!isBillingName}
              />
              <p className="CheckboxOption__label">Utilizar mis datos para factura.</p>
            </CheckboxOption>
            {isBillingName && (
              <TextField
                label="Nombre para facturas"
                name="billingName"
                className={toPrefix('input')}
                placeholder="Nombre para facturas"
                status={!errors.billingName ? undefined : 'error'}
                errorMessage={errors.billingName?.message}
                value={watchFields.billingName || ''}
                onChange={onBillingNameChange}
              />
            )}
            <DocumentType
              isVisible
              isGuest
              dni={prevDni}
              setIsCheckingDni={setIsCheckingBolivia}
              selectedDocumentType={selectedDocumentType}
              setSelectedDocumentType={setSelectedDocumentType}
              types={documentTypes}
            />
          </>
        )}

        {!isBolivia() && (
          <Controller
            control={control}
            name="dni"
            defaultValue=""
            render={({ value }) => (
              <TextField
                data-testid="dni-guest"
                className={toPrefix('input')}
                label="Rut"
                placeholder="12.345.678-0"
                onChange={({ target: { value: dni } }) => handleDniChange(dni)}
                type="text"
                maxLength={12}
                status={!errors.dni ? undefined : 'error'}
                errorMessage={errors.dni?.message}
                value={value || ''}
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="email"
          defaultValue=""
          render={() => (
            <TextField
              data-testid="email-guest"
              maxLength={isBolivia() ? 50 : undefined}
              className={toPrefix('input')}
              label="Correo electrónico"
              status={!errors.email ? undefined : 'error'}
              placeholder="Ingresa tu correo electrónico"
              errorMessage={errors.email?.message}
              value={emailValue || ''}
              autoCapitalize="nope"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isBolivia()) setIsCheckingBolivia(true)
                else setIsChecking(true)
                setValue('email', e.target.value)
                setEmailValue(e.target.value)
              }}
            />
          )}
        />

        <BirthDateInput
          data-testid="birthday-guest"
          errorMessage={errors.birthdate?.message}
          className={toPrefix('input')}
          onDateChange={onDateChange}
          status={!errors.birthdate ? undefined : 'error'}
        />

        <Button
          data-test="signin-submit"
          className={toPrefix('submit')}
          whileTap={{ scale: 0.9 }}
          type="submit"
          isDisabled={isSubmitDisabled || loading || isChecking || isCheckingBolivia}
          disabled={isSubmitDisabled || loading || isChecking || isCheckingBolivia}
          isLoading={loading || isChecking || isCheckingBolivia}
        >
          Continuar sin registrar
        </Button>
        <div className={toPrefix('status')}>
          <InfoMessage className={toPrefix('error_message')} isHidden={!errorMessage} message={errorMessage} />
        </div>
      </Wrapper>
    </FormProvider>
  )
}

export default FormGuest
