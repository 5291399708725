import { configureStore, Middleware } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import { AppDispatchType, BaseRootStateType } from './types'
import rootReducer from './rootReducer'

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

const middlewares: Middleware[] = []

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: activeEnv !== 'production',
})

export const useAppDispatch: () => AppDispatchType = useDispatch

export const useAppSelector: TypedUseSelectorHook<BaseRootStateType> = useSelector

export default store
