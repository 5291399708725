import React from 'react'

export const ArrowDownIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>arrow_down</title>
      <path d="M17.348 21.561v-16.211h-2.667v16.243l-7.479-7.479-1.886 1.886 10.682 10.682 10.682-10.682-1.886-1.886z" />
    </svg>
  )
}

export const ArrowUpIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>arrow_up</title>
      <path d="M17.348 10.47v16.211h-2.667v-16.243l-7.479 7.479-1.886-1.886 10.682-10.682 10.682 10.682-1.886 1.886z" />
    </svg>
  )
}

export const ArrowLeftIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>arrow_left</title>
      <path d="M10.454 14.667h16.211v2.667h-16.243l7.479 7.479-1.886 1.886-10.682-10.682 10.682-10.682 1.886 1.886z" />
    </svg>
  )
}

export const ArrowRightIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>arrow_right</title>
      <path d="M21.545 14.667h-16.211v2.667h16.243l-7.479 7.479 1.886 1.886 10.682-10.682-10.682-10.682-1.886 1.886z" />
    </svg>
  )
}

export const ChevronRightIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>chevron_right</title>
      <path d="M12.589 10.312l1.886-1.886 7.616 7.616-7.616 7.616-1.886-1.886 5.73-5.73z" />
    </svg>
  )
}

export const ChevronLeftIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>chevron_left</title>
      <path d="M19.424 10.312l-5.73 5.73 5.73 5.73-1.886 1.886-7.616-7.616 7.616-7.616z" />
    </svg>
  )
}

export const ChevronUpIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>chevron_up</title>
      <path d="M10.276 19.501l-1.886-1.886 7.616-7.616 7.616 7.616-1.886 1.886-5.73-5.73z" />
    </svg>
  )
}

export const ChevronDownIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>chevron_down</title>
      <path d="M10.276 12.533l5.73 5.73 5.73-5.73 1.886 1.886-7.616 7.616-7.616-7.616z" />
    </svg>
  )
}
