import * as React from 'react'
import { useEffect, useState } from 'react'
import { AnimatePresence, PanInfo } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import { ContainerImage, ButtonSlide, ContainerSlider, ContainerDots, Dot } from './components'

interface Props {
  images: string[]
  height?: number
  autoTransition?: boolean
  hiddenArrows?: boolean
  hiddenDots?: boolean
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

type Direction = -1 | 1 | 0

const Slider = (props: Props) => {
  const { images, height = 100, autoTransition = false, hiddenArrows = true, hiddenDots = true } = props
  const swipeConfidenceThreshold = 1000

  const [[position, direction], setPage] = useState<[number, Direction]>([0, 0])
  const [activeBullet, setActiveBullet] = useState(0)
  const imageIndex = wrap(0, images?.length, position)

  const onPaginate = (newDirection: number) => {
    if (position + newDirection < images.length && position + newDirection >= 0) {
      setPage([position + newDirection, 0])
      setActiveBullet(position + newDirection)
    } else if (position + newDirection === images.length) {
      setPage([0, 0])
      setActiveBullet(0)
    } else if (position + newDirection === -1) {
      setPage([images.length - 1, 0])
      setActiveBullet(images.length - 1)
    }
  }

  function onDrag(e: TouchEvent, info: PanInfo) {
    const swipe = Math.abs(info.offset.x) * info.velocity.x

    if (swipe < -swipeConfidenceThreshold) {
      onPaginate(1)
    } else if (swipe > swipeConfidenceThreshold) {
      onPaginate(-1)
    }
  }

  useEffect(() => {
    if (autoTransition) {
      const loopPage = setTimeout(() => onPaginate(1), 4000)

      return function cleanup() {
        clearTimeout(loopPage)
      }
    }
  }, [position, autoTransition, hiddenDots])

  return (
    <ContainerSlider height={height}>
      <ButtonSlide direction="left" hidden={hiddenArrows} onClick={() => onPaginate(-1)}>
        ‣
      </ButtonSlide>
      <ContainerDots>
        {images.map((_, i) => (
          <Dot
            hidden={hiddenDots}
            className={position === i ? 'dot-active' : ''}
            onClick={() => {
              setPage([i, 0])
              setActiveBullet(i)
            }}
            bg={activeBullet === i ? 'red' : 'grey'}
          />
        ))}
      </ContainerDots>
      <ButtonSlide direction="right" hidden={hiddenArrows} onClick={() => onPaginate(1)}>
        ‣
      </ButtonSlide>
      <AnimatePresence initial={false} custom={direction}>
        <ContainerImage
          key={position}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          height={height}
          transition={{
            x: { type: 'tween', stiffness: 600, damping: 400 },
            opacity: { duration: 0.1 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={onDrag}
        />
      </AnimatePresence>
    </ContainerSlider>
  )
}

export default Slider
