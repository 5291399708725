import { Country, toNumber } from '../..'

interface CountryTerms {
  currencySymbol: string
}

interface I18n {
  [Country.CHILE]: CountryTerms
  [Country.BOLIVIA]: CountryTerms
}

export const i18n: I18n = {
  CL: {
    currencySymbol: '$',
  },
  BO: {
    currencySymbol: 'Bs.',
  },
}

export function getCurrencyCode(countryCode?: Country) {
  return countryCode === Country.BOLIVIA ? 'BOB' : 'CLP'
}

export function toCurrencyNumber(num: number | string, useFloatFormat: boolean) {
  return useFloatFormat ? parseInt(`${num}`, 10) / 100 : parseInt(`${num}`, 10)
}

export function toCurrency(num: number | string, isBolivia?: boolean, useFloatFormat?: boolean) {
  const amount = toCurrencyNumber(num, useFloatFormat ?? false)
  return `${i18n[isBolivia ? 'BO' : 'CL'].currencySymbol} ${toNumber(amount, isBolivia)}`
}
