import React, { ReactNode } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { SemanticColors } from '../types/styled'

export interface ThemeExtension {
  brand: {
    default: string
  }
  brandText: {
    default: string
  }
  background: {
    body: string
    bodyAlternative: string
    layout?: string
  }
  productView: {
    wrapperBackground: string
    containerBackground: string
  }
  buttonBackgroundPrimaryColor?: string
  buttonPrimaryColor?: string
  bodyText: string
  alternativeBodyTextColor?: string
  iconsColor?: string
  iconsHoverColor?: string
}

const tokens = {
  colors: {
    red: '#F40009',
    red80: '#F44040',
    red60: '#FA8084',
    red40: '#FCBFC1',
    red20: '#FEE5E6',

    darkRed: '#C00000',
    darkRed80: '#D04040',
    darkRed60: '#DF8080',
    darkRed40: '#EFBFBF',
    darkRed20: '#F9E5E5',

    green: '#12C269',
    green80: '#4DD18F',
    green60: '#71DAA5',
    green40: '#C4F0DA',
    green20: '#E7F9F0',

    yellow: '#F0B63F',
    yellow80: '#F4C86F',
    yellow60: '#F8DA9F',
    yellow40: '#FBEDCF',
    yellow20: '#FEF8EC',

    returnability: '#76C074',
    returnability80: '#91CD90',
    returnability60: '#ADD9AC',
    returnability40: '#C8E6C7',
    returnability20: '#E4F2E3',

    black: '#030F1C',
    black80: '#424B55',
    black60: '#81878E',
    black40: '#C0C3C6',
    black20: '#E6E7E8',

    platinum: '#354455',
    platinum80: '#5E7083',
    platinum60: '#9BB0C7',
    platinum40: '#CCD8E5',
    platinum20: '#E6ECF4',

    grey: '#E8EBF7',
    grey80: '#EEF0F9',
    grey60: '#ECF1F7',
    grey40: '#F1F5F9',
    grey20: '#F7F9FC',

    white: '#FFFFFF',

    skyBlue: '#1890FF',
    skyBlue80: '#4BA9FF',
    skyBlue60: '#A6D1F9',
    skyBlue40: '#CEE8FF',
    skyBlue20: '#ECF6FF',

    cyan: '#03D1FE',
    cyan80: '#4EDBFA',
    cyan60: '#82E8FF',
    cyan40: '#BEF3FF',
    cyan20: '#EBFBFF',

    turquoise: '#36CBCB',
    turquoise80: '#63D9D9',
    turquoise60: '#A1E7E7',
    turquoise40: '#CEF3F3',
    turquoise20: '#EAF8F8',

    blue: '#1B4BC7',
    blue80: '#567AD9',
    blue60: '#8EA7E9',
    blue40: '#C5D3F5',
    blue20: '#E9EDF8',

    violet: '#5F67E4',
    violet80: '#8990F4',
    violet60: '#B0B4F2',
    violet40: '#D5D8F9',
    violet20: '#F0F1FF',

    petrolBlue: '#04A4C7',
    petrolBlue80: '#43BBD5',
    petrolBlue60: '#82D1E3',
    petrolBlue40: '#C0E8F1',
    petrolBlue20: '#E6F6F9',

    purple: '#6C006F',
    purple80: '#914093',
    purple60: '#B680B7',
    purple40: '#DABFDB',
    purple20: '#F0E5F1',

    magenta: '#DB029E',
    magenta80: '#E441B6',
    magenta60: '#ED80CF',
    magenta40: '#F6C0E7',
    magenta20: '#FBE6F5',

    pink: '#CE5DD0',
    pink80: '#DA85DC',
    pink60: '#E6AEE8',
    pink40: '#F3D6F3',
    pink20: '#FAEFFA',

    ocher: '#ED892D',
    ocher80: '#F1A762',
    ocher60: '#F6C496',
    ocher40: '#FBE2CB',
    ocher20: '#FDF3EA',

    orange: '#FA7800',
    orange80: '#FB9A40',
    orange60: '#FCBB80',
    orange40: '#FEDDBF',
    orange20: '#FFF1E5',

    gold: '#CBA138',
    gold80: '#D5B460',
    gold60: '#E0C788',
    gold40: '#EAD9AF',
    gold20: '#F5ECD7',

    redDarkCoke: 'linear-gradient(135deg, #AF0101 0%, #F00000 100%)',
    redCoke: 'linear-gradient(135deg, #F00000 0%, #FF4F4F 100%)',

    whiteBack: 'linear-gradient(135deg, #FFF 0%, #EEECEC 52.13%, #FFF 100%)',
    whiteLeft: 'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
    whiteBottom: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
    whiteTop: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',

    darkLeft: 'linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
    darkBottom: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
  },
}

const semanticColors: SemanticColors = {
  background: {
    primary: tokens.colors.white,
    emphasis: tokens.colors.red,
    secondary: tokens.colors.grey20,
    focus: tokens.colors.grey,
  },
  backgroundRoles: {
    success: tokens.colors.green20,
    error: tokens.colors.red20,
    alert: tokens.colors.yellow20,
    info: tokens.colors.skyBlue20,
  },
  text: {
    display: {
      primary: tokens.colors.black,
      secondary: tokens.colors.platinum,
      emphasis: tokens.colors.red,
    },
    title: {
      primary: tokens.colors.black,
      secondary: tokens.colors.platinum,
      tertiary: tokens.colors.black60,
      emphasis: tokens.colors.red,
    },
    body: {
      primary: tokens.colors.platinum,
      secondary: tokens.colors.platinum60,
      emphasis: tokens.colors.red,
    },
    roles: {
      success: tokens.colors.green,
      error: tokens.colors.red80,
      alert: tokens.colors.yellow,
      info: tokens.colors.skyBlue,
    },
  },
  border: {
    primary: tokens.colors.black,
    secondary: tokens.colors.grey,
    error: tokens.colors.darkRed,
    success: tokens.colors.green,
  },
}

export const defaultTheme: DefaultTheme = {
  tokens,
  semanticColors,
  borderRadius: '4px',
  sizes: {
    width: {
      desktopMin: '1025px',
    },
  },
  boxShadow: {
    lvlOne: '0 4px 8px 0 rgba(95, 111, 134, 0.15)',
    lvlTwo: '0 8px 16px 0 rgba(95, 111, 134, 0.2)',
    lvlThree: '0 12px 24px 0 rgba(0, 0, 0, 0.1)',
    lvlFour: '0 16px 40px 0 rgba(0, 0, 0, 0.1)',
  },
  colors: {
    ...tokens.colors,
    /* CONTENTFUL THEME COLORS */
    brand: {
      default: tokens.colors.red,
    },
    brandText: {
      default: tokens.colors.white,
    },
    background: {
      body: tokens.colors.white,
      bodyAlternative: tokens.colors.grey,
    },
    productView: {
      wrapperBackground: tokens.colors.grey,
      containerBackground: tokens.colors.white,
    },
    bodyText: tokens.colors.black,

    /* BASE COLORS  */
    secondary: tokens.colors.darkRed, // TODO: Validar color
    error: tokens.colors.darkRed, // TODO: Validar color
  },
}

export default ({ children, themeExtension }: { children: ReactNode; themeExtension?: ThemeExtension }) => {
  const theme = { ...defaultTheme, colors: { ...defaultTheme.colors, ...(themeExtension ?? {}) } }

  return <ThemeProvider theme={theme as DefaultTheme}>{children}</ThemeProvider>
}
