import { AxiosPromise } from 'axios'
import fetch, { headers } from './fetch'
import { CLRetrieveAddressResponse } from './types'

export const DUMMY_ZIP_CODE = '0000'

interface Params {
  business?: boolean
  first_name: string
  last_name: string
  company?: string
  full_name?: string
  line_1: string
  line_2?: string
  city?: string
  zip_code: string
  state_code: string
  country_code?: string
  phone?: string
  email?: string
  notes?: string
  lat?: number
  lng?: number
  metadata?: Record<string, unknown>
}

export function createAddress(attributes: Params): AxiosPromise<CLRetrieveAddressResponse> {
  const data = {
    data: {
      type: 'addresses',
      attributes,
    },
  }

  return fetch('/api/addresses', { data, headers, method: 'POST' })
}
