import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Country } from '@ecommerce/shared'
import { BaseRootStateType } from '../types'
import secrets from '../../config/secrets'

const { COUNTRY } = secrets

const isBolivia = COUNTRY === Country.BOLIVIA

interface FilterBySkusPayload {
  skus: string[]
}

interface SearchPayload {
  query: string
}

interface SearchState {
  searchState: {
    configure: {
      filters: string
      hitsPerPage: number
      query: string
    }
    refinementList: { [key: string]: string[] }
  }
  indexName: string
}

const initialState: SearchState = {
  searchState: {
    configure: {
      filters: `${isBolivia ? 'hasStock:true AND' : ''} NOT isDummy:true`,
      hitsPerPage: 96,
      query: '',
    },
    refinementList: {},
  },
  indexName: secrets.ALGOLIA_INDEX_NAME,
}

export const searchStateSlice = createSlice({
  name: 'algolia/searchState',
  initialState,
  reducers: {
    setIndexName: (state, action: PayloadAction<string>) => {
      state.indexName = action.payload
    },
    setRefinementList: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      state.searchState.refinementList = {
        ...state.searchState.refinementList,
        ...action.payload,
      }
    },
    setFilterBySkus: (state, action: PayloadAction<FilterBySkusPayload>) => {
      const { skus } = action.payload

      state.searchState.configure.filters = `${state.searchState.configure.filters} AND (${skus
        .map((sku) => `sku=${sku}`)
        .join(' OR ')})`
    },
    setQuery: (state, action: PayloadAction<SearchPayload>) => {
      const { query } = action.payload
      state.searchState.configure.query = query
    },
    resetQuery: (state) => {
      state.searchState.configure.query = initialState.searchState.configure.query
    },
    resetRefinementLists: (state) => {
      state.searchState.refinementList = initialState.searchState.refinementList
    },
    resetFilters: (state) => {
      state.searchState.configure.filters = initialState.searchState.configure.filters
      state.indexName = initialState.indexName
    },
  },
})

export const {
  resetFilters,
  resetQuery,
  resetRefinementLists,
  setFilterBySkus,
  setIndexName,
  setRefinementList,
  setQuery,
} = searchStateSlice.actions

export const selectIndexName = (state: BaseRootStateType) => state.algolia.search.indexName

export const selectConfigure = (state: BaseRootStateType) => state.algolia.search.searchState.configure

export const selectRefinementList = (state: BaseRootStateType) => state.algolia.search.searchState.refinementList

export const selectSearchState = (state: BaseRootStateType) => state.algolia.search.searchState

export type searchStateSliceType = typeof searchStateSlice

export default searchStateSlice.reducer
