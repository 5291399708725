import { AxiosError } from 'axios'
import { PromotionDetail } from '../../types'
import { DiscountDetails } from '../../context/ShoppingCart/context'

export enum ResourceType {
  ORDERS = 'orders',
  PAYMENT_METHODS = 'payment_methods',
  WIRE_TRANSFERS = 'wire_transfers',
  SHIPMENTS = 'shipments',
  ADDRESSES = 'addresses',
  CUSTOMERS = 'customers',
  LINE_ITEMS = 'line_items',
  SKUS = 'skus',
  PRICES = 'prices',
  SHIPPING_METHODS = 'shipping_methods',
  ADJUSTMENT = 'adjustments',
  EXTERNAL_PROMOTIONS = 'external_promotions',
  GIFT_CARD = 'gift_cards',
}

export enum OrderStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  PLACED = 'placed',
  APPROVED = 'approved',
  CANCELLED = 'cancelled',
}

interface RelationData {
  type: string
  id: string
}

export interface CLRelation {
  links: {
    self: string
    related: string
  }
  data?: null | RelationData | RelationData[]
}

interface SingleCLRelation extends CLRelation {
  data?: null | RelationData
  object?: CLObject
}

interface MultipleCLRelation extends CLRelation {
  data?: null | RelationData[]
  objects?: CLObject[]
}

export interface CLObject {
  id: string
  type: ResourceType
  attributes: Record<string, unknown>
  relationships: Record<string, CLRelation>
}

export interface CLRetrieveResponse {
  data: CLObject
  included?: CLObject[]
}

export interface CLListResponse {
  data: CLObject[]
  included?: CLObject[]
  meta: {
    record_count: number
    page_count: number
  }
  links: {
    first: string
    next: string
    last: string
  }
}

interface CLCustomer extends CLObject {
  type: ResourceType.CUSTOMERS
  attributes: {
    email: string
    status: string
    created_at: string
    updated_at: string
  }
}

interface ShippingAddressCLRelation extends SingleCLRelation {
  object?: CLAddress
}

interface PaymentSourceCLRelation extends SingleCLRelation {
  object?: CLWireTransfer
}

interface ShipmentsCLRelation extends MultipleCLRelation {
  objects?: CLShipment[]
}

interface LineItemCLRelation extends MultipleCLRelation {
  objects?: CLLineItem[]
}

interface PriceCLRelation extends MultipleCLRelation {
  objects?: CLPrice[]
}

export interface CleanCLOrder {
  id: string
  status: OrderStatus
  metadata?: {
    firstName?: string
    lastName?: string
    cartId?: string
  }
}
export interface FilteredCLOrder {
  type: ResourceType.ORDERS
  links: any
  id: string
  meta: any
  attributes: {
    status: OrderStatus
    metadata?: {
      firstName?: string
      lastName?: string
      cartId?: string
    }
  }
}
export interface CLOrder extends CLObject {
  type: ResourceType.ORDERS
  attributes: {
    formatted_total_amount: string
    skus_count: number
    number: number
    status: OrderStatus
    total_amount_cents: number
    discount_amount_cents: number
    approved_at: string
    placed_at: string
    updated_at: string
    coupon_code: string
    gift_card_code: string
    gift_card_amount_cents: number
    total_amount_with_taxes_cents: number
    total_amount_with_taxes_float: number
    shipping_amount_cents: number
    shipping_amount_float: number
    metadata: {
      firstName?: string
      lastName?: string
      couponCode?: string | number
      cartId?: string
      paymentMethod?: string
      paymentStatus?: string
      hasQR?: boolean
      qrCode?: string
      qrCodeRaw?: string
      birthdate?: string
      dni?: string
      documentType?: string
      billingName?: string
      dniComplement?: string
      distributionCenterSlug?: string
    }
  }
  relationships: {
    market: CLRelation
    customer: CLRelation
    line_items: LineItemCLRelation
    shipping_address: ShippingAddressCLRelation
    payment_source: PaymentSourceCLRelation
    shipments: ShipmentsCLRelation
  }
}

export interface CLAddress extends CLObject {
  type: ResourceType.ADDRESSES
  attributes: {
    first_name: string
    last_name: string
    line_1: string
    city: string
    phone: string
    lat: string
    lng: string
    created_at: string
    metadata: {
      cityId: any
      alias: string
    }
  }
}

export interface CLShipment extends CLObject {
  type: ResourceType.SHIPMENTS
  attributes: {
    metadata: {
      shippingDate: string
    }
  }
}

export interface CLWireTransfer extends CLObject {
  type: ResourceType.WIRE_TRANSFERS
  attributes: {
    reference: string
    reference_origin: string
  }
}

export interface CLSku extends CLObject {
  type: ResourceType.SKUS
  attributes: {
    code: string
    image_url: string
    name: string
    inventory?: {
      quantity: number
    }
    metadata?: {
      returnability?: string
    }
  }
  relationships: {
    prices: PriceCLRelation
  }
}

export interface CLPrice extends CLObject {
  type: ResourceType.PRICES
  attributes: {
    sku_code: string
    amount_cents: number
    formatted_amount: string
    compare_at_amount_cents: number
  }
}

export interface CLLineItem extends CLObject {
  type: ResourceType.LINE_ITEMS
  attributes: {
    metadata: {
      originalPrice?: number
      price?: number
      isBundle?: boolean
      recyclable?: boolean
      type?: string
      brandName?: string
      categoryName?: string
      size?: string
      labelId?: string | null
      labelUrl?: string | null
      isSuggested?: boolean
      isGift?: true
      discountQuantity?: number
      discountDetails?: PromotionDetail[] | DiscountDetails[]
    }
    sku_code: string
    quantity: number
    unit_amount_cents: number
    formatted_unit_amount: string
    total_amount_cents: number
    formatted_total_amount: string
    name: string
    image_url: string | null
    item_type: ResourceType
    discount_cents: number
  }
}

export interface CLRetrieveOrderResponse extends CLRetrieveResponse {
  data: CLOrder
  included?: (CLAddress | CLShipment | CLWireTransfer | CLLineItem | CLOrder)[]
}

export interface CLRetrieveCustomerResponse extends CLRetrieveResponse {
  data: CLCustomer
  included?: (CLOrder | CLAddress | CLShipment | CLWireTransfer)[]
}

export interface CLRetrieveAddressResponse extends CLRetrieveResponse {
  data: CLAddress
}

export interface CLOrderListResponse extends CLListResponse {
  data: CLOrder[]
}

export interface CLSkuListResponse extends CLListResponse {
  data: CLSku[]
  included?: CLPrice[]
}

export interface CLPaymentMethod extends CLObject {
  type: ResourceType.PAYMENT_METHODS
}

export interface CLPaymentMethodListResponse extends CLListResponse {
  data: CLPaymentMethod[]
}

export interface CLShippingMethod extends CLObject {
  type: ResourceType.SHIPPING_METHODS
}

export interface CLShippingMethodListResponse extends CLListResponse {
  data: CLShippingMethod[]
}

export interface CLError {
  title: string
  detail: string
  code: string
  status: number
  source?: {
    pointer: string
  }
  meta: any
}

export type CLRequestError = AxiosError<{
  errors: CLError[]
}>
export interface CLayerSku {
  id: string
  attributes: {
    code: number
    inventory: {
      available: boolean
      quantity: number
    }
  }
}

export interface CLayerResource<T = Record<string, unknown>, U = Record<string, unknown>> {
  id: string
  type: ResourceType
  attributes: T & {
    metadata: U
  }
}
