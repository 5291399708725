export interface DragState {
  isDragging: boolean
  xStart: number
  yStart: number
}

export const initialDragState = {
  isDragging: false,
  xStart: 0,
  yStart: 0,
}

const tirggerClickLimit = 10

export const startDragCheck = (e: React.MouseEvent) => {
  return {
    isDragging: true,
    xStart: e.nativeEvent.pageX,
    yStart: e.nativeEvent.pageY,
  }
}

export const endDragCheck = (e: React.MouseEvent, state: DragState, onClick: () => void) => {
  const xMovement = Math.abs(state.xStart - e.nativeEvent.pageX)
  const yMovement = Math.abs(state.yStart - e.nativeEvent.pageY)
  if (xMovement < tirggerClickLimit && yMovement < tirggerClickLimit) onClick()
  return initialDragState
}
