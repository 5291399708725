import axios, { AxiosInstance } from 'axios'
import config from '../../config'
import handleNetworkError from '../../utils/networkErrorHandler'

const client: AxiosInstance = axios.create({
  baseURL: `https://${config.ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/${config.ALGOLIA_INDEX_NAME}`,
  headers: {
    'X-Algolia-API-Key': config.ALGOLIA_API_KEY,
    'X-Algolia-Application-Id': config.ALGOLIA_APP_ID,
  },
  timeout: 90000,
})

client.interceptors.response.use(
  (res) => res,
  (error) => {
    if (!error.response) handleNetworkError()

    return Promise.reject(error)
  },
)

export default client
