import { ByHashZone, LocationState } from './context'
import { DistributionCenter, Market, ShippingCost, toHash, Zone } from '../../..'
import { Error as ErrorType, Country } from '../../types'

export type BEGINNING = { type: 'BEGINNING' }
export type SET_CURRENT_ZONE = { type: 'SET_CURRENT_ZONE'; zone: Zone }
export type SET_CURRENT_MARKET = { type: 'SET_CURRENT_MARKET'; market: Market }
export type SET_CURRENT_DISTRIBUTION_CENTER = {
  type: 'SET_CURRENT_DISTRIBUTION_CENTER'
  distributionCenter: DistributionCenter
}
export type SET_DISTRIBUTION_CENTER_VERSION = {
  type: 'SET_DISTRIBUTION_CENTER_VERSION'
  distributionCenterVersion: number
}
export type SET_DISTRIBUTION_CENTERS = {
  type: 'SET_DISTRIBUTION_CENTERS'
  distributionCenters: DistributionCenter[]
}
export type SET_MARKETS = { type: 'SET_MARKETS'; markets: Market[] }
export type SET_ZONES = { type: 'SET_ZONES'; zones: ByHashZone }
export type SET_SHIPPING_COST = { type: 'SET_SHIPPING_COST'; shippingCost: ShippingCost }
export type SET_COUNTRY = { type: 'SET_COUNTRY'; country: Country }
export type FETCH_MARKETS = { type: 'FETCH_MARKETS'; markets: Market[]; country: Country }
export type ERROR = { type: 'ERROR'; error: ErrorType }

export type LocationActions =
  | SET_DISTRIBUTION_CENTER_VERSION
  | SET_CURRENT_DISTRIBUTION_CENTER
  | SET_DISTRIBUTION_CENTERS
  | SET_CURRENT_MARKET
  | SET_CURRENT_ZONE
  | FETCH_MARKETS
  | SET_MARKETS
  | SET_COUNTRY
  | ERROR
  | BEGINNING
  | SET_ZONES
  | SET_SHIPPING_COST

function reducer(state: LocationState, action: LocationActions): LocationState {
  switch (action.type) {
    case 'BEGINNING': {
      return { ...state, isLoading: true, error: null }
    }

    case 'ERROR': {
      return { ...state, isLoading: false, error: action.error }
    }

    case 'SET_MARKETS': {
      return {
        ...state,
        byHashCities: toHash<Market>(action.markets),
        markets: action.markets,
      }
    }

    case 'SET_ZONES': {
      return {
        ...state,
        byHashZones: action.zones,
      }
    }

    case 'SET_COUNTRY': {
      return {
        ...state,
        country: action.country,
      }
    }

    case 'FETCH_MARKETS': {
      return {
        ...state,
        byHashCities: toHash<Market>(action.markets),
        markets: action.markets,
        isLoading: false,
        country: action.country,
      }
    }

    case 'SET_CURRENT_ZONE': {
      return { ...state, currentZone: action.zone }
    }

    case 'SET_CURRENT_MARKET': {
      return { ...state, currentCity: action.market }
    }

    case 'SET_CURRENT_DISTRIBUTION_CENTER': {
      return {
        ...state,
        currentDistributionCenter: action.distributionCenter,
      }
    }

    case 'SET_DISTRIBUTION_CENTERS': {
      return {
        ...state,
        distributionCenters: action.distributionCenters,
      }
    }
    case 'SET_DISTRIBUTION_CENTER_VERSION': {
      return {
        ...state,
        distributionCenterVersion: action.distributionCenterVersion,
      }
    }

    case 'SET_SHIPPING_COST': {
      return {
        ...state,
        shippingCost: action.shippingCost,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

export default reducer
