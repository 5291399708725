import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'
import { PromotionType } from '@ecommerce/customer-bff/src/types'
import { navigate } from '@reach/router'
import { getByhashWithPromotions } from '@ecommerce/chile-customer-webapp/src/components/ShoppingCart/utils'
import { ErrorLevel, ErrorSource, sendMessageToSentry } from '@ecommerce/chile-customer-webapp/src/utils/sentry'
import { useShoppingCart } from '../ShoppingCart'
import { ProductCart as CartItemType } from '../../types'
import { showToast } from '../../components/molecules/Notification'
import { getCartId, getOrderId, getStoredDistributionCenter, getStoredMarket, setOrderId } from '../../utils/store'
import { useLocation } from '../Location'
import { prepareOrderForCheckout } from '../../services/BFF'
import { DiscountDetails } from '../ShoppingCart/context'
import { lineItemsToCartState } from '../../serviceManagers'
import { log } from '../../utils/log'
import { CLOrder } from '../../services/CommerceLayer'
import { useFirestore } from '../../hooks/useFirestore'
import { useAuth } from '../Auth'
import { FormattedProduct } from '../../services/Algolia'

interface CartProviderState {
  setIsLoadingTotalPrice: Dispatch<SetStateAction<boolean>>
  isLoadingTotalPrice: boolean
  setShowSummary: Dispatch<SetStateAction<boolean>>
  showSummary: boolean
  getTotalPrice: () => number
  getShippingCost: () => {
    operation: number
    text: number
  }
  getDiscounts: () => number
  setSkusToInject: (skus: CartItemType[]) => void
  openEmptyCartAlert: boolean
  setOpenEmptyCartAlert: Dispatch<SetStateAction<boolean>>
  submitOrder: (metadata?: OrderMetadata, email?: string, isGuest?: boolean) => void
  loadingCheckout: boolean
  setLoadingCheckout: Dispatch<SetStateAction<boolean>>
  deletedProducts: FormattedProduct[]
  setDeletedProducts: Dispatch<SetStateAction<FormattedProduct[]>>
  openDeletedProductAlert: boolean
  setOpenDeletedProductAlert: Dispatch<SetStateAction<boolean>>
}

type OrderMetadata = CLOrder['attributes']['metadata']
type PartialMetadata = Partial<OrderMetadata>

export const CartContext = createContext<CartProviderState | undefined>(undefined)

export const CartProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { state } = useShoppingCart()
  const {
    state: { country },
  } = useLocation()
  const { state: authState } = useAuth()
  const { pushCartStateToFirestore } = useFirestore()

  const [isLoadingTotalPrice, setIsLoadingTotalPrice] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [openEmptyCartAlert, setOpenEmptyCartAlert] = useState(false)
  const [loadingCheckout, setLoadingCheckout] = useState(false)
  const [skusToInject, setSkusToInject] = useState<CartItemType[]>([])

  const [deletedProducts, setDeletedProducts] = useState<FormattedProduct[]>([])
  const [openDeletedProductAlert, setOpenDeletedProductAlert] = useState(false)

  const { byHash, promotions } = state

  const getGiftDiscounts = () => {
    return (
      Object.keys(promotions ?? [])
        .map((sku) => promotions && promotions[sku])
        .reduce(
          (acc, promotion) =>
            acc + (promotion?.promotion?.type === PromotionType.A_DISCOUNTS_B ? promotion?.discountAmount ?? 0 : 0),
          0,
        ) ?? 0
    )
  }

  const getDiscounts = () => {
    return state.totalDiscount && state.totalDiscount > 0
      ? state.totalDiscount - getGiftDiscounts()
      : state.globalTotalDiscounted ?? 0
  }

  const getShippingCost = () => {
    return {
      text: state.globalTotal > Number(state.freeOver) ? Number(state.shippingCost) : 0,
      operation:
        Number(state.freeOver) > 0 && state.globalTotal > Number(state.freeOver) ? 0 : Number(state.shippingCost),
    }
  }

  const getTotalPrice = () => {
    return state.globalRawTotal + getShippingCost().operation - (getDiscounts() ?? 0)
  }

  const prepareOrderForCheckoutHandler = async (metadata: PartialMetadata = {}, userEmail?: string) => {
    try {
      const startTime = performance.now()
      const email = userEmail ?? authState.email

      const items = [...getByhashWithPromotions(byHash, promotions), ...skusToInject]

      const { hasStockError, outOfStock, skus, order } = await prepareOrderForCheckout({
        lineItems: items.filter((item) => item.quantity > 0),
        country,
        customerEmail: email,
        orderMetadata: metadata,
      })

      const outOfStockSkuCodes = outOfStock?.map((code) => `${code}`)

      const {
        id,
        attributes: { coupon_code },
      } = order

      if (hasStockError && outOfStockSkuCodes) {
        return { success: false }
      }

      const externalPromotion = skus.find((lineItem) => lineItem.attributes.item_type === 'external_promotions')
      const discountDetails = externalPromotion?.attributes.metadata.discountDetails as DiscountDetails[]
      const totalDiscount = Math.abs(externalPromotion?.attributes.unit_amount_cents ?? 0)

      const newCartState = {
        ...lineItemsToCartState(skus, state),
        couponCode: coupon_code,
        orderId: id,
        discountDetails,
        totalDiscount,
      }
      const cartId = getCartId()

      if (!cartId) throw new Error('No FS cart available')

      pushCartStateToFirestore(cartId, newCartState)
      setOrderId(id)

      log.trace(`prepareForCheckoutHandler laster ${(performance.now() - startTime) / 1000}s`)
      return { success: true }
    } catch (e) {
      log.error(e)
      const error = e.response?.data
      const cartId = getCartId()
      const city = getStoredMarket()

      sendMessageToSentry({
        page: 'global - shoppingCart:',
        message: `On prepareOrderForCheckout: ${error?.message || e?.message}`,
        source: ErrorSource.BFF,
        level: error?.Severity ?? ErrorLevel.Error,
        metadata: {
          orderId: getOrderId(),
          cartId,
          city,
          state,
          customerEmail: authState.email,
          params: { metadata, userEmail },
          error,
        },
      })

      throw e
    }
  }

  const onFormSubmit = async (orderMetadata?: PartialMetadata, email?: string) => {
    const currentDistributionCenter = getStoredDistributionCenter()

    try {
      setLoadingCheckout(true)
      const validationResult = await prepareOrderForCheckoutHandler(
        { ...orderMetadata, distributionCenterSlug: currentDistributionCenter?.slug },
        email,
      )

      if (!validationResult?.success) {
        return setLoadingCheckout(false)
      }

      await navigate(`/checkout`, {
        state: {
          referrer: window.location.pathname,
        },
      })
    } catch (e) {
      setLoadingCheckout(false)

      showToast({ title: 'Error de Conexión', content: 'Por favor intenta nuevamente.', type: 'error' })

      throw e
    } finally {
      setLoadingCheckout(false)
    }
  }

  const value = {
    isLoadingTotalPrice,
    setIsLoadingTotalPrice,
    setShowSummary,
    setSkusToInject,
    showSummary,
    getTotalPrice,
    getShippingCost,
    getDiscounts,
    openEmptyCartAlert,
    setOpenEmptyCartAlert,
    submitOrder: onFormSubmit,
    loadingCheckout,
    setLoadingCheckout,
    deletedProducts,
    setDeletedProducts,
    openDeletedProductAlert,
    setOpenDeletedProductAlert,
  }
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export const useCart = () => {
  const context = useContext(CartContext)
  if (context === undefined) throw new Error('useCart must be used within a CartProvider')
  return context
}
