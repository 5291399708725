import * as React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../utils/breakpoints'

const TitleStyled = styled.h2<StylesProps>`
  margin: 0;
  color: ${(props) => (props.color ? props.color : '#fff')};
  position: ${(props) => (props.position ? props.position : 'static')};
  font-size: 18px;

  @media (${breakpoints.desktop.min}) {
    font-size: 48px;
  }
`

interface StylesProps {
  color: string
  position?: string
}

interface Props extends StylesProps {
  text: string
}

const Title = (props: Props) => {
  const { text, color, position = 'static' } = props

  return (
    <TitleStyled color={color} position={position}>
      {text}
    </TitleStyled>
  )
}

export default Title
