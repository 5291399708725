/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import { ErrorLevel, sendMessageToSentry } from '@ecommerce/chile-customer-webapp/src/utils/sentry'
import { useShoppingCart } from '../ShoppingCart'
import { getStoredDistributionCenter, getStoredMarket, getStoredZone } from '../../utils/store'
import { getSuggestedSkus } from '../../services/BFF'
import { useCartStatus } from '../ShoppingCart/cartStatusContext'
import { FormattedProduct, getProductsBySkus } from '../../services/Algolia'

interface SuggestedProductsState {
  suggestedProducts: FormattedProduct[]
  isAddedSuggestedProduct: boolean
}

const initialState = {
  suggestedProducts: [],
  isAddedSuggestedProduct: false,
}

type Action =
  | { type: 'SET_SUGGESTED_PRODUCTS'; payload: FormattedProduct[] }
  | { type: 'SET_IS_ADDED_SUGGESTED_PRODUCT'; isAdded: boolean }

const suggestedProductsReducer = (state: SuggestedProductsState, action: Action) => {
  switch (action.type) {
    case 'SET_SUGGESTED_PRODUCTS': {
      return {
        ...state,
        suggestedProducts: action.payload,
      }
    }
    case 'SET_IS_ADDED_SUGGESTED_PRODUCT': {
      return {
        ...state,
        isAddedSuggestedProduct: action.isAdded,
      }
    }
    default: {
      return state
    }
  }
}

interface SuggestedProductsContextProps {
  isAddedSuggestedProduct: boolean
  suggestedProducts: FormattedProduct[]
  setIsAddedSuggestedProduct: (isAdded: boolean) => void
  getSuggestedProducts: () => void
}

export const SuggestedProductsContext = React.createContext<SuggestedProductsContextProps>({
  isAddedSuggestedProduct: initialState.isAddedSuggestedProduct,
  suggestedProducts: initialState.suggestedProducts,
  getSuggestedProducts: () => {},
  setIsAddedSuggestedProduct: () => {},
})

SuggestedProductsContext.displayName = 'SuggestedContext'

export const SuggestedProductsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(suggestedProductsReducer, initialState)

  const { state: shoppingState } = useShoppingCart()
  const { setIsLoadingSuggestedSkus, setErrorLoadingSuggestedSkus } = useCartStatus()

  const skus = Object.keys(shoppingState.byHash)
  const zone = getStoredZone()
  const market = getStoredMarket()
  const distributionCenter = getStoredDistributionCenter()

  const setIsAddedSuggestedProduct = useCallback((isAdded) => {
    dispatch({ type: 'SET_IS_ADDED_SUGGESTED_PRODUCT', isAdded })
  }, [])

  const getSuggestedProducts = async () => {
    if (zone && market && distributionCenter && skus) {
      setIsLoadingSuggestedSkus(true)
      try {
        const suggestedSkus = await getSuggestedSkus(skus, zone?.internalName)
        const products = await getProductsBySkus({
          slugLocation: distributionCenter?.slug,
          skus: suggestedSkus.data,
          isSuggestedQuery: true,
        })

        dispatch({ type: 'SET_SUGGESTED_PRODUCTS', payload: products })
      } catch (error) {
        setErrorLoadingSuggestedSkus(true)
        sendMessageToSentry({
          message: `error: ${error?.message ?? ''}`,
          level: ErrorLevel.Warning,
          page: 'edit user',
          metadata: { error: { ...error } },
        })
      } finally {
        setIsLoadingSuggestedSkus(false)
      }
    }
  }

  const value = useMemo<SuggestedProductsContextProps>(
    () => ({
      ...state,
      getSuggestedProducts,
      setIsAddedSuggestedProduct,
    }),
    [state, getSuggestedProducts, setIsAddedSuggestedProduct],
  )

  return <SuggestedProductsContext.Provider value={value}>{children}</SuggestedProductsContext.Provider>
}

export const useSuggestedProducts = () => {
  const context = React.useContext(SuggestedProductsContext)
  if (Object.values(context).some((value) => value === undefined)) {
    throw new Error('useSuggestedProducts must be used within SuggestedProductsProvider')
  }
  return context
}
