import { graphql, useStaticQuery } from 'gatsby'
import { DistributionCenter, useLocation, Zone } from '@ecommerce/shared'

export interface LocationOptionResult {
  title: string
  slug: string
  isExternal: boolean
  zones: Zone[]
  distributionCenters: DistributionCenter[]
  sublocations?: string[]
}

export interface LocationOptionsQueryResults {
  allContentfulGlobal: {
    nodes: [{ locationSelectorOptions: LocationOptionResult[] }]
  }
}

const extractLocationSelectorOptions = (results: LocationOptionsQueryResults) =>
  results.allContentfulGlobal.nodes[0].locationSelectorOptions

export const useLocationSelectorOptionsQuery = () =>
  extractLocationSelectorOptions(
    useStaticQuery(graphql`
      query LocationOptionsQuery {
        allContentfulGlobal {
          nodes {
            locationSelectorOptions {
              title
              slug
              isExternal
              sublocations
            }
          }
        }
      }
    `),
  )

export interface LocationSelectorOption extends LocationOptionResult {
  id: number | null
}

export const useLocationSelectorOptions = () => {
  const {
    state: { markets },
  } = useLocation()

  const locations = useLocationSelectorOptionsQuery()

  return locations
    .filter((location) => location.isExternal || markets.some((market) => market.slug === location.slug))
    .map((location) => {
      const locationMarket = markets.filter((market) => market.slug === location.slug)[0]
      return {
        ...location,
        id: locationMarket?.id ?? null,
        zones: locationMarket?.zones,
        distributionCenters: locationMarket?.distributionCenters,
      }
    })
}
