import { useCallback, useState } from 'react'
import { checkCustomerDniExist } from '../../../services/SignUp'
import { showToast } from '../../molecules/Notification'
import { FormData } from './index'
import { FormGuestData } from '../FormGuest'

type CommonFormData = keyof FormData & keyof FormGuestData

interface CheckEmailProps {
  getValues: () => FormData | FormGuestData
  setError: (name: CommonFormData, error: { type: string; message: string }) => void
  clearErrors: (name?: CommonFormData | undefined) => void
}

const useCheckEmail = ({ getValues, setError, clearErrors }: CheckEmailProps) => {
  const [isChecking, setIsChecking] = useState(false)

  const checkResponse = useCallback(async () => {
    const response = await checkCustomerDniExist({ dni: getValues().email })
    if (response.status === 200 && response.data.customerExists && !response.data.isGuest) {
      const message = 'Ya tenemos este email asociado a una cuenta.'

      showToast({
        content: message,
        title: `Ya existe este email`,
        type: 'error',
      })

      setError('email', {
        type: 'manual',
        message,
      })
    } else {
      clearErrors('email')
    }
  }, [getValues, setError, clearErrors])

  const checkEmail = useCallback(async () => {
    setIsChecking(true)
    try {
      await checkResponse()
    } catch (err) {
      showToast({
        content: `Ha ocurrido un error en la búsqueda del email`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
    } finally {
      setIsChecking(false)
    }
  }, [checkResponse])

  return { checkEmail, isChecking, setIsChecking }
}

export default useCheckEmail
