import { useState, useCallback, useLayoutEffect } from 'react'

export interface DimensionObject {
  width: number
  height: number
  top: number
  left: number
  x: number
  y: number
  right: number
  bottom: number
}

export type UseDimensionsHook<T> = [
  (node: HTMLElement & T) => void,
  { width: number | null; height: number | null } | DimensionObject,
  () => number | null,
  (HTMLElement & T) | null,
]

export interface UseDimensionsArgs {
  liveMeasure?: boolean
}

function getDimensionObject<T>(node: HTMLElement & T): DimensionObject {
  const rect = node.getBoundingClientRect()

  const getRectVal = (rectObject: DOMRect, key: 'x' | 'y', fallbackKey: 'x' | 'y' | 'top' | 'left') => {
    if (rectObject[key] || rectObject[key] === 0) return rectObject[key]
    return rectObject[fallbackKey]
  }

  return {
    width: rect.width,
    height: rect.height,
    top: getRectVal(rect, 'x', 'top'),
    left: getRectVal(rect, 'y', 'left'),
    x: getRectVal(rect, 'x', 'left'),
    y: getRectVal(rect, 'y', 'top'),
    right: rect.right,
    bottom: rect.bottom,
  }
}

function useDimensions<T>(): UseDimensionsHook<T> {
  const [dimensions, setDimensions] = useState<DimensionObject | { width: null; height: null }>({
    width: null,
    height: null,
  })
  const [node, setNode] = useState<(HTMLElement & T) | null>(null)

  const ref = useCallback((nodeObject) => {
    setNode(nodeObject)
  }, [])

  const measure = (element: (HTMLElement & T) | null) => {
    if (element) return window.requestAnimationFrame(() => setDimensions(getDimensionObject<T>(element)))
    return null
  }
  useLayoutEffect(() => {
    if (node) {
      measure(node)
      window.addEventListener('resize', () => measure(node))

      return () => {
        window.removeEventListener('resize', () => measure(node))
      }
    }
  }, [node])

  return [ref, dimensions, () => measure(node), node]
}

export default useDimensions
