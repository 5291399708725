import styled, { css } from 'styled-components'
import { CalendarContainer } from 'react-datepicker'
import { Label, Status } from '../TextField/Styled'
import { ZIndex } from '../../../utils/Constants'

export const ContentPicker = styled.div<{ status?: Status }>`
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 4px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  max-height: 56px;
  ${({ status, theme }) => (status === 'error' ? `border: 1px solid ${theme.colors.error};` : '')}
  svg {
    fill: #5f6f86;
  }
  .react-datepicker-wrapper {
    margin-left: 10px;
    .react-datepicker__input-container {
      input {
        all: unset;
        border: none;
        font-weight: 325;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: -0.24px;
        color: #5f6f86;
        width: inherit;
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      transform: translate3d(0px, 45.5px, 0px) !important;
      z-index: ${ZIndex.medium};
    }
  }
`

export const CalendarContent = styled.div<{ status?: Status; value?: string }>`
  padding: 31px 27px;
  display: flex;
  flex-direction: column;
  .react-datepicker__header {
    background: ${({ theme }) => theme.colors.white};
    border: none;
    .react-datepicker__current-month {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      text-transform: capitalize;
      margin-bottom: 26px;
    }
    .react-datepicker__day-names {
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  .react-datepicker__month {
    .react-datepicker__week {
      .react-datepicker__day {
        font-weight: 325;
        font-size: 11px;
        outline: none;
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected {
        background-color: red;
        border-radius: 100%;
        outline: none;
      }
    }
  }
  .content-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    button {
      padding: 6px 22px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      height: auto;
    }
  }
`
