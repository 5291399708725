import React, { createContext, Dispatch, ReactNode, SetStateAction, useState, useEffect } from 'react'
import { useModal, MouseEventButton } from '../../..'
import ModalBox from '../../components/molecules/ModalBox'

type Modal = Omit<ReturnType<typeof useModal>, 'Modal'>

interface State extends Modal {
  setBody: Dispatch<SetStateAction<ReactNode>>
  setTitle: Dispatch<SetStateAction<string>>
  setNoClose: Dispatch<SetStateAction<boolean>>
}

export const ActivityDialogContext = createContext<State | undefined>(undefined)

export function ActivityDialogProvider(props: { children: ReactNode }) {
  const { children } = props

  const [title, setTitle] = useState('')
  const [body, setBody] = useState<ReactNode>(null)
  const [noClose, setNoClose] = useState(false)

  const { onCloseModal, onOpenModal, onToggleModal, ref, isOpen } = useModal()

  return (
    <ActivityDialogContext.Provider
      value={{ setTitle, setBody, onCloseModal, onOpenModal, onToggleModal, ref, isOpen, setNoClose }}
    >
      <ActivityDialogContext.Consumer>
        {(value) => {
          function onClose(e?: MouseEventButton) {
            if (value?.onCloseModal) {
              return value.onCloseModal(e)
            }
          }

          return (
            <>
              {children}
              <ModalBox title={title} isOpen={value?.isOpen} onClose={() => onClose()} noClose={noClose}>
                {body}
              </ModalBox>
            </>
          )
        }}
      </ActivityDialogContext.Consumer>
    </ActivityDialogContext.Provider>
  )
}

export function useActivityDialog() {
  const modal = React.useContext(ActivityDialogContext)

  if (modal === undefined) {
    throw new Error('useActivityDialog must be used within a ActivityDialogContext')
  }

  return modal
}
