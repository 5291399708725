import { createContext } from 'react'
import { AuthActions } from './reducer'
import { useModal } from '../../..'

export const AuthState = {
  isAuth: false,
  isLoading: false,
  email: '',
  ownerId: '',
  dni: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  city: 0,
  market: 0,
  registered: false,
  hasToken: false,
  hasAnonymousToken: false,
  favoriteSkus: [] as number[],
  availabilitySkus: [] as number[],
  guest: {
    firstName: '',
    lastName: '',
  },
  documentType: '',
  billingName: '',
  dniComplement: '',
  isUpdated: false,
  isBillingName: false,
  customerGroup: undefined,
}

export type AuthState = typeof AuthState

export const AuthContext = createContext<AuthState>(AuthState)

export type AuthDispatch = (action: AuthActions) => void
export const DispatchContext = createContext<AuthDispatch | undefined>(undefined)
export const ModalContext = createContext<Omit<useModal, 'Modal'> | undefined>(undefined)
