import { useEffect, useState, useRef } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { Country } from '../types'
import config from '../config'

export type CountryCode = Country
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus
type PlaceResult = google.maps.places.PlaceResult
type AutocompletePrediction = google.maps.places.AutocompletePrediction
type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken
type PlacesService = google.maps.places.PlacesService

interface Input {
  countryCode: CountryCode
  city: string
  attrContainer: HTMLDivElement | null
}

export type Geocode = {
  country: CountryCode
  latitude: number
  longitude: number
}

interface Error {
  status?: number
  code: string
  description: string
  metadata?: Record<string, unknown>
}

const BlacklistRegex = new RegExp(config.MAPS_QUERY_BLACKLIST)
const sanitizedRegions = config.SANITIZE_REGIONS.split('/').map((region) => {
  const splitted = region.split('-')
  const [change, replace] = splitted[1].split('|')

  return {
    city: splitted[0],
    change,
    replace,
  }
})

export const useGooglePlaceAutocomplete = (input: Input) => {
  const [sessionToken, setSessionToken] = useState<AutocompleteSessionToken | undefined>()
  const { ready, suggestions, setValue } = usePlacesAutocomplete({
    requestOptions: {
      sessionToken,
      componentRestrictions: { country: input.countryCode },
      types: ['address'],
    },
    callbackName: process.env.GATSBY_GOOGLE_MAPS_API_CALLBACK_NAME,
  })

  const [placesService, setPlacesService] = useState<PlacesService | undefined>()
  const [selected, setSelected] = useState<AutocompletePrediction | undefined>()
  const [geocode, setGeocode] = useState<Geocode | undefined>()
  const [loadingGeocode, setLoadingGeocode] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const sanitizeObj = sanitizedRegions.find((region) => region.city === input.city)
  const blacklistedQuery = useRef<string | null>(null)
  const value = useRef<string>('')
  const queryCountry = input.countryCode === 'CL' ? 'Chile' : 'Bolivia'

  const createSessionToken = () => setSessionToken(new window.google.maps.places.AutocompleteSessionToken())

  const onInitialization = () => {
    if (ready && !placesService && input.attrContainer) {
      setPlacesService(new window.google.maps.places.PlacesService(input.attrContainer))
      createSessionToken()
    }
  }

  const onChangeSuggestions = () => {
    if (suggestions?.status !== 'OK') {
      setError({
        code: 'AUTOCOMPLETE_ERROR',
        description: 'Error obteniendo sugerencias',
        metadata: { status: suggestions.status },
      })
    } else {
      setError(undefined)
    }
  }

  const processGeoCodingResult = (result: PlaceResult, status: PlacesServiceStatus) => {
    if (status !== 'OK') {
      setError({
        code: 'GEOCODE_ERROR',
        description: 'Error obteniendo detalles del lugar',
        metadata: { status },
      })
    } else if (!result?.geometry?.location) {
      setError({
        code: 'GEOCODE_ERROR',
        description: 'Geolocalización no entrega coordenadas',
      })
    } else {
      setGeocode({
        country: input.countryCode,
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
      })
      createSessionToken()
      setError(undefined)
    }
    setLoadingGeocode(false)
  }

  const onChangeSelected = () => {
    if (placesService && selected) {
      setLoadingGeocode(true)
      placesService.getDetails({ placeId: selected.place_id, sessionToken }, processGeoCodingResult)
    } else {
      setGeocode(undefined)
      setError(undefined)
    }
  }

  const sanitizeQuery = (text: string, regex?: RegExp): string => {
    const replaceCity = input.city === sanitizeObj?.city ? sanitizeObj?.change : input.city
    let sanitized = text.replace(`, ${replaceCity}`, '').replace(`, ${queryCountry}`, '')

    if (regex) sanitized = text.replace(regex, '')

    if (replaceCity === sanitizeObj?.change) {
      if (!sanitized.includes(sanitizeObj?.replace)) {
        sanitized = `${sanitized}, ${sanitizeObj?.change}, ${sanitizeObj?.replace}`
      } else {
        sanitized = `${sanitized}, ${sanitizeObj?.change}`
      }
    } else {
      sanitized = `${sanitized}, ${input.city}`
    }

    return sanitized
  }

  const onSuggestionsFailure = (): void => {
    const shouldRetry = !suggestions.loading && suggestions.data.length === 0

    if (shouldRetry && blacklistedQuery.current) {
      const newQuery = sanitizeQuery(blacklistedQuery.current, BlacklistRegex)
      setValue(newQuery, true)
    }
  }

  const onInputChange = (query: string, refetch = true): void => {
    if (BlacklistRegex.test(query)) {
      blacklistedQuery.current = query
    } else {
      blacklistedQuery.current = null
    }

    value.current = query
    setValue(sanitizeQuery(query), refetch)
  }

  const onCityChange = () => {
    if (ready) {
      if (value.current) setValue(sanitizeQuery(value.current))

      createSessionToken()
    }
  }

  useEffect(onInitialization, [ready, input.attrContainer])
  useEffect(onSuggestionsFailure, [suggestions])
  useEffect(onChangeSuggestions, [suggestions])
  useEffect(onChangeSelected, [selected])
  useEffect(onCityChange, [input.city])

  return {
    setQuery: onInputChange,
    setSelected,
    ready,
    loadingSuggestions: suggestions.loading,
    suggestions: suggestions.data,
    loadingGeocode,
    geocode,
    error,
  }
}
