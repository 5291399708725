import styled from 'styled-components'

export default styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: #000000a6;

  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
`
