import React from 'react'
import styled from 'styled-components'

type Step = {
  icon: React.ReactNode
  label?: string
}

type StyleProps = {
  currentStep: number
}

type Props = StyleProps & {
  steps: Step[]
  className?: string
}

const StyledCheckoutSteps = styled.div<StyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 320px;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.grey};
    z-index: -1;
  }

  div {
    background-color: ${({ theme }) => theme.colors.grey};

    &:nth-child(${({ currentStep }) => `-n+${currentStep}`}) {
      background-color: ${({ theme }) => theme.colors.red};
    }
  }
`

const StyledStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;

  svg {
    width: 28px;
    height: 28px;
    fill: ${({ theme }) => theme.colors.white};

    g {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const CheckoutSteps = ({ currentStep, steps, className }: Props) => (
  <StyledCheckoutSteps className={className} currentStep={currentStep}>
    {steps.map(({ icon, label }) => (
      <StyledStep title={label} key={label}>
        {icon}
      </StyledStep>
    ))}
  </StyledCheckoutSteps>
)

export default CheckoutSteps
