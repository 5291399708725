import React, { useState, InputHTMLAttributes } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import { Icon } from '@ecommerce/chile-customer-webapp/src/components/Icon/Icon'
import Button from '../../atoms/Button'
import { Wrapper, Label, ControlSection, Status } from '../TextField/Styled'
import { ContentPicker, CalendarContent } from './styles'
import InfoMessage from '../../atoms/InfoMessage'

registerLocale('es', es)

type Props<T = any> = {
  label?: string
  helpMessage?: string
  errorMessage?: string
  className?: string
  status?: Status
  placeholder?: string
  onSelect: (selected: T) => unknown
  setStartDate: (date: Date | null) => void
  startDate: Date | null
}

const SelectDate = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { label, helpMessage, errorMessage, className, status, placeholder, onSelect, setStartDate, startDate } = props

  const onChangeDate = (date: Date | null) => {
    setStartDate(date)
    onSelect(date)
  }
  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  const onCancel = () => {
    setStartDate(null)
    setIsOpen(false)
  }

  const MyContainer = ({ className: classNameValue, children }: { className: string; children: Node }) => {
    return (
      <div>
        <CalendarContent value="calendat-content" className={classNameValue} onClick={stopPropagation}>
          <div style={{ position: 'relative' }}>{children}</div>
          <div className="content-buttons">
            <Button btnType="secondary" onClick={() => onCancel()}>
              Cancelar
            </Button>
            <Button onClick={() => setIsOpen(false)}>Aceptar</Button>
          </div>
        </CalendarContent>
      </div>
    )
  }

  return (
    <Wrapper className={`${className} status--${status}`} status={status}>
      <Label className="Select__label">{label}</Label>

      <ControlSection>
        <ContentPicker onClick={() => setIsOpen(!isOpen)} status={status}>
          <Icon iconId="calendar" width={24} height={24} />
          <DatePicker
            open={isOpen}
            selected={startDate}
            dateFormat="dd/MM/yyyy"
            closeOnScroll
            placeholderText={placeholder}
            onChange={onChangeDate}
            calendarContainer={MyContainer}
            locale="es"
            minDate={new Date()}
          />
          <Icon iconId={isOpen ? 'chevron_up' : 'chevron_down'} width={24} height={24} />
        </ContentPicker>
      </ControlSection>
      <InfoMessage
        className={`TextField__help status--${status}`}
        isHidden={!(errorMessage || helpMessage)}
        message={status === 'error' ? errorMessage : helpMessage}
      />
    </Wrapper>
  )
}

export default SelectDate
