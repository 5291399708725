/* eslint-disable no-plusplus */

export type ValidationKeys = 'NUMBERS_ONLY' | 'LETTERS_ONLY'

export const validationRegexObj: { [key in ValidationKeys]: RegExp } = {
  NUMBERS_ONLY: /^[0-9]*$/g,
  LETTERS_ONLY: /^[a-zA-zá-źÁ-Ź ]+$/g,
}

export const validateInputField = (val: string | number, validationType: ValidationKeys) => {
  const regex = new RegExp(validationRegexObj[validationType])

  if (regex) {
    return regex.test(`${val}`)
  }

  return false
}

// http://stackoverflow.com/questions/4060004/calculate-age-in-javascript/7091965#7091965
export const getAge = (dateString: string) => {
  if (/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(dateString)) {
    const [day, _month, year] = dateString.split('/')

    const today = new Date()
    const birthDate = new Date(Number(year), Number(_month) - 1, Number(day))

    let age = today.getUTCFullYear() - birthDate.getUTCFullYear()
    const month = today.getUTCMonth() - birthDate.getUTCMonth()
    if (month < 0 || (month === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
      age -= 1
    }
    return age >= 18
  }
  return false
}

export const cleanRut = (rut: string) => {
  return typeof rut === 'string' ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase() : ''
}

export const validateRut = (value: string) => {
  if (!value || typeof value !== `string` || !/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(value)) return false

  const rut = cleanRut(value)

  let t = parseInt(rut.slice(0, -1), 10)
  let m = 0
  let s = 1

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11
    t = Math.floor(t / 10)
  }

  const v = s > 0 ? `${s - 1}` : 'K'

  return v === rut.slice(-1)
}

export const formatRut = (value: string) => {
  const rut = cleanRut(value)

  let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`
  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`
  }

  return result
}

export const stripTrailingSlash = (str: string) => (str.endsWith('/') ? str.slice(0, -1) : str)

export const insertString = (originalString: string, newString: string, index: number) => {
  return originalString.substr(0, index) + newString + originalString.substr(index)
}

export const formatDate = (dateString: string) => {
  const cleanString = dateString.replace(/\D/g, '') // Removes all non-numeric characters
  let output = cleanString.substr(0, 8) // Limit to 8 digits
  const size = dateString.length

  if (size > 4) output = insertString(output, '/', 4)

  if (size > 2) output = insertString(output, '/', 2)

  return output
}
