import { AxiosPromise } from 'axios'
import { log } from '../../utils/log'
import fetch from './fetch'
import config from '../../config'
import { LineItem } from '../../../../../apps/customer-bff/src/services/CommerceLayer/types'

const { COUNTRY } = config

interface ArgumentsDeleteLineItem {
  lineItemId: string
}

interface ArgumentsUpdateLineItem {
  lineItemId: string
  quantity: number
}

export function deleteLineItem(params: ArgumentsDeleteLineItem): AxiosPromise {
  const { lineItemId } = params
  log.trace('DELETING LINE ITEM', lineItemId)

  return fetch(`line_items`, { method: 'DELETE', data: { country: COUNTRY, id: lineItemId } })
}

export function updateQuantityLineItem(params: ArgumentsUpdateLineItem): AxiosPromise {
  const { lineItemId, quantity } = params
  log.trace('UPDATING LINE ITEM QUANTITY', lineItemId)

  return fetch(`line_items`, { method: 'PATCH', data: { country: COUNTRY, id: lineItemId, lineItem: { quantity } } })
}
