import { keyframes, css } from 'styled-components'

export const opacitySkeleton = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
`

export const waveSkeleton = keyframes`
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      background-position: -468px 0;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      background-position: 468px 0;
    }
`

export const spinner = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`

export const skeletonAnimationStyles = css`
  will-change: transform;
  animation: ${waveSkeleton} 3.5s linear infinite forwards;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: #e6e6e6;
  background: linear-gradient(90deg, rgba(204, 216, 229, 0.86) 8%, rgba(204, 216, 229, 0.93) 18%, #ccd8e5 33%);
  background-size: 100% 100%;
  position: relative;
`
