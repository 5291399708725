import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Country } from '@ecommerce/shared'
import { BaseRootStateType } from '../types'
import secrets from '../../config/secrets'

const { COUNTRY } = secrets

const isBolivia = COUNTRY === Country.BOLIVIA

interface FilterPayload {
  categoryNames: string[]
}

interface ReturnabilityState {
  searchState: {
    configure: {
      filters: string
      hitsPerPage: number
      facetFilters: [string[]]
    }
    refinementList: { [key: string]: string[] }
  }
}

const initialState: ReturnabilityState = {
  searchState: {
    configure: {
      filters: `${isBolivia ? 'hasStock:true AND ' : ''} (returnability:RETORNABLE) AND NOT isDummy:true`,
      hitsPerPage: 96,
      facetFilters: [[]],
    },
    refinementList: {},
  },
}

export const promotionStateSlice = createSlice({
  name: 'algolia/returnabilityState',
  initialState,
  reducers: {
    setReturnabilityFilters: (state, action: PayloadAction<FilterPayload>) => {
      const { categoryNames } = action.payload

      if (categoryNames && Array.isArray(categoryNames) && categoryNames.length > 0) {
        state.searchState.configure.facetFilters[0] = Array.from(
          new Set([
            ...categoryNames.map((cat) => `mainCategoryName:${cat}`),
            ...state.searchState.configure.facetFilters[0],
          ]),
        )
      }
    },
    setRefinementList: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      state.searchState.refinementList = {
        ...state.searchState.refinementList,
        ...action.payload,
      }
    },
    resetRefinementLists: (state) => {
      state.searchState.refinementList = initialState.searchState.refinementList
    },
  },
})

export const { resetRefinementLists, setReturnabilityFilters, setRefinementList } = promotionStateSlice.actions

export const selectReturnabilityConfigure = (state: BaseRootStateType) =>
  state.algolia.returnability.searchState.configure
export const selectReturnabilityFacetFilters = (state: BaseRootStateType) =>
  state.algolia.returnability.searchState.configure.facetFilters

export default promotionStateSlice.reducer
