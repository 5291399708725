import fetch from './fetch'

export * from './general'
export * from './orders'
export * from './skus'
export * from './customers'

export const registerADCPromoEntry = (data: { email: string; sku: number; orderId: string }) => {
  return fetch(`/adc-promo`, { method: 'POST', data })
}
