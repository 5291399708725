import { useCallback, useState } from 'react'
import { checkCustomerDniAndEmailExist } from '../../../services/SignUp'
import { showToast } from '../../molecules/Notification'
import { userDataErrorMessage } from '../../../utils/userDataErrorMessage'
import { getStoredMarket } from '../../../utils/store'
import { FormData } from './index'
import { FormGuestData } from '../FormGuest'

type CommonFormData = keyof FormData & keyof FormGuestData

interface CheckEmailProps {
  getValues: () => FormData | FormGuestData
  setError: (name: CommonFormData, error: { type: string; message: string }) => void
  clearErrors: (name?: CommonFormData | undefined) => void
}

const useCheckEmailBolivia = ({ getValues, setError, clearErrors }: CheckEmailProps) => {
  const currentMarket = getStoredMarket()

  const [isChecking, setIsChecking] = useState(false)

  const clearEmailAndDniErrors = useCallback(() => {
    clearErrors('email')
    clearErrors('dni')
  }, [clearErrors])

  const handleCoincidence = useCallback(
    (response) => {
      const errorData = userDataErrorMessage({
        isGuest: response.data.isGuest,
        emailCoincidence: response.data.emailCoincidence,
        dniCoincidence: response.data.dniCoincidence,
        country: currentMarket,
      })

      if (errorData) {
        showToast({
          content: errorData.content,
          title: errorData.title,
          type: 'error',
        })

        if (response.data.emailCoincidence || response.data.dniCoincidence) {
          setError(response.data.emailCoincidence ? 'email' : 'dni', {
            type: 'manual',
            message: errorData.message,
          })
        }

        if (response.data.isGuest && response.data.dniCoincidence && response.data.emailCoincidence) {
          clearEmailAndDniErrors()
        }
      }
    },
    [setError, currentMarket],
  )

  const checkEmailBolivia = useCallback(async () => {
    setIsChecking(true)
    const formValues = getValues()

    try {
      const response = await checkCustomerDniAndEmailExist({ dni: formValues.dni, email: formValues.email })
      if (response.status === 200) {
        if (response.data.customerExists) {
          handleCoincidence(response)
        }
        if (!response.data.customerExists) {
          clearEmailAndDniErrors()
        }
      }
    } catch (err) {
      showToast({
        content: `Ha ocurrido un error en la búsqueda del email`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
    } finally {
      setIsChecking(false)
    }
  }, [getValues, setError, clearErrors])

  return { checkEmailBolivia, isCheckingBolivia: isChecking, setIsCheckingBolivia: setIsChecking }
}

export default useCheckEmailBolivia
