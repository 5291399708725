import { AxiosPromise, AxiosResponse } from 'axios'
import fetch, { headers } from './fetch'
import { CLListResponse, CLObject, CLRetrieveResponse, ResourceType } from './types'

export function getOrderShipments(orderId: string): AxiosPromise<CLListResponse> {
  return fetch(`/api/orders/${orderId}/shipments`, { headers, method: 'GET' })
}

export function setShippingDetails(
  shipmentId: string,
  shippingMethodId: string,
  shippingDate?: string,
): AxiosPromise<CLRetrieveResponse> {
  const data = {
    data: {
      type: 'shipments',
      id: shipmentId,
      attributes: {
        metadata: {
          shippingDate,
        },
      },
      relationships: {
        shipping_method: {
          data: {
            type: 'shipping_methods',
            id: shippingMethodId,
          },
        },
      },
    },
  }
  return fetch(`api/shipments/${shipmentId}`, { data, headers, method: 'PATCH' })
}

export const updateShippingAddress = async (orderId: string, shippingAddressId: string, shippingMethod: string) => {
  const query = `/api/orders/${orderId}?include=shipments`
  const data = {
    data: {
      type: ResourceType.ORDERS,
      id: orderId,
      relationships: {
        shipping_address: {
          data: {
            type: ResourceType.ADDRESSES,
            id: shippingAddressId,
          },
        },
      },
    },
  }

  const responseData = await fetch(query, {
    data,
    headers,
    method: 'PATCH',
  })

  const {
    data: { included },
  }: AxiosResponse<{ included: CLObject[] }> = responseData

  return included?.map((shipment) => setShippingDetails(shipment.id, shippingMethod))
}
