/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, ReactNode, useCallback, useMemo } from 'react'

interface UIState {
  displayCartDrawer: boolean
  displaySuggestedProducts: boolean
}

const initialState = {
  displayCartDrawer: false,
  displaySuggestedProducts: false,
}

type Action =
  | { type: 'OPEN_DRAWER_CART' }
  | { type: 'CLOSE_DRAWER_CART' }
  | { type: 'OPEN_SUGGESTED_PRODUCTS' }
  | { type: 'CLOSE_SUGGESTED_PRODUCTS' }

const uiReducer = (state: UIState, action: Action) => {
  switch (action.type) {
    case 'OPEN_DRAWER_CART': {
      return {
        ...state,
        displayCartDrawer: true,
      }
    }
    case 'CLOSE_DRAWER_CART': {
      return {
        ...state,
        displayCartDrawer: false,
      }
    }
    case 'OPEN_SUGGESTED_PRODUCTS': {
      return {
        ...state,
        displaySuggestedProducts: true,
      }
    }
    case 'CLOSE_SUGGESTED_PRODUCTS': {
      return {
        ...state,
        displaySuggestedProducts: false,
      }
    }
    default: {
      return state
    }
  }
}

interface UIContextProps {
  displayCartDrawer: boolean
  displaySuggestedProducts: boolean
  openDrawerCart: () => void
  closeDrawerCart: () => void
  openSuggestedProducts: () => void
  closeSuggestedProducts: () => void
}

export const UIContext = React.createContext<UIContextProps>({
  displayCartDrawer: initialState.displayCartDrawer,
  displaySuggestedProducts: initialState.displaySuggestedProducts,
  openDrawerCart: () => {},
  closeDrawerCart: () => {},
  openSuggestedProducts: () => {},
  closeSuggestedProducts: () => {},
})

UIContext.displayName = 'UIContext'

export const UIProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openDrawerCart = useCallback(() => {
    dispatch({ type: 'OPEN_DRAWER_CART' })
  }, [])

  const closeDrawerCart = useCallback(() => {
    dispatch({ type: 'CLOSE_DRAWER_CART' })
  }, [])

  const openSuggestedProducts = useCallback(() => {
    dispatch({ type: 'OPEN_SUGGESTED_PRODUCTS' })
  }, [])

  const closeSuggestedProducts = useCallback(() => {
    dispatch({ type: 'CLOSE_SUGGESTED_PRODUCTS' })
  }, [])

  const value = useMemo<UIContextProps>(
    () => ({
      ...state,
      openDrawerCart,
      closeDrawerCart,
      openSuggestedProducts,
      closeSuggestedProducts,
    }),
    [closeDrawerCart, openDrawerCart, closeSuggestedProducts, openSuggestedProducts, state],
  )

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (Object.values(context).some((value) => value === undefined)) {
    throw new Error('useUI must be used within a UIProvider')
  }
  return context
}
