import React from 'react'
import styled from 'styled-components'
import { Icon } from '@ecommerce/chile-customer-webapp/src/components/Icon/Icon'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  isAuth: boolean
  onAddNotify: () => void
}

const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 8px;
  width: 151px;

  .mail-icon {
    color: ${({ theme }) => theme.colors.red};
  }

  .notify-container {
    display: flex;
    align-items: center;
  }

  span {
    color: ${({ theme }) => theme.colors.red};
    font-size: inherit;
    font-weight: bold;
  }
`

const NotifyButton = ({ isAuth, onAddNotify, ...restProps }: Props) => {
  const [callToAction, buttonText] = React.useMemo(() => {
    if (!isAuth) return ['¿Quieres saber cuando esté en promoción?', 'Avísame']

    return ['Notificarme', ' cuando esté disponible']
  }, [isAuth])

  function onPreventAndStop(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    return (fn?: () => void) => {
      if (fn) fn()
    }
  }

  return (
    <Wrapper
      onMouseUp={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => onPreventAndStop(e)(onAddNotify)}
      title={buttonText}
      type="button"
      {...restProps}
    >
      <div className="notify-container">
        {isAuth && <Icon className="mail-icon" iconId="mail" size="22" />}
        <span>{`${callToAction} `}</span>
      </div>
      {buttonText}
    </Wrapper>
  )
}

export default NotifyButton
