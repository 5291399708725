import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BaseRootStateType } from '../types'

interface FilterState {
  skus: string
}

const initialState: FilterState = {
  skus: '',
}

export const filterSlice = createSlice({
  name: 'algolia/filters',
  initialState,
  reducers: {
    filterBySkus: (state, action: PayloadAction<string[]>) => {
      state.skus = `AND (${action.payload.map((sku) => `sku=${sku}`).join(' OR ')})`
    },
    resetFilterBySkus: (state) => {
      state.skus = ''
    },
  },
})

export const { filterBySkus, resetFilterBySkus } = filterSlice.actions

export const selectSkus = (state: BaseRootStateType) => state.algolia.filters.skus

export type filterSliceType = typeof filterSlice

export default filterSlice.reducer
