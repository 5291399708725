import React from 'react'
import styled from 'styled-components'
import { breakpoints, toCssPrefix, getStoredMarket, useLocation } from '@ecommerce/shared'
import { Link } from 'gatsby'
import FooterCollapse from './FooterCollapse/FooterCollapse'
import { useFooterQuery } from './useFooterQuery'
import { Icon } from '../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('Footer__')

const FooterWrapper = styled.footer`
  display: block;
  background: ${({ theme }) => theme.colors.background.layout || theme.colors.brand.default};
  color: ${({ theme }) => theme.colors.iconsColor};
  width: 100%;
  padding: 14px 24px 16px;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'list'
    'logo'
    'social'
    'info';
  .${cssPrefix} {
    &list {
      display: none;
    }
    &collapse {
      grid-area: list;
    }
    &list-content {
      padding-top: 14px;
      display: flex;
      flex-direction: column;
    }
    &list-option {
      all: unset;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.06px;
      margin: 6px 0;
    }
    &content-logo {
      grid-area: logo;
      width: 100%;
      padding: 25px 0;
      display: flex;
      justify-content: center;
      a {
        all: unset;
        cursor: pointer;
      }
    }
    &logo {
      width: 146px;
      svg {
        width: 146px;
        height: 46px;
        fill: ${({ theme }) => theme.colors.brand.default};
      }
    }
    &content-social {
      grid-area: social;
      display: flex;
      align-items: center;
      flex-direction: column;
      span {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.1px;
        font-weight: 600;
        margin-bottom: 12px;
      }
    }
    &content-ccs-logo-container {
      width: 100%;
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }
    &content-ccs-logo {
      width: 80px;
      height: 80px;
    }
    &social {
      a {
        margin: 0 9px;
        svg {
          width: 30px;
          height: 30px;
          fill: ${({ theme }) => theme.colors.iconsColor};
        }
      }
      a :first-child {
        margin-left: 0;
      }
      a :last-child {
        margin-right: 0;
      }
    }
    &content-info {
      grid-area: info;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0.055px;
      text-align: center;
      margin-top: 42px;
      span {
        margin: 0 5px;
        font-weight: 400;
        a {
          all: unset;
          cursor: pointer;
          font-weight: 600;
          margin: 0 5px;
        }
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &content-ccs-logo-container {
        gap: 24px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    padding: 32px 0 12px;
    grid-template-columns: 0.4fr 1fr 0.4fr;
    grid-template-areas:
      'logo list social'
      'info info info';
    .${cssPrefix} {
      &collapse {
        display: none;
      }
      &list {
        all: unset;
        grid-area: list;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      &list-wrapper {
        all: unset;
      }
      &list-title {
        font-weight: 600;
      }
      &content-logo {
        align-items: initial;
        padding: 0;
        justify-content: center;
      }
      &content-social {
        align-items: flex-start;
        width: fit-content;
        margin: 0 auto;
      }
      &content-ccs-logo-container {
        justify-content: flex-end;
        margin-top: 50px;
      }
      &content-ccs-logo {
        width: 88px;
        height: 88px;
      }
      &content-info {
        font-size: 15px;
        display: flex;
        align-items: end;
        justify-content: center;
      }
    }
    br {
      display: none;
    }
  }
`

interface OptionLink {
  href: string
  label: string
}

export const Footer = () => {
  const data = useFooterQuery()
  const currentCity = getStoredMarket()

  const { isBolivia } = useLocation()

  const getLinkOption = (option: OptionLink) => {
    return option.href.startsWith('/') || option.href.startsWith('https') ? (
      <a target="_blank" rel="noreferrer" className={toPrefix('list-option')} href={option.href} key={option.href}>
        {option.label}
      </a>
    ) : (
      <Link className={toPrefix('list-option')} to={`/${option.href}`} key={option.href}>
        {option.label}
      </Link>
    )
  }

  return (
    <FooterWrapper data-testid="footer">
      <div className={toPrefix('collapse')}>
        {data?.linksList.map((list) => (
          <FooterCollapse label={list.title} key={list.title}>
            <div className={toPrefix('list-content')}>{list.items.map((option) => getLinkOption(option))}</div>
          </FooterCollapse>
        ))}
      </div>
      <div className={toPrefix('content-logo')}>
        <a target="_blank" rel="noreferrer" className={toPrefix('logo')} href="/">
          <Icon iconId={isBolivia() ? 'logo-bo' : 'logo-cl'} />
        </a>
      </div>
      <ul className={toPrefix('list')}>
        {data?.linksList.map((list) => (
          <li className={toPrefix('list-wrapper')} key={list.title}>
            <div className={toPrefix('list-title')}>{list.title}</div>
            <div className={toPrefix('list-content')}>{list.items.map((option) => getLinkOption(option))}</div>
          </li>
        ))}
      </ul>
      <div className={toPrefix('content-social')}>
        <span>Síguenos</span>
        <div className={toPrefix('social')}>
          {data?.instagramUrl && (
            <a target="_blank" rel="noreferrer" href={data?.instagramUrl}>
              <Icon iconId="instagram-footer" />
            </a>
          )}
          {data?.facebookUrl && (
            <a target="_blank" rel="noreferrer" href={data?.facebookUrl}>
              <Icon iconId="facebook-footer" />
            </a>
          )}
          {data?.youtubeUrl && (
            <a target="_blank" rel="noreferrer" href={data?.youtubeUrl}>
              <Icon iconId="youtube-footer" />
            </a>
          )}
          {data?.twitterUrl && (
            <a target="_blank" rel="noreferrer" href={data?.twitterUrl}>
              <Icon iconId="twitter-footer" />
            </a>
          )}
        </div>
        <div>
          {(data.ccsLogo?.file?.url || data.ccsAwards?.file?.url) && (
            <div className={toPrefix('content-ccs-logo-container')}>
              {data.ccsAwards?.file?.url && (
                <img src={data.ccsAwards?.file?.url} alt="ccs awards" className={toPrefix('content-ccs-logo')} />
              )}
              {data.ccsLogo?.file?.url && (
                <img src={data.ccsLogo?.file?.url} alt="ccs logo" className={toPrefix('content-ccs-logo')} />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={toPrefix('content-info')}>
        <span>{data?.copyrightText}</span>
        <br />
        <span>
          <a target="_blank" rel="noreferrer" href={data?.termsSlug}>
            Términos y Condiciones
          </a>
          &#160;|&#160;
          <a target="_blank" rel="noreferrer" href={data?.privacyPolicies}>
            Políticas de privacidad
          </a>
        </span>
      </div>
    </FooterWrapper>
  )
}
