import axios, { AxiosInstance } from 'axios'
import config from '../../config'
import handleNetworkError from '../../utils/networkErrorHandler'

const client: AxiosInstance = axios.create({
  baseURL: config.EMBONOR_DISTRIBUTION_CENTER_SERVICE_HOST,
})

client.interceptors.response.use(
  (res) => res,
  (error) => {
    if (!error.response) handleNetworkError()
    return Promise.reject(error)
  },
)

export default client
