import {
  Market,
  getStoredMarket,
  setStoredMarket,
  getAuth,
  getAccessToken,
  setAccessToken,
  setAccessTokenExpirationTimestamp,
  useAuth,
  useShoppingCart,
  addShippingAndPaymentMethod,
  getOrderId,
  useFirebase,
  useLocation,
  getCustomerEmail,
  setAnonymousToken,
  getAnonymousToken,
  getOwnerId,
  log,
  setStoredZone,
  setStoredDistributionCenter,
  DistributionCenter,
  Zone,
  getStoredDistributionCenter,
  getStoredZone,
  signOutRedirect,
  setStoredDistributionCenterVersion,
  getStoredDistributionCenterVersion,
  getShippingCosts,
  ShippingCost,
  setStoredShippingCost,
  getStoredShippingCost,
} from '@ecommerce/shared'
import { useEffect } from 'react'
import { guestSignIn } from '@ecommerce/shared/src/services/CommerceLayer/auth'
import { getCustomer } from '@ecommerce/shared/src/services/CommerceLayer/customer'
import { PageProps, useInitCitiesInContext } from '@ecommerce/shared/src/hooks/useInitCitiesInContext'
import { getCustomerGroup } from '@ecommerce/shared/src/services/BFF/customer-group'
import { useUserLoginHandler } from './useUserLoginHandler'
import { sendMessageToSentry, ErrorLevel } from '../utils/sentry'
import { checkTokenExpiration, decodeToken } from '../utils/auth'
import secrets from '../config/secrets'

const setMarkets = (market: Market, setMarket: (market: Market) => void) => {
  setStoredMarket(market)
  setMarket(market)
}

const setZones = (zone: Zone, setZone: (zone: Zone) => void) => {
  setStoredZone(zone)
  setZone(zone)
}

const setDistributionCenters = (
  distributionCenter: DistributionCenter,
  setDistributionCenter: (distributionCenter: DistributionCenter) => void,
) => {
  setStoredDistributionCenter(distributionCenter)
  setDistributionCenter(distributionCenter)
}

const setDistributionCenterVersions = (
  distributionCenterVersion: number,
  setDistributionCenterVersion: (distributionCenterVersion: number) => void,
) => {
  setStoredDistributionCenterVersion(distributionCenterVersion)
  setDistributionCenterVersion(distributionCenterVersion)
}
const initLocation = async (
  market: Market,
  zone: Zone,
  distributionCenter: DistributionCenter,
  onCityChange: () => void,
  setMarket: (market: Market) => void,
  setZone: (zone: Zone) => void,
  setDistributionCenter: (distributionCenter: DistributionCenter) => void,
  setDistributionCenterVersion: (distributionCenterVersion: number) => void,
  distributionCenters: DistributionCenter[],
  markets: Market[],
) => {
  const distributionCenterVersion = secrets.DISTRIBUTION_CENTER_VERSION
  let storedMarket = null as Market | null
  let storedZone = null as Zone | null
  let storedDistributionCenter = null as DistributionCenter | null
  let storedDistributionCenterVersion: number | null | undefined = null
  storedMarket = getStoredMarket()
  storedZone = getStoredZone()
  storedDistributionCenter = getStoredDistributionCenter()
  storedDistributionCenterVersion = getStoredDistributionCenterVersion()
  log.info('storedCity', storedMarket)

  if (
    !storedMarket ||
    !storedMarket.id ||
    !storedDistributionCenter?.commerceLayer?.market?.number ||
    !storedZone ||
    !storedDistributionCenter
  ) {
    if (market && (!market.id || !distributionCenter.commerceLayer?.market?.number))
      throw new Error('market number are not defined')
    setMarkets(market, setMarket)
    if (!zone) throw new Error('zone are not defined')
    setZones(zone, setZone)
    if (!distributionCenter) throw new Error('distributionCenter are not defined')
    setDistributionCenters(distributionCenter, setDistributionCenter)
    setDistributionCenterVersions(parseFloat(distributionCenterVersion.toString()), setDistributionCenterVersion)
    return true
  }
  if (!storedDistributionCenterVersion) {
    setDistributionCenterVersions(parseFloat(distributionCenterVersion.toString()), setDistributionCenterVersion)
    return false
  }
  if (
    storedDistributionCenterVersion &&
    storedDistributionCenterVersion < parseFloat(secrets.DISTRIBUTION_CENTER_VERSION.toString())
  ) {
    const marketToUpdate = markets.find((m) => m.id === storedMarket?.id)
    const zoneToUpdate = marketToUpdate?.zones.find((z) => z.id === storedZone?.id)
    const distributionCenterToUpdate = distributionCenters.find((dc) => dc.id === zoneToUpdate?.distributionCenterId)
    if (zoneToUpdate && marketToUpdate && distributionCenterToUpdate) {
      setZones(zoneToUpdate, setZone)
      setMarkets(marketToUpdate, setMarket)
      setDistributionCenters(distributionCenterToUpdate, setDistributionCenter)
      setDistributionCenterVersions(parseFloat(distributionCenterVersion.toString()), setDistributionCenterVersion)
    }
    return true
  }
  if (storedMarket.id !== market.id) {
    onCityChange()
    return false
  }
  return true
}

const initAnonymousAuth = async () => {
  const storedDistributionCenter = getStoredDistributionCenter()
  const storedToken = getAnonymousToken()

  if (storedToken) {
    const marketIds = decodeToken<{ market: { id: string[] } }>(storedToken)?.market?.id ?? []
    const isExpired = checkTokenExpiration(storedToken)
    const tokenMarketsAreValid = marketIds?.some((id) => id === storedDistributionCenter?.commerceLayer?.market?.id)

    if (!isExpired && tokenMarketsAreValid) return Promise.resolve()
  }

  const {
    data: { access_token },
  } = await guestSignIn(storedDistributionCenter?.commerceLayer?.market?.id)
  setAnonymousToken(access_token)
}

const initAuth = async (forceFetch = false) => {
  const accessToken = getAccessToken()
  const isExpired = checkTokenExpiration(accessToken ?? '')

  if ((!getAuth() && !accessToken) || forceFetch || isExpired) {
    const {
      data: { access_token, expires_in },
    } = await guestSignIn()
    setAccessToken(access_token)
    setAccessTokenExpirationTimestamp(Date.now() + expires_in * 1000)
    const market = getStoredMarket()
    const distributionCenter = getStoredDistributionCenter()
    if (
      market &&
      distributionCenter &&
      distributionCenter?.commerceLayer.paymentMethod.id &&
      distributionCenter?.commerceLayer.shippingMethod.id
    ) {
      setStoredMarket(await addShippingAndPaymentMethod(market, distributionCenter))
    }
  }
}

const initShippingCosts = async (setShippingCost: (shippingCost: ShippingCost) => void) => {
  const shippingCosts = await getShippingCosts()
  const market = getStoredMarket()
  const distributionCenter = getStoredDistributionCenter()

  const currentShippingCost = shippingCosts.find((shippingCost) => shippingCost.id === distributionCenter?.id)

  if (currentShippingCost && market && distributionCenter) {
    setShippingCost(currentShippingCost)
    setStoredShippingCost(currentShippingCost)
    await addShippingAndPaymentMethod(market, distributionCenter)
  }
}

export interface ClCustomerInitPipelineParams {
  pageProps: PageProps
  onCityChange?: () => void
}

export default ({ pageProps, onCityChange = () => null }: ClCustomerInitPipelineParams) => {
  useInitCitiesInContext(pageProps)

  const pathname = pageProps.location?.pathname
  const marketState = useLocation().state.currentCity
  const zoneState = useLocation().state.currentZone
  const distributionCenterState = useLocation().state.currentDistributionCenter

  const shippingCost = getStoredShippingCost()

  const marketsState = pageProps.pageContext?.markets
  const distributionCentersState = pageProps.pageContext?.distributionCenters

  const distributionCenter = pageProps.pageContext?.currentDistributionCenter ?? distributionCenterState
  const market = pageProps.pageContext?.currentMarket ?? pageProps.pageContext?.currentMarket ?? marketState
  const zone = pageProps.pageContext?.currentZone ?? zoneState

  const {
    state: { email },
    onHasToken,
    onHasAnonymousToken,
    setIsLoading,
    setUser,
  } = useAuth()

  const {
    state: { cartId },
  } = useShoppingCart()

  const {
    state: { firebaseInstance },
  } = useFirebase()
  const { userLoginCartHandler } = useUserLoginHandler()

  const {
    state: { country },
    setMarket,
    setZone,
    setDistributionCenter,
    setDistributionCenterVersion,
    setShippingCost,
  } = useLocation()

  useEffect(() => {
    const ownerId = getOwnerId()

    const onAppMount = async () => {
      try {
        // Logged user - check existing token
        if (ownerId) {
          const {
            data: {
              data: {
                id: customerId,
                attributes: {
                  email: customerEmail,
                  metadata: {
                    dni,
                    firstName,
                    lastName,
                    birthdate,
                    city: customerCity,
                    market: customerMarket,
                    registered,
                    favoriteSkus,
                    documentType,
                    billingName,
                    dniComplement,
                    isUpdated,
                  },
                },
                relationships: { customer_group },
              },
            },
          } = await getCustomer({ ownerId })

          let customerGroup
          if (customer_group?.data?.id) {
            customerGroup = await getCustomerGroup({
              customerGroupId: customer_group.data.id,
              country,
            })
          }

          return setUser({
            ownerId: customerId,
            email: customerEmail,
            dni,
            firstName,
            lastName,
            birthdate,
            city: customerCity,
            market: customerMarket,
            registered,
            favoriteSkus,
            isUpdated,
            billingName,
            documentType,
            dniComplement,
            customerGroup: customerGroup?.name,
          })
        }

        // Guest user - get new token
        await initAuth(true)
      } catch (error) {
        signOutRedirect()
      }
    }

    onAppMount()
  }, [])

  useEffect(() => {
    setIsLoading(false)
    async function fetch() {
      try {
        if (!market && !zone && !distributionCenter && !distributionCentersState && !marketState) return
        if (market && zone && distributionCenter && distributionCentersState && marketsState)
          if (
            !initLocation(
              market,
              zone,
              distributionCenter,
              onCityChange,
              setMarket,
              setZone,
              setDistributionCenter,
              setDistributionCenterVersion,
              distributionCentersState,
              marketsState,
            )
          )
            return
        await initAuth()
        if (getAccessToken()) onHasToken()

        await initAnonymousAuth()
        if (getAnonymousToken()) onHasAnonymousToken()
      } catch (e) {
        log.error(e)
      }
    }

    fetch()
  }, [pathname])

  useEffect(() => {
    try {
      if (getAuth() && firebaseInstance && !cartId) {
        userLoginCartHandler({ orderId: getOrderId() || '', cartId, customerEmail: email ?? getCustomerEmail() ?? '' })
      }
    } catch (e) {
      sendMessageToSentry({
        message: `Error: app init`,
        page: 'global - useInit auth',
        level: ErrorLevel.Error,
        metadata: {
          error: e,
        },
      })
    }
  }, [firebaseInstance])

  useEffect(() => {
    const currentDistributionCenter = getStoredDistributionCenter()

    if (!shippingCost || shippingCost?.id !== currentDistributionCenter?.id) {
      initShippingCosts(setShippingCost)
    }
  }, [shippingCost])
}
