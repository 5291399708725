import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'

import {
  sendViewItemToGTM,
  sendDataToGTM,
  sendProductsToGTM,
  sendBannerEventToGTM,
  sendSelectPromotionToGTM,
  sendViewPromotionToGTM,
  sendViewItemListToGTM,
  EventGMT,
  toCurrencyNumber,
  getStoredMarket,
  toCurrency,
  Product,
  OrderAfterPayment,
  CartType,
  EntityInfoType,
  sendProductVisitToConnectif,
  sendLoginToConnectif,
  sendSuccessRegisterToConnectif,
  sendSearchToConnectif,
  sendSuccessPurchaseToConnectif,
  sendSelectItemToGTM,
  sendRemoveProductToGTM,
  sendAddProductToGTM,
  sendCheckoutEventToGTM,
  ProductCart,
  sendCartDataToConnectif,
  sendPageVisitToConnectif,
} from '@ecommerce/shared'
import { ShoppingCartState } from '@ecommerce/shared/src/context/ShoppingCart/context'
import { generateProductUrl } from '../config/siteBuild/utils'
import { DetailViewProduct } from '../components/ProductDetail/types'

export const sendProductVisitEvent = (
  product: Product | DetailViewProduct,
  isBolivia: boolean,
  cart: CartType,
  entityInfo: EntityInfoType,
) => {
  const url = `${document.location.origin}`
  const productData = {
    name: product.title,
    productDetailUrl: `${url}/products/${generateProductUrl(product.title, product.skuCode)}`,
    productId: product.skuCode.toString(),
    unitPrice: product.price,
    availability: !product.unavailable ? 'InStock' : 'outofstock',
    imageUrl: product.image,
    brand: product.brandName,
    unitPriceOriginal: product.rawPrice,
    unitPriceWithoutVAT: product.price,
    discountedAmount: product.rawPrice - product.price,
    discountedPercentage: product.discount,
    categories: [product.categoryName],
  }
  sendViewItemToGTM(product, isBolivia)
  sendProductVisitToConnectif(cart, productData, entityInfo)
}

export const sendRegisterEvent = (cart: CartType, entityInfo: EntityInfoType) => {
  const currentMarket = getStoredMarket()
  sendDataToGTM({ event: 'sign_up', market: currentMarket })
  sendSuccessRegisterToConnectif(
    cart,
    {
      title: 'Registro exitoso',
    },
    entityInfo,
  )
}

export const sendLoginEvent = (cart: CartType, entityInfo: EntityInfoType) => {
  const currentMarket = getStoredMarket()
  sendDataToGTM({ event: 'login', market: currentMarket })
  sendLoginToConnectif(cart, entityInfo)
}

export const sendSearchEvent = (searchTerm: string, cart: CartType, entityInfo: EntityInfoType) => {
  sendDataToGTM({ event: 'pageSearch' })
  sendSearchToConnectif(cart, searchTerm, entityInfo)
}

export const sendPurchaseEvent = (
  orderId: string,
  data: OrderAfterPayment,
  entityInfo: EntityInfoType,
  isBolivia: boolean,
) => {
  const { TAX_MULTIPLIER: taxMultiplier } = secrets
  const { items, total, shippingAmountCents, shippingAmountFloat, clayerTotalCents, couponCode, skus_count } = data
  const url = `${document.location.origin}`
  const purchase = {
    totalQuantity: skus_count,
    totalPrice: clayerTotalCents,
    purchaseId: orderId,
    products: Object.values(items).map((product) => ({
      name: product.title,
      productDetailUrl: `${url}/products/${product.skuCode}`,
      productId: product.skuCode.toString(),
      unitPrice: product.price,
      availability: !product.unavailable ? 'InStock' : 'outofstock',
      imageUrl: product.image,
      brand: product.brandName,
      quantity: product.quantity,
      price: product.price * product.quantity,
      unitPriceOriginal: product.rawPrice,
      unitPriceWithoutVAT: product.price,
      discountedAmount: product.rawPrice - product.price,
      discountedPercentage: product.discount,
      categories: [product.categoryName],
    })),
  }
  sendProductsToGTM({
    event: 'purchase',
    products: Object.values(items).map((product) => ({
      ...product,
      price: toCurrencyNumber(product.price, isBolivia),
    })),
    transaction_id: orderId,
    affiliation: 'Online Store',
    value: toCurrencyNumber(total, isBolivia),
    tax: total * taxMultiplier,
    shipping: isBolivia ? shippingAmountFloat : shippingAmountCents,
    currency: isBolivia ? 'BOB' : 'CLP',
    coupon: couponCode ?? '',
  })
  sendSuccessPurchaseToConnectif(purchase, entityInfo)
}

export const sendBannerEvent = (campaign: string, position: number) => {
  const currentMarket = getStoredMarket()
  sendBannerEventToGTM({ campaign, city: currentMarket?.slug || '', position })
}

export const sendSelectPromotionEvent = (promotion: {
  itemId?: string
  itemName?: string
  creativeName: string
  position: string
}) => {
  sendSelectPromotionToGTM({
    item_id: promotion.itemId ?? '',
    item_name: promotion.itemName ?? '',
    creative_name: promotion.creativeName ?? '',
    position: promotion.position,
  })
}

export const sendViewPromotionEvent = (promotions: { item_id: string; item_name: string; creative_name: string }[]) => {
  sendViewPromotionToGTM(promotions)
}

export const sendViewItemListEvent = (
  args: Partial<EventGMT> & { products: Product[]; currency: string; list?: string },
) => {
  sendViewItemListToGTM(args)
}

export const sendSelectItemEvent = (product: Product, productListName: string, isBolivia: boolean) => {
  sendSelectItemToGTM(product, productListName, isBolivia)
}

export const sendRemoveProductEvent = (products: (Product & { quantity?: number })[], isBolivia: boolean) => {
  sendRemoveProductToGTM(products, isBolivia)
}

export const sendAddProductEvent = (product: Product, isBolivia: boolean) => {
  sendAddProductToGTM(product, isBolivia)
}

export const sendCheckoutEvent = (
  products: ProductCart[],
  state: ShoppingCartState,
  isBolivia: boolean,
  actionField?: EventGMT['actionField'],
) => {
  sendCheckoutEventToGTM(products, state, isBolivia, actionField)
}

export const sendViewCartEvent = (products: ProductCart[]) => {
  sendProductsToGTM({
    event: 'view_cart',
    products,
  })
}

export const sendRefundEvent = (orderId: string, data: OrderAfterPayment, isBolivia: boolean) => {
  sendProductsToGTM({
    event: 'refund',
    products: Object.values(data.items).map((product) => ({
      ...product,
      price: toCurrencyNumber(product.price, isBolivia),
      transaction_id: orderId,
      value: toCurrency(data.total, isBolivia),
      currency: isBolivia ? 'BOB' : 'CLP',
    })),
  })
}

export const sendRegistrationMarketEvent = (marketName: string) => {
  sendDataToGTM({ event: 'registrationMarket', marketName })
}

export const sendTimeOutCheckoutEvent = () => {
  sendDataToGTM({
    event: 'showModalTimeOutCheckout',
  })
}

export const sendMiBarEvent = () => {
  sendDataToGTM({ event: 'Mi Bar', pageTitle: 'Mi Bar' })
}

export const sendCategoryClickEvent = (slug: string, name: string) => {
  if (slug === 'Licores' || slug === 'Vinos y Espumantes' || slug === 'Cerveza') {
    sendDataToGTM({ event: `${name} CategoryClick`, categoryName: name })
  } else {
    sendDataToGTM({ event: 'categoryClick', categoryName: name })
  }
}

export const sendReturnabilityClickEvent = () => {
  sendDataToGTM({ event: 'navReturnabilityClick' })
}

export const sendCartDataEvent = (cart: CartType, entityInfo: EntityInfoType) => {
  sendCartDataToConnectif(cart, entityInfo)
}

export const sendPageViewEvent = (data: {
  cart?: CartType
  entityInfo?: EntityInfoType
  event?: string
  title: string
}) => {
  const { cart, event, title, entityInfo } = data
  if (event) sendDataToGTM({ event, pageTitle: title })
  if (cart) {
    sendPageVisitToConnectif(
      cart,
      {
        title,
      },
      entityInfo || null,
    )
  }
}
