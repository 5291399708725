import styled from 'styled-components'
import React, { ReactNode, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { toCssPrefix } from '@ecommerce/shared'
import { Icon, IconProps } from '../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('Collapse__')

const CollapseContainer = styled.div`
  width: 100%;
  padding: 14px 0;
  .${cssPrefix} {
    &title {
      all: unset;
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`

interface CollapseProps {
  label: string
  children: ReactNode
  isOpen?: boolean
  closeIcon?: IconProps['iconId']
  openIcon?: IconProps['iconId']
}

const Collapse = ({ label, children, isOpen = false, closeIcon, openIcon }: CollapseProps): JSX.Element => {
  const [isShown, setIsShown] = useState(isOpen)

  const handleClick = () => setIsShown((prev) => !prev)

  useEffect(() => setIsShown(isOpen), [isOpen])

  const overflow = isShown ? 'visible' : 'hidden'

  return (
    <CollapseContainer data-testid="collapse">
      <button
        data-testid="collapse-option"
        type="button"
        className={toPrefix('title')}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <span>{label}</span>
        {isShown ? <Icon iconId={closeIcon ?? 'chevron_up'} /> : <Icon iconId={openIcon ?? 'chevron_down'} />}
      </button>
      <motion.div
        transition={{ duration: 0.2 }}
        initial={{ overflow }}
        animate={{
          maxHeight: isShown ? `min-content` : '0px',
          opacity: isShown ? 1 : 0,
          overflow,
        }}
      >
        {children}
      </motion.div>
    </CollapseContainer>
  )
}

export default Collapse
