import styled, { css } from 'styled-components'
import Anchor from '../../atoms/Anchor'
import { breakpoints } from '../../../utils/breakpoints'
import { hexToRGBA, toCssPrefix } from '../../../utils/format'

export const { toPrefix, cssPrefix } = toCssPrefix('AuthForm__')

export const WrapperFormGuest = styled.div<{ isCheckout?: boolean }>`
  display: flex;
  flex-direction: ${({ isCheckout }) => (isCheckout ? 'column-reverse' : 'column')};
`

export const WrapperFormUserData = WrapperFormGuest

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px 24px;
`

export const ALink = styled(Anchor)<{ disabled?: boolean; typeButton?: boolean }>`
  font-size: 15px;
  width: auto;
  font-weight: bold;
  color: ${(props) => props.theme.colors.red};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  :hover {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.red : theme.colors.red80)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  ${({ typeButton, disabled, theme }) =>
    typeButton &&
    css`
      border: 1px solid ${disabled ? theme.colors.red : theme.colors.red80};
      display: block;
      text-align: center;
      padding: 10px 70px;
      border-radius: 4px;
      margin: 15px auto;
    `}
`

export const CheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px 0 0;
  width: 100%;
  min-height: 35px;

  .CheckboxOption__ {
    &label {
      margin: 0;
      font-size: 12px;
    }
    &input {
      margin-right: 1em;
    }
    &anchor {
      text-decoration-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.black};
    }
  }
  span {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 13px;
  }
`

export const WrapperForm = styled.form<{ height?: string; isCheckout?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isCheckout }) => (isCheckout ? '20px 22px' : '20px 33px')};
  width: 100%;
  gap: 24px;
  height: ${({ height }) => height ?? 'auto'};
  background-color: ${(props) => props.theme.colors.white};

  .${cssPrefix} {
    &terms-error {
      color: ${({ theme }) => theme.colors.error};
      align-self: flex-start;

      svg {
        fill: ${({ theme }) => theme.colors.error};
      }
    }

    &title {
      font-size: 17px;
      font-weight: bold;
      color: ${(props) => props.theme.colors.black};
      padding-bottom: 10px;
    }

    &subtitle {
      margin-bottom: 8px;
    }

    &submit {
      margin-top: 13px;
      width: 250px;
      min-height: 40px;
    }

    &select {
      width: 100%;
      margin: 0 auto 15px;
    }

    &setting_account {
      span {
        padding-right: 4px;
      }
    }

    &submit-white {
      margin-top: 28px;
      width: 250px;
      min-height: 40px;
    }

    &email-image {
      margin-bottom: 30px;
      width: 150px;
      height: 150px;
    }

    &main-indication,
    &indication {
      text-align: center;
    }

    &main-indication {
      font-size: 1.2em;
      margin-bottom: 30px;
      color: ${(props) => props.theme.colors.black};
    }

    &indication {
      font-size: 1em;
      margin-bottom: 30px;
      color: ${(props) => props.theme.colors.black};
    }

    &status {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
    }

    &error_message {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.colors.error};
      font-size: 15px;
      font-weight: normal;
      line-height: 1.33;
      margin-top: -20px;

      svg {
        width: 24px;
        height: 24px;
        font-size: 15px;

        fill: ${(props) => props.theme.colors.error};
      }
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: ${({ isCheckout }) => (isCheckout ? '10px 12px' : '39px 85px')};

    .${cssPrefix} {
      &title {
        display: none;
      }
      &error_message {
        font-size: 14px;
      }
    }
  }
`

export const WrapperNoAccount = styled.div<{
  disabled?: boolean
  height?: number
  isCheckout?: boolean
  isSignin?: boolean
}>`
  display: flex;
  flex-direction: ${({ isCheckout, isSignin }) => (isCheckout && !isSignin ? 'column-reverse' : 'column')};
  align-items: center;
  justify-content: space-between;
  padding: 16px 33px;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100px')};
  ${({ isCheckout, theme }) =>
    isCheckout &&
    css`
      background: ${theme.colors.white};
      height: auto;
    `}

  .${cssPrefix} {
    &submit {
      width: 250px;
      min-height: 40px;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.red};
      border: 1px solid ${({ theme }) => theme.colors.red};
      opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
      cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    }

    &setting_account {
      ${({ isCheckout }) =>
        isCheckout &&
        css`
          width: 100%;
          text-align: center;
        `}

      span {
        padding-right: 4px;
      }
    }

    &separator {
      height: 1px;
      width: 70%;
      margin: 40px auto 0 auto;
      background: ${({ theme }) => hexToRGBA(theme.colors.grey60, 0.5)};
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: ${({ isCheckout }) => (isCheckout ? '16px 20px' : '16px 96px')};
  }
`
