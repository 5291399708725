import { motion } from 'framer-motion'
import styled from 'styled-components'
import { breakpoints } from '../../../utils/breakpoints'

export const ButtonSlide = styled.button<{ direction: 'left' | 'right'; hidden: boolean }>`
  display: none;

  @media (${breakpoints.tabletLandscape.min}) {
    top: 45%;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    outline: none;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    right: ${(props) => (props.direction === 'right' ? `2%` : 'inherit')};
    left: ${(props) => (props.direction === 'left' ? `2%` : 'inherit')};
    z-index: 2;
    position: absolute;
    transform: ${(props) => (props.direction === 'left' ? `scale(-1)` : 'none')};
    display: ${(props) => (props.hidden ? 'none' : 'block')};
  }
`

export const ContainerImage = styled(motion.img)`
  width: auto;
  height: 100%;
  border-radius: 10px;
`

export const ContainerSlider = styled.div<{ height: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  @media (${breakpoints.tabletLandscape.min}) {
    height: ${(props) => (props.height ? `${1.5 * props.height}px` : 'auto')};
  }
`

export const Dot = styled.div<{ bg: string }>`
  display: none;

  @media (${breakpoints.tabletLandscape.min}) {
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
    top: -5px;
    width: 11px;
    height: 11px;
    margin: 0 4px;
    position: relative;
    border-radius: 100%;
    display: ${(props) => (props.hidden ? 'none' : 'inline-block')};
    background-color: ${(props) => (props.bg ? props.bg : 'white')};

    &:hover {
      cursor: pointer;
      background-color: red;
    }
  }
`

export const ContainerDots = styled.div`
  display: none;

  @media (${breakpoints.tabletLandscape.min}) {
    position: absolute;
    text-align: center;
    bottom: 0;
    display: block;
    width: 40%;
    user-select: none;
    cursor: pointer;
  }
`
