import styled from 'styled-components'
import { DefaultTheme } from '@ecommerce/shared'

export const Wrapper = styled.div<{
  color: keyof DefaultTheme['colors']
  backgroundColor: keyof DefaultTheme['colors']
}>`
  width: 100%;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 8px;
  background: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  border-radius: 4px;
  border-left: 4px solid ${({ theme, color }) => theme.colors[color]};
  padding: 8px;
  padding-inline-start: 4px;
  p {
    margin: 0;
    width: fit-content;
  }

  .message {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme, color }) => theme.colors[color]};
    font-weight: normal;
    > span {
      font-weight: bold;
    }
  }
`
