import fetch from './fetch'
import {
  formatAddress,
  getFavoriteAddressId,
  getAvailableAddresses,
  sort,
  getAvailableAddressesToUpdate,
} from './utils'
import { CommerceLayerAddress, FormattedAddress } from '../../types'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../../../../../apps/cl-customer-webapp/src/utils/sentry'
import { ByHashZone } from '../../context/Location/context'

type Params = CommerceLayerAddress & { ownerId: string }
type Context = 'checkout' | 'addresses'
export type FormattedAddressUpdate = {
  distributionCenterId: number
} & FormattedAddress

const headers = {
  'Content-Type': 'application/vnd.api+json',
}

const fetchAddresses = async (
  currentMarket: number,
  context: Context = 'checkout',
  byHashZones: ByHashZone,
): Promise<
  [
    FormattedAddress[] | undefined,
    boolean,
    string | undefined,
    FormattedAddress[] | undefined,
    FormattedAddressUpdate[] | undefined,
  ]
> => {
  try {
    const { data } = await fetch(`/api/customer_addresses?page[size]=25`, {
      method: 'GET',
      params: {
        include: 'address',
      },
    })

    // Structure data
    const addresses = data.included?.map((address: CommerceLayerAddress) => formatAddress(address, data.data)) ?? []

    // Check if addresses are in the same city

    // Check if addresses are available for update
    const addressesToUpdate = getAvailableAddressesToUpdate(addresses, byHashZones)

    const [validAddresses, hasAddress] = getAvailableAddresses(currentMarket, addresses)

    if (validAddresses) {
      return [validAddresses, hasAddress, undefined, [], addressesToUpdate]
    }

    if (addressesToUpdate.length > 0) {
      return [undefined, false, undefined, [], addressesToUpdate]
    }

    if (context === 'checkout') {
      // Get favorite address
      const favoriteId = getFavoriteAddressId(validAddresses)

      // Set favorite address first
      const sorted = sort(validAddresses)

      return [sorted, hasAddress, favoriteId, addresses, addressesToUpdate]
    }

    return [sort(addresses), hasAddress, undefined, [], addressesToUpdate]
  } catch (e) {
    throw new Error(e)
  }
}

/**
 * Create new address
 * @param addressData
 */
const createUserAddress = async (addressData: Params): Promise<FormattedAddress> => {
  try {
    const customerID = addressData.ownerId
    const {
      line_1,
      line_2,
      city,
      phone,
      lat,
      lng,
      notes,
      first_name,
      last_name,
      country_code,
      state_code,
      metadata,
      zip_code,
    } = addressData.attributes
    const { marketId, alias, marketName, favorite, zoneId, zoneName } = metadata

    // Create address
    const address = {
      data: {
        type: 'addresses',
        attributes: {
          first_name,
          last_name,
          country_code,
          state_code,
          zip_code,
          line_1,
          line_2,
          phone,
          city,
          notes,
          lat,
          lng,
          metadata: {
            marketId,
            alias,
            marketName,
            favorite,
            zoneId,
            zoneName,
          },
        },
      },
    }

    const { data } = await fetch('/api/addresses ', {
      data: address,
      method: 'POST',
      headers,
    })

    // Create customer address
    const customer_address = {
      data: {
        type: 'customer_addresses',
        relationships: {
          customer: {
            data: {
              type: 'customers',
              id: customerID,
            },
          },
          address: {
            data: {
              type: 'addresses',
              id: data.data.id,
            },
          },
        },
      },
    }

    // Send request
    await fetch(`/api/customer_addresses`, {
      method: 'POST',
      headers,
      data: customer_address,
    })

    return formatAddress(data.data)
  } catch (error) {
    sendMessageToSentry({
      message: `Error on Create Address: ${error?.response?.data?.errors[0]?.detail}`,
      page: window.location.pathname,
      level: ErrorLevel.Error,
      source: ErrorSource.CLayer,
      metadata: { error: error.response, data: addressData, ...error },
    })

    throw error
  }
}

/**
 * Update existing address
 * @param params
 */
const updateAddress = async (params: Params): Promise<FormattedAddress> => {
  try {
    const {
      line_1,
      line_2,
      city,
      phone,
      lat,
      lng,
      notes,
      first_name,
      last_name,
      metadata,
      country_code,
      zip_code,
    } = params.attributes
    const { marketId, alias, favorite, marketName, zoneId, zoneName, distributionCenterId } = metadata

    // Send request
    const { data } = await fetch(`/api/addresses/${params.id}`, {
      method: 'PATCH',
      data: {
        data: {
          type: 'addresses',
          id: params.id,
          attributes: {
            first_name,
            last_name,
            country_code,
            state_code: marketId,
            zip_code,
            line_1,
            line_2,
            phone,
            city,
            notes,
            lat,
            lng,
            metadata: {
              marketId,
              alias,
              favorite,
              marketName,
              zoneId,
              zoneName,
              distributionCenterId,
            },
          },
        },
      },
      headers,
    })

    return formatAddress(data.data)
  } catch (error) {
    throw new Error(error)
  }
}

const updateFavoriteAddress = (addresses: FormattedAddress[]) =>
  new Promise((resolve, reject) => {
    const promises = addresses.map(({ id, isFavorite, marketId, shipping_name, marketName, zoneId, zoneName }) =>
      fetch(`/api/addresses/${id}`, {
        method: 'PATCH',
        headers,
        data: {
          data: {
            type: 'addresses',
            id,
            attributes: {
              metadata: {
                marketId,
                marketName,
                favorite: isFavorite,
                alias: shipping_name,
                zoneId,
                zoneName,
              },
            },
          },
        },
      }),
    )

    Promise.all(promises).then(resolve).catch(reject)
  })

export const deleteAddress = async ({ id, parent }: { id: string; parent: string }): Promise<boolean> => {
  try {
    await fetch(`/api/customer_addresses/${parent}`, {
      method: 'DELETE',
      headers,
    })

    fetch(`/api/addresses/${id}`, {
      method: 'DELETE',
      headers,
    })

    return true
  } catch (e) {
    throw new Error(e)
  }
}

export { fetchAddresses, createUserAddress, updateAddress, updateFavoriteAddress }
