import { ErrorLevel, ErrorSource, sendMessageToSentry } from '@ecommerce/chile-customer-webapp/src/utils/sentry'
import { flatError } from '../../../utils/format'
import Errors from '../../../services/CommerceLayer/handlingErrors'
import { log } from '../../../utils/log'

const handleError = (err: any, onError: () => void, email: string) => {
  if (err.message === 'blocked') {
    sendMessageToSentry({
      message: `On signIn: blocked user`,
      page: window.location.pathname,
      source: ErrorSource.CLayer,
      level: ErrorLevel.Error,
      metadata: { payload: { email }, error: err },
    })
    return null
  }
  const formattedError = flatError<keyof typeof Errors>(err.response)

  log.error({ formattedError })
  onError()

  if (formattedError.code !== 'INVALID_GRANT') {
    sendMessageToSentry({
      message: `On signIn: ${formattedError.code}`,
      page: window.location.pathname,
      source: ErrorSource.CLayer,
      level: ErrorLevel.Error,
      metadata: { formattedError, payload: { email }, error: err },
    })
  }

  return {
    code: formattedError.code,
    status: 400,
    metadata: err,
    description: Errors[formattedError.code],
  }
}

export default handleError
