import { ProductCart, ByHash, Promotions, PromotionType } from '@ecommerce/shared'

export const removeProductsFromState = (products: ProductCart[], byHash: ByHash) => {
  let newStateProducts = byHash

  products.forEach((p) => {
    const { [p.skuCode]: productWhitoutStock, ...restProducts } = newStateProducts

    if (productWhitoutStock) newStateProducts = restProducts
  })

  return newStateProducts
}

export const getByhashWithPromotions = (byHash: ByHash, promotions: Promotions = {}) => {
  const skus = Object.keys(byHash)
  return skus.map((sku) => {
    const promotionDetail = promotions[sku]
    if (promotionDetail && promotionDetail.promotion?.type !== PromotionType.A_DISCOUNTS_B)
      return {
        ...byHash[sku],
        promotionDetail: promotions[sku],
      }
    return {
      ...byHash[sku],
    }
  })
}
