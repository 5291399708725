import { persistCredentials, signIn } from '../../services/CommerceLayer/auth'
import customerApi from '../../services/CommerceLayer/customer'
import handlingErrors from '../../services/CommerceLayer/handlingErrors'
import { AuthDispatch } from './context'
import { SetUserParams } from './reducer'
import { authKeys, flatError, ResponseSignIn, saveState } from '../../..'
import { getCustomerGroup } from '../../services/BFF/customer-group'
import config from '../../config'

const { COUNTRY } = config

export function actionReset(dispatch: AuthDispatch) {
  return () => dispatch({ type: 'RESET' })
}

export interface SignInReturn {
  data: ResponseSignIn & {
    email: string
    dni: string
    firstName: string
    lastName: string
    birthdate: string
    city: number
    market: number
    registered: boolean
    documentType: string
    billingName: string
    dniComplement: string
    isUpdated: boolean
  }
}

export function actionSignIn(dispatch: AuthDispatch) {
  return async (params: { username: string; password: string }, handleLoading = true): Promise<SignInReturn> => {
    if (handleLoading) dispatch({ type: 'BEGINNING' })

    const response = await signIn({ username: params.username, password: params.password })
    dispatch({ type: 'SIGN_IN' })
    persistCredentials(response.data)
    const userResponse = await customerApi.getCustomer({ ownerId: response.data.owner_id })

    let customerGroup
    if (userResponse?.data?.data?.relationships?.customer_group?.data?.id) {
      customerGroup = await getCustomerGroup({
        customerGroupId: userResponse.data.data.relationships.customer_group.data.id,
        country: COUNTRY,
      })
    }

    const {
      data: { owner_id: ownerId },
    } = response

    const {
      data: {
        data: {
          id: customerId,
          attributes: {
            email,
            metadata: {
              dni,
              firstName,
              lastName,
              birthdate,
              city,
              market,
              registered,
              favoriteSkus,
              documentType,
              billingName,
              dniComplement,
              isUpdated,
            },
          },
        },
      },
    } = userResponse

    const userData = {
      ownerId: customerId,
      email,
      dni,
      firstName,
      lastName,
      birthdate,
      city,
      market,
      registered,
      favoriteSkus,
      documentType,
      billingName,
      dniComplement,
      isUpdated,
      customerGroup: customerGroup?.name,
    }

    saveState(authKeys.auth, userData)
    dispatch({
      type: 'SET_USER',
      ...userData,
    })

    if (handleLoading) dispatch({ type: 'FINISHED' })
    return {
      data: {
        ...response.data,
        ...{
          email,
          dni,
          firstName,
          lastName,
          birthdate,
          city,
          market,
          registered,
          documentType,
          billingName,
          dniComplement,
          isUpdated,
        },
      },
    }
  }
}

export function actionUpdateCustomer(dispatch: AuthDispatch) {
  return async (params: {
    ownerId: string
    firstName: string
    lastName: string
    dni: string
    city: number
    market?: number
    birthdate: string
    registered?: boolean
    newPassword?: string
    documentType?: string
    billingName?: string
    dniComplement?: string
    isUpdated?: boolean
  }) => {
    const customer = await customerApi.getCustomer({ ownerId: params.ownerId })
    const update: {
      password?: string
      metadata: {
        firstName: string
        lastName: string
        dni: string
        city: number
        market?: number
        birthdate: string
        registered?: boolean
        documentType?: string
        billingName?: string
        dniComplement?: string
        isUpdated?: boolean
      }
    } = {
      metadata: {
        ...customer.data?.data?.attributes?.metadata,
        firstName: params.firstName,
        lastName: params.lastName,
        dni: params.dni,
        city: params.city,
        market: params.market,
        birthdate: params.birthdate,
        registered: params.registered,
        documentType: params.documentType,
        billingName: params.billingName,
        dniComplement: params.dniComplement,
        isUpdated: params.isUpdated,
      },
    }
    if (params.newPassword) {
      update.password = params.newPassword
    }
    try {
      const response = await customerApi.updateCustomer({ ownerId: params.ownerId, customer: update })
      const {
        data: {
          data: {
            id: ownerId,
            attributes: {
              email,
              metadata: {
                firstName,
                lastName,
                dni,
                city,
                market,
                birthdate,
                registered,
                documentType,
                billingName,
                dniComplement,
                isUpdated,
              },
            },
          },
        },
      } = response
      dispatch({
        type: 'SET_USER',
        ownerId,
        email,
        firstName,
        lastName,
        dni,
        city,
        market,
        birthdate,
        registered,
        documentType,
        billingName,
        dniComplement,
        isUpdated,
      })
      return response
    } catch (err) {
      const error = flatError<keyof typeof handlingErrors>(err.response)
      const errorFormatted = { ...error, description: handlingErrors[error.code] }

      dispatch({ type: 'FINISHED' })
      throw errorFormatted
    }
  }
}

export function actionHasToken(dispatch: AuthDispatch) {
  return () => dispatch({ type: 'HAS_TOKEN' })
}

export function actionSignOut(dispatch: AuthDispatch) {
  return () => dispatch({ type: 'RESET' })
}

export function actionSetIsLoading(dispatch: AuthDispatch) {
  return (isLoading: boolean) => dispatch({ type: 'SET_IS_LOADING', isLoading })
}

export function actionSetGuest(dispatch: AuthDispatch) {
  return ({ firstName, lastName }: { firstName: string; lastName: string }) =>
    dispatch({ type: 'SET_GUEST', firstName, lastName })
}

export function actionSetUser(dispatch: AuthDispatch) {
  return (payload: SetUserParams) => dispatch({ type: 'SET_USER', ...payload })
}

export function actionSetFavoriteSkus(dispatch: AuthDispatch) {
  return (skus: number[]) => dispatch({ type: 'SET_FAVORITE_SKUS', skus })
}

export function actionSetAvailabilitiesSkus(dispatch: AuthDispatch) {
  return (skus: number[]) => dispatch({ type: 'SET_AVAILABILITY_SKUS', skus })
}
