import React, { useState, useEffect, ReactNode } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import CardTitle from './CardTitle'

type Props = {
  icon?: ReactNode
  label: string
  children: React.ReactElement | React.ReactElement[]
  isOpen?: boolean
  className?: string
  onClick?: () => void
  customMaxHeight?: number
  hideIcons?: boolean
  showHeader?: boolean
  showEditButton?: boolean
  iconsDisabled?: boolean
}

const CollapsibleContainer = styled.div`
  width: 100%;
`

export const EditIconWrapper = styled.span<{ disabled?: boolean }>`
  font-size: 15px !important;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.platinum80 : theme.colors.red)} !important;
  font-weight: 400 !important;
  position: relative;
  left: -16px;
  transition: all ease-in-out 0.2s;
  cursor: default;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
      &:hover {
        font-weight: bold !important;
      }
    `}
`

/* Edit icon */
const EditIcon = (disabled: boolean) => <EditIconWrapper disabled={disabled}>editar</EditIconWrapper>

/* Minus icon */
const IconSubstract = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 32 32">
    <path d="M29.333 14.667v2.667h-26.667v-2.667z" />
  </svg>
)

/* Plus icon */
const IconAdd = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
    <path d="M16.744 14.669h9.336v2.075h-9.336v9.336h-2.075v-9.336h-9.336v-2.075h9.336v-9.336h2.075z" />
  </svg>
)

const Collapse = ({
  icon,
  label,
  children,
  isOpen = false,
  className,
  onClick,
  customMaxHeight,
  showHeader = true,
  hideIcons,
  showEditButton = false,
  iconsDisabled = false,
}: Props) => {
  const [isShown, setIsShown] = useState(isOpen)
  const [maxHeight, setMaxHeight] = useState(customMaxHeight ?? 0)

  const ref = React.useRef<HTMLElement>(null)

  const handleMaxHeight = () => {
    if (!customMaxHeight) setMaxHeight((ref.current?.clientHeight ?? 0) + 100)
  }
  const handleClick = () => setIsShown((prev) => !prev)

  useEffect(() => setIsShown(isOpen), [isOpen])
  useEffect(handleMaxHeight, [children, customMaxHeight])

  const overflow = isShown ? 'visible' : 'hidden'

  const getIcon = () => {
    if (isShown) {
      return IconSubstract
    }
    return showEditButton ? EditIcon(iconsDisabled) : IconAdd
  }

  return (
    <CollapsibleContainer className={className}>
      {showHeader && (
        <CardTitle
          icon={icon}
          title={label}
          buttonIcon={hideIcons ? null : getIcon()}
          onButtonClick={() => {
            if (iconsDisabled) {
              return
            }

            if (onClick) {
              onClick()
            } else {
              handleClick()
            }
          }}
          isButtonDisabled={iconsDisabled}
        />
      )}
      <motion.div
        transition={{ duration: 0.2 }}
        initial={{ overflow }}
        animate={{
          maxHeight: isShown ? `${maxHeight}px` : '0px',
          opacity: isShown ? 1 : 0,
          overflow,
        }}
        className="Collapsible__display-content"
      >
        {React.Children.map(children, (child) => React.cloneElement(child, { ref }))}
      </motion.div>
    </CollapsibleContainer>
  )
}

export default Collapse
