import {
  useShoppingCart,
  useLocation,
  useAuth,
  cleanStoreOnRedirect,
  Market,
  setStoredMarket,
  saveSelectedSessionMarket,
  setStoredZone,
  DistributionCenter,
  setStoredDistributionCenter,
  Zone,
  setStoredDistributionCenterVersion,
} from '@ecommerce/shared'
import secrets from '../config/secrets'

type ResetAppState = (
  redirectSlug: string,
  options?: {
    keepCart?: boolean
    keepAuth?: boolean
    newMarket?: Market
    newZone?: Zone
    newDistributionCenter?: DistributionCenter
    onFinish?: () => void
  },
) => Promise<void>

const useSession = () => {
  const distributionCenterVersion = secrets.DISTRIBUTION_CENTER_VERSION
  const isInCart = typeof window !== 'undefined' ? window.location.pathname.includes('/cart') : null

  const { empty: emptyCart, createShoppingCartAndSetId } = useShoppingCart()
  const { setMarket, setZone, setDistributionCenter, setDistributionCenterVersion } = useLocation()
  const { onReset } = useAuth()

  const resetAppState: ResetAppState = async (redirectSlug, options) => {
    const keepCart = options?.keepCart ?? false
    const keepAuth = options?.keepAuth ?? false
    const onFinish = options?.onFinish
    const newZone = options?.newZone
    const newMarket = options?.newMarket
    const newDistributionCenter = options?.newDistributionCenter

    cleanStoreOnRedirect(!keepCart, !!newMarket, !keepAuth, !!newZone, !!newDistributionCenter)

    if (!keepCart) {
      emptyCart()
      await createShoppingCartAndSetId()
    }
    if (newMarket && newZone && newDistributionCenter) {
      setDistributionCenter(newDistributionCenter)
      setMarket(newMarket)
      setZone(newZone)
      setStoredDistributionCenter(newDistributionCenter)
      setStoredMarket(newMarket)
      setStoredZone(newZone)
      setDistributionCenterVersion(parseFloat(distributionCenterVersion.toString()))
      setStoredDistributionCenterVersion(parseFloat(distributionCenterVersion.toString()))
      saveSelectedSessionMarket()
    }
    if (!keepAuth) onReset()

    if (onFinish) onFinish()

    if (!isInCart) {
      setTimeout(() => {
        window.location.replace(redirectSlug)
      }, 1000)
    }
  }

  return { resetAppState }
}

export default useSession
