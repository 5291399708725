import fetcher from './fetch'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

interface ResetPasswordParams {
  dni?: string
  email?: string
  originUrl: string
}

interface ResetPasswordParamsConfirm {
  password: string
  passwordResetId: string
  passwordResetToken: string
}

export function resetPassword(params: ResetPasswordParams) {
  const data = { ...params }
  return fetcher('/reset', { data: { ...data, email: data.email?.toLowerCase() }, headers, method: 'POST' })
}

export function resetPasswordConfirm(params: ResetPasswordParamsConfirm) {
  const data = { ...params }
  return fetcher('/confirmReset', { data, headers, method: 'POST' })
}
