import * as React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { HTMLMotionProps, motion } from 'framer-motion'
import Loading from '../Loading'
import { breakpoints } from '../../../utils/breakpoints'
import { hexToRGBA } from '../../../utils/format'

interface StyleProps {
  isDisabled?: boolean
  btnType?: 'primary' | 'secondary' | 'tertiary'
  btnColor?: 'red' | 'white' | 'black' | 'blackInverted' | 'blue' | 'gray'
  isSkeleton?: boolean
  customSecondary?: boolean
}

const generateActionStyles = (
  textColorHover: string,
  textColorAction: string,
  backgroundColorHover: string,
  backgroundColorAction: string,
) => {
  return css`
    @media screen and (${breakpoints.tabletLandscape.min}) {
      &:hover {
        background: ${backgroundColorHover};
        border: none;
        color: ${textColorHover};
      }

      &:active {
        background: ${backgroundColorAction};
        border: none;
        color: ${textColorAction};
      }
    }
  `
}

const generateStyles = (
  primaryColor: string,
  secondaryColor: string,
  hoverActionStyles: FlattenSimpleInterpolation,
) => {
  return {
    primary: css`
      background: ${primaryColor};
      color: ${secondaryColor};
      border: none;
      ${hoverActionStyles}
    `,
    secondary: css`
      background: transparent;
      color: ${primaryColor};
      border: 1px solid ${primaryColor};
      ${hoverActionStyles}
    `,
    tertiary: css`
      border: none;
      background: transparent;
      color: ${primaryColor};
      ${hoverActionStyles}
    `,
  }
}

const ButtonStyled = styled(motion.button)<StyleProps>`
  ${({ theme, isDisabled, btnType, btnColor, isSkeleton, customSecondary }) => {
    if (isSkeleton) {
      return css`
        color: ${theme.colors.grey};
        background: ${theme.colors.grey};
        border-radius: ${theme.borderRadius};
        border: none;
      `
    }

    const primaryWhiteHoverStyles = generateActionStyles(
      theme.colors.white,
      theme.colors.white,
      theme.colors.red80,
      theme.colors.secondary,
    )

    const disabled1 = css`
      border: none;
      background: ${theme.colors.platinum60};
      color: ${theme.colors.white};
    `

    const btnStyles = {
      red: {
        ...generateStyles(theme.colors.red, theme.colors.white, primaryWhiteHoverStyles),
        disabled: disabled1,
      },
      white: {
        ...generateStyles(theme.colors.white, theme.colors.red, primaryWhiteHoverStyles),
        disabled: css`
          border: none;
          background: ${theme.colors.platinum40};
          color: ${theme.colors.platinum80};
        `,
      },
      black: {
        ...generateStyles(
          theme.colors.black,
          theme.colors.white,
          generateActionStyles(theme.colors.white, theme.colors.white, theme.colors.black80, theme.colors.black),
        ),
        disabled: disabled1,
      },
      blackInverted: {
        ...generateStyles(
          theme.colors.white,
          theme.colors.black,
          generateActionStyles(
            theme.colors.black,
            theme.colors.black80,
            hexToRGBA(theme.colors.grey40, 0.25),
            hexToRGBA(theme.colors.grey60, 0.5),
          ),
        ),
        disabled: disabled1,
      },
      blue: {
        ...generateStyles(
          theme.colors.blue80,
          theme.colors.white,
          css`
            &:hover,
            &:active {
              opacity: 0.75;
            }
          `,
        ),
        disabled: disabled1,
      },
      gray: {
        ...generateStyles(
          theme.colors.grey,
          theme.colors.black,
          generateActionStyles(theme.colors.black, theme.colors.black80, theme.colors.grey60, theme.colors.grey60),
        ),
        disabled: disabled1,
      },
    }

    const type = btnType || 'primary'
    const color = btnColor || 'red'

    return css`
      width: auto;
      height: 40px;
      font-size: 17px;
      font-weight: 700;
      cursor: pointer;
      outline: none;
      border-radius: ${theme.borderRadius};
      color: ${theme.colors.white};

      ${isDisabled ? btnStyles[color].disabled : btnStyles[color][type]}
      ${theme.colors.buttonBackgroundPrimaryColor &&
      !isDisabled &&
      customSecondary &&
      type === 'secondary' &&
      css`
        color: ${theme.colors.buttonBackgroundPrimaryColor};
        border: ${theme.colors.buttonBackgroundPrimaryColor} 1px solid;
      `}
    `
  }}
`

interface Props extends HTMLMotionProps<'button'>, StyleProps {
  isLoading?: boolean
}

const Button = (props: Props) => {
  const { isDisabled, children, isLoading, ...restProps } = props

  return (
    <ButtonStyled type="button" {...restProps} isDisabled={isDisabled} color={props.color || 'primary'}>
      {isLoading ? <Loading size="small" isGhost={!props.btnType || props.btnType === 'primary'} /> : children}
    </ButtonStyled>
  )
}

export default Button
