import { AxiosResponse } from 'axios'
import { StoredCustomer } from '../../types'
import secrets from '../../config'
import fetch from './fetch'

const pathCountry = secrets.COUNTRY.toLowerCase()
const favoritesPath = `/${pathCountry}/customers/favorite-skus`
const availabilityPath = `/${pathCountry}/customers/availability-skus`

export const getUserFavoriteSkus = async (email: string) => {
  const { data }: AxiosResponse<StoredCustomer> = await fetch(`/${pathCountry}/customers/skus`, {
    params: { email },
  })

  return data.favoriteSkus
}

export const saveUserFavoriteSkus = async (payload: StoredCustomer) => {
  return fetch(favoritesPath, { data: payload, method: 'POST' })
}

export const deleteUserFavoriteSku = (email: string, sku: number | number[]) => {
  return fetch(favoritesPath, { method: 'DELETE', data: { email, sku } })
}

export const getUserAvailabilitySkus = async (email: string) => {
  const { data }: AxiosResponse<StoredCustomer> = await fetch(`/${pathCountry}/customers/skus`, {
    params: { email },
  })

  return data.skusAvailabilityList
}

export const saveUserAvailabilitySkus = async (payload: StoredCustomer) => {
  return fetch(availabilityPath, { data: payload, method: 'POST' })
}

export const deleteUserAvailabilitySku = (email: string, sku: number | number[]) => {
  return fetch(availabilityPath, { method: 'DELETE', data: { email, sku } })
}
