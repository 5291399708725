import React, { ReactNode, useMemo, useEffect } from 'react'
import {
  ActivityDialogProvider,
  AuthProvider,
  GlobalFonts,
  GlobalStyle,
  LocationProvider,
  ShoppingCartProvider,
  Theme,
  FirebaseProvider,
  NotificationContainer,
  CartProvider,
  log,
  getStoredShippingCost,
} from '@ecommerce/shared'
import { Link } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Helmet } from 'react-helmet'
import { UIProvider } from '@ecommerce/shared/src/context/UI/ui.context'
import { CartStatusProvider } from '@ecommerce/shared/src/context/ShoppingCart/cartStatusContext'
import { SuggestedProductsProvider } from '@ecommerce/shared/src/context/Cart/suggested.context'
import Provider from 'react-redux/es/components/Provider'
import { useGlobalThemeQuery, useMaxCartQuantitiesQuery, usePromotionCouponsWhitelist } from './graphql/global'
import 'react-toastify/dist/ReactToastify.css'

import config from './config/secrets'
import ErrorBoundary from './components/ErrorBoundary'
import store from './store'

declare const window: Window & { initializeConnectif?: any; connectifConfiguration?: any }

export default (props: { children: ReactNode }) => {
  const { themeExtension } = useGlobalThemeQuery()

  const promotionCouponsWhitelist = usePromotionCouponsWhitelist()

  const { maxCartProductQuantity, maxCartBundleQuantity } = useMaxCartQuantitiesQuery()

  const currentShippingCost = getStoredShippingCost()
  const ErrorWrapper = useMemo(() => {
    if (config.DISABLE_ERROR_BOUNDARY) return ({ children }: { children: React.ReactNode }) => <>{children}</>
    return ErrorBoundary
  }, [config.DISABLE_ERROR_BOUNDARY])

  const connectifScript = `
    !function(e){
      function t(){
        if(!e.querySelector("#__cn_client_script_${config.CONNECTIF_CLIENT_ID}")){
          var t=e.createElement("script");t.setAttribute("src","https://cdn.connectif.cloud/am1/client-script/${config.CONNECTIF_CLIENT_ID}"),e.body.appendChild(t)
        }
      } "complete"===e.readyState||"interactive"===e.readyState?t():e.addEventListener("DOMContentLoaded",t)
    }(document);`

  useEffect(() => {
    document.addEventListener('connectif.loaded', function onConnectifLoaded() {
      log.trace('Connectif loaded')
      if (window.initializeConnectif && window.connectifConfiguration) {
        window.initializeConnectif(window.connectifConfiguration)
      }
    })
    document.addEventListener('connectif.managed.initialized', function onConnectifInitialized() {
      log.trace('Connectif is initialized')
    })
  }, [])

  return (
    <>
      <Helmet>
        <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <meta name="google-site-verification" content="eerSjq3MH861sNAkwkl1XwvQQ0YbupmDLvT7PTdZMzQ" />

        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon.ico" sizes="16x16 32x32 48x48" type="image/x-icon" />

        <link rel="icon" href="/favicon.svg" sizes="any" type="image/svg+xml" />
        <link rel="mask-icon" href="/favicon.svg" color="#921919" />
        <link rel="apple-touch-icon" sizes="48x48 60x60 76x76 120x120 152x152 180x180" href="/favicon.png" />

        <meta name="msapplication-TileColor" content="#921919" />
        <meta name="msapplication-TileImage" content="/favicon.png" />

        <link rel="icon" href="/favicon.png" sizes="96x96 128x128 192x192 512x512" type="image/png" />

        <body data-app-version={config.APP_VERSION} />

        {config.BCI_PLUS_URL && <script async defer src={config.BCI_PLUS_URL} />}
      </Helmet>
      <script
        async
        id={`__cn_generic_script__${config.CONNECTIF_CLIENT_ID}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: connectifScript }}
      />
      <Theme themeExtension={themeExtension}>
        <GlobalFonts />
        <GlobalStyle />

        <Link to="/network-error" />
        <Provider store={store}>
          <FirebaseProvider>
            <CartStatusProvider>
              <UIProvider>
                <NotificationContainer />
                <GoogleReCaptchaProvider reCaptchaKey={config.GOOGLE_RECAPTCHAV3_SITE_KEY}>
                  <LocationProvider country={config.COUNTRY}>
                    <AuthProvider>
                      <ActivityDialogProvider>
                        <ShoppingCartProvider
                          freeOver={Number(currentShippingCost?.freeOver)}
                          shippingCost={Number(currentShippingCost?.shippingCost)}
                          maxCartLineProductQuantity={maxCartProductQuantity}
                          maxCartLineBundleQuantity={maxCartBundleQuantity}
                          promotionCouponsWhitelist={promotionCouponsWhitelist}
                        >
                          <CartProvider>
                            <SuggestedProductsProvider>
                              <ErrorWrapper>{props.children}</ErrorWrapper>
                            </SuggestedProductsProvider>
                          </CartProvider>
                        </ShoppingCartProvider>
                      </ActivityDialogProvider>
                    </AuthProvider>
                  </LocationProvider>
                </GoogleReCaptchaProvider>
              </UIProvider>
            </CartStatusProvider>
          </FirebaseProvider>
        </Provider>
      </Theme>
    </>
  )
}
