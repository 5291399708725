import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import Loading from '../../atoms/Loading'
import Collapse, { EditIconWrapper } from '../../molecules/Collapse'
import { ShoppingCartState } from '../../../context/ShoppingCart/context'
import { hexToRGBA, toCssPrefix } from '../../../utils/format'
import { Market } from '../../../services/Location/cities'

type Props = {
  productsQty?: string
  subTotal: string
  shipping: string
  orderTotal: string
  discount?: string
  couponDiscount?: string
  className?: string
  showHeader?: boolean
  totalText?: string
  isRawDiscount: boolean
  isAditionalDiscounts?: boolean
  scratchDiscount?: boolean
  removePadding?: boolean
  cartState: ShoppingCartState
  currentCity: Market
}

const CheckListIcon = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons/checklist" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Box-24pt" x="0" y="0" width="24" height="24" />
      <g id="Group-2" transform="translate(2.000000, 2.000000)">
        <g id="list" transform="translate(7.700000, 13.000000)" />
        <path
          d="M16.3153846,4.75789474 C16.6552522,4.75789474 16.9307692,5.11606694 16.9307692,5.55789474 C16.9307692,5.96816341 16.693206,6.30630047 16.3871515,6.35251255 L16.3153846,6.35789474 L8.73643725,6.35789474 C8.39656971,6.35789474 8.12105263,5.99972254 8.12105263,5.55789474 C8.12105263,5.14762607 8.35861583,4.80948901 8.6646704,4.76327692 L8.73643725,4.75789474 L16.3153846,4.75789474 Z"
          id="Path"
          fill="#030F1C"
        />
        <path
          d="M16.3153846,9.05789474 C16.6552522,9.05789474 16.9307692,9.41606694 16.9307692,9.85789474 C16.9307692,10.2681634 16.693206,10.6063005 16.3871515,10.6525126 L16.3153846,10.6578947 L8.73643725,10.6578947 C8.39656971,10.6578947 8.12105263,10.2997225 8.12105263,9.85789474 C8.12105263,9.44762607 8.35861583,9.10948901 8.6646704,9.06327692 L8.73643725,9.05789474 L16.3153846,9.05789474 Z"
          id="Path"
          fill="#030F1C"
        />
        <path
          d="M16.3153846,13.3578947 C16.6552522,13.3578947 16.9307692,13.7160669 16.9307692,14.1578947 C16.9307692,14.5681634 16.693206,14.9063005 16.3871515,14.9525126 L16.3153846,14.9578947 L8.73643725,14.9578947 C8.39656971,14.9578947 8.12105263,14.5997225 8.12105263,14.1578947 C8.12105263,13.7476261 8.35861583,13.409489 8.6646704,13.3632769 L8.73643725,13.3578947 L16.3153846,13.3578947 Z"
          id="Path"
          fill="#030F1C"
        />
        <path
          d="M4.44482419,5.72087863 L3.86293995,5.1764582 L3.82965957,5.1481462 C3.61885834,4.99415465 3.35417336,5.00869501 3.17466589,5.17647979 C2.94177804,5.39415897 2.94177804,5.78100037 3.17466589,5.99867954 L4.1011203,6.86463209 L4.13442379,6.89296568 C4.34522501,7.04695723 4.60991,7.03241687 4.78941747,6.86463209 L6.82761718,4.95953649 L6.85844103,4.92788936 C7.06241597,4.6837987 7.04057083,4.33638351 6.82761718,4.13733674 C6.63170771,3.95422109 6.33522948,3.95422109 6.13932001,4.13733674 L4.44482419,5.72087863 Z"
          id="Path"
          stroke="#030F1C"
          strokeWidth="0.3"
          fill="#030F1C"
        />
        <path
          d="M4.44482419,14.7208786 L3.86293995,14.1764582 L3.82965957,14.1481462 C3.61885834,13.9941546 3.35417336,14.008695 3.17466589,14.1764798 C2.94177804,14.394159 2.94177804,14.7810004 3.17466589,14.9986795 L4.1011203,15.8646321 L4.13442379,15.8929657 C4.34522501,16.0469572 4.60991,16.0324169 4.78941747,15.8646321 L6.82761718,13.9595365 L6.85844103,13.9278894 C7.06241597,13.6837987 7.04057083,13.3363835 6.82761718,13.1373367 C6.63170771,12.9542211 6.33522948,12.9542211 6.13932001,13.1373367 L4.44482419,14.7208786 Z"
          id="Path"
          stroke="#030F1C"
          strokeWidth="0.3"
          fill="#030F1C"
        />
        <path
          d="M4.44482419,10.2208786 L3.86293995,9.6764582 L3.82965957,9.6481462 C3.61885834,9.49415465 3.35417336,9.50869501 3.17466589,9.67647979 C2.94177804,9.89415897 2.94177804,10.2810004 3.17466589,10.4986795 L4.1011203,11.3646321 L4.13442379,11.3929657 C4.34522501,11.5469572 4.60991,11.5324169 4.78941747,11.3646321 L6.82761718,9.45953649 L6.85844103,9.42788936 C7.06241597,9.1837987 7.04057083,8.83638351 6.82761718,8.63733674 C6.63170771,8.45422109 6.33522948,8.45422109 6.13932001,8.63733674 L4.44482419,10.2208786 Z"
          id="Path"
          stroke="#030F1C"
          strokeWidth="0.3"
          fill="#030F1C"
        />
        <path
          d="M17,5.68434189e-14 L3,5.68434189e-14 C1.34314575,5.68434189e-14 0,1.34314575 0,3 L0,17.3013485 C0,18.9582027 1.34314575,20.3013485 3,20.3013485 L17,20.3013485 C18.6568542,20.3013485 20,18.9582027 20,17.3013485 L20,3 C20,1.34314575 18.6568542,5.68434189e-14 17,5.68434189e-14 Z M3,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,16.9318901 C18,17.4841749 17.5522847,17.9318901 17,17.9318901 L3,17.9318901 C2.44771525,17.9318901 2,17.4841749 2,16.9318901 L2,3 C2,2.44771525 2.44771525,2 3,2 Z"
          id="Rectangle"
          fill="#030F1C"
        />
      </g>
    </g>
  </svg>
)

type StyledOrderProp = {
  scratchDiscount?: boolean
  removePadding?: boolean
}

const { cssPrefix, toPrefix } = toCssPrefix('OrderResume__')

const StyledOrderResumeItem = styled.div<StyledOrderProp>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  ${({ removePadding }) =>
    !removePadding &&
    css`
      padding: 0 20px;
    `}

  span {
    font-weight: 500;
    width: 50%;
    font-size: 14px;
    color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};

    &:nth-child(even) {
      text-align: right;
    }

    &.OrderResume__main-resume {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  &.discount {
    position: relative;
    text-decoration: ${({ scratchDiscount }) => (scratchDiscount ? 'line-through' : 'none')};
    text-decoration-color: ${({ theme }) => theme.colors.green};
    span {
      color: ${({ theme }) => theme.colors.green};
      font-weight: bold;
    }
  }

  &.total {
    span {
      font-weight: bold;
      font-size: 18px;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

const CollapseContent = styled.div`
  border-bottom: 1px solid rgb(232, 235, 247);
  border-radius: 4px;
`

const Separator = styled.div`
  width: 100%;
  margin: 21px auto 17px auto;
  background: ${({ theme }) => hexToRGBA(theme.colors.black20, 0.1)};
  height: 1px;
`

const WrapperLoading = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SwiperWrapper = styled.div<{ showButtonLeft: boolean }>`
  margin-top: 25px;
  margin-bottom: 26px;
  display: flex;
  justify-content: flex-start;

  .swiper {
    width: calc(100% - 44px);
    margin-left: ${({ showButtonLeft }) => (showButtonLeft ? 'auto' : '0')};
    margin-right: ${({ showButtonLeft }) => (showButtonLeft ? '0' : 'auto')};

    &-button {
      &-next,
      &-prev {
        display: none;
      }
    }
  }

  .${cssPrefix} {
    &swiper-slide {
      width: 64px;
    }

    &slide-content {
      position: relative;
      padding-top: 8px;

      img {
        width: 64px;
        height: 64px;
        border-radius: 5px;
      }

      span {
        position: absolute;
        background: red;
        color: white;
        border-radius: 50%;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
`

const SwiperButton = styled.button<{ showButtonLeft: boolean; showButton: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  background-color: #9fa9b6;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  transition: 200ms;
  content: '';
  border: none;
  margin: ${({ showButtonLeft }) => (showButtonLeft ? 'auto 0 auto 12px' : 'auto 12px auto 0')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ showButton }) =>
    !showButton &&
    css`
      display: none;
    `}

  &:hover {
    opacity: 0.75;
    background-color: ${({ theme }) => theme.colors.red};
  }
`

const toggleLineThroughStyles = (isDashed: boolean) => (isDashed ? { textDecoration: 'line-through' } : {})

const OrderResume = ({
  productsQty,
  subTotal,
  shipping,
  discount,
  couponDiscount,
  orderTotal,
  className,
  showHeader = true,
  totalText,
  isRawDiscount,
  isAditionalDiscounts,
  scratchDiscount,
  removePadding = false,
  cartState,
  currentCity,
}: Props) => {
  const { byHash } = cartState
  const skus = Object.keys(byHash)

  const [isOrderOpen, setIsOrderOpen] = useState(true)
  const [showButtonLeft, setShowButtonLeft] = useState(false)
  const [editProducts, setEditProducts] = useState(false)

  const buttonNextRef = useRef<HTMLDivElement | null>(null)
  const buttonPrevRef = useRef<HTMLDivElement | null>(null)

  const handleCollapseClick = () => {
    setIsOrderOpen(!isOrderOpen)
  }

  const updateButtonsRef = () => {
    if (!buttonNextRef.current) {
      buttonNextRef.current = document.querySelector('.swiper-button-next')
    }

    if (!buttonPrevRef.current) {
      buttonPrevRef.current = document.querySelector('.swiper-button-prev')
    }
  }

  const swiperRight = () => {
    updateButtonsRef()
    if (!buttonNextRef.current) {
      return
    }
    buttonNextRef.current.click()
  }

  const swiperPrev = () => {
    updateButtonsRef()
    if (!buttonPrevRef.current) {
      return
    }
    buttonPrevRef.current.click()
  }

  const onSlideChange = () => {
    updateButtonsRef()

    if (!buttonNextRef.current || !buttonPrevRef.current) {
      return
    }

    if (buttonNextRef.current.classList.contains('swiper-button-disabled')) {
      setShowButtonLeft(true)
    }
    if (buttonPrevRef.current.classList.contains('swiper-button-disabled')) {
      setShowButtonLeft(false)
    }
  }

  const handleEditProducts = () => {
    setEditProducts(true)
  }

  return (
    <Collapse
      key="client_content"
      isOpen={isOrderOpen}
      className="Collapse"
      icon={CheckListIcon}
      label="Resumen de tu compra"
      onClick={handleCollapseClick}
      customMaxHeight={2500}
    >
      <CollapseContent className="Collapse__content">
        {productsQty && parseInt(productsQty) > 0 ? (
          <>
            {productsQty && (
              <>
                <StyledOrderResumeItem removePadding={removePadding}>
                  <span className="OrderResume__main-resume">{`Total de productos: ${productsQty}`}</span>
                  {!editProducts && (
                    <EditIconWrapper onClick={handleEditProducts} style={{ left: '0' }}>
                      editar
                    </EditIconWrapper>
                  )}
                </StyledOrderResumeItem>

                {editProducts ? (
                  <p>Carrito</p>
                ) : (
                  <SwiperWrapper showButtonLeft={!!showButtonLeft}>
                    <SwiperButton
                      type="button"
                      onClick={swiperPrev}
                      showButtonLeft={!!showButtonLeft}
                      showButton={!!showButtonLeft}
                    >
                      <svg
                        width="8"
                        height="13"
                        viewBox="0 0 8 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ transform: 'rotate(180deg)' }}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.91107 0.912046C0.610667 1.21245 0.587559 1.68514 0.841746 2.01205L0.91107 2.09056L5.32116 6.5013L0.91107 10.912C0.610667 11.2125 0.587559 11.6851 0.841746 12.0121L0.91107 12.0906C1.21147 12.391 1.68417 12.4141 2.01108 12.1599L2.08958 12.0906L7.08958 7.09056C7.38998 6.79015 7.41309 6.31746 7.15891 5.99055L7.08958 5.91205L2.08958 0.912046C1.76414 0.58661 1.23651 0.58661 0.91107 0.912046Z"
                          fill="white"
                        />
                      </svg>
                    </SwiperButton>

                    <Swiper
                      className="swiper"
                      navigation
                      slidesPerView="auto"
                      spaceBetween={12}
                      onSlideChange={onSlideChange}
                    >
                      <>
                        {skus.map((sku) => (
                          <SwiperSlide key={sku} className={toPrefix('swiper-slide')}>
                            <div className={toPrefix('slide-content')}>
                              <img src={byHash[sku].image ?? ''} alt={byHash[sku].title} />
                              <span>{byHash[sku].quantity}</span>
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    </Swiper>

                    <SwiperButton
                      type="button"
                      onClick={swiperRight}
                      showButtonLeft={!!showButtonLeft}
                      showButton={!showButtonLeft}
                    >
                      <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.91107 0.912046C0.610667 1.21245 0.587559 1.68514 0.841746 2.01205L0.91107 2.09056L5.32116 6.5013L0.91107 10.912C0.610667 11.2125 0.587559 11.6851 0.841746 12.0121L0.91107 12.0906C1.21147 12.391 1.68417 12.4141 2.01108 12.1599L2.08958 12.0906L7.08958 7.09056C7.38998 6.79015 7.41309 6.31746 7.15891 5.99055L7.08958 5.91205L2.08958 0.912046C1.76414 0.58661 1.23651 0.58661 0.91107 0.912046Z"
                          fill="white"
                        />
                      </svg>
                    </SwiperButton>
                  </SwiperWrapper>
                )}
              </>
            )}
            <StyledOrderResumeItem removePadding={removePadding}>
              <span>Sub-total</span>
              <span>{subTotal}</span>
            </StyledOrderResumeItem>
            <StyledOrderResumeItem removePadding={removePadding}>
              <span>Despacho</span>
              <span>{shipping}</span>
            </StyledOrderResumeItem>
            {discount ? (
              <StyledOrderResumeItem
                removePadding={removePadding}
                scratchDiscount={scratchDiscount}
                className="discount"
              >
                <span>Descuentos</span>
                <span>{discount}</span>
              </StyledOrderResumeItem>
            ) : null}
            {/* TODO: Show detail later */}
            {/* {couponDiscount ? (
          <StyledOrderResumeItem removePadding={removePadding} className="discount">
            <span>
              Descuentos&nbsp;
              {isAditionalDiscounts ? 'adicionales' : 'promocionales'}
            </span>
            <span>{couponDiscount}</span>
          </StyledOrderResumeItem>
        ) : null} */}
            <StyledOrderResumeItem removePadding={removePadding} className="total">
              <span>{totalText ?? 'Total a pagar'}</span>
              <span>{orderTotal}</span>
            </StyledOrderResumeItem>
            <Separator />
          </>
        ) : (
          <WrapperLoading>
            <Loading />
          </WrapperLoading>
        )}
      </CollapseContent>
    </Collapse>
  )
}

export default OrderResume
