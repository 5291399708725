import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { MouseEventButton } from '@ecommerce/shared'
import { baseInput } from '../../../styles/baseForms'
import Anchor from '../../atoms/Anchor'

const SearchIcon = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>search</title>
    <path d="M12.667 4c4.786 0 8.667 3.88 8.667 8.667 0 2.147-0.787 4.12-2.080 5.64l0.36 0.36h1.053l6.667 6.667-2 2-6.667-6.667v-1.053l-0.36-0.36c-1.52 1.293-3.493 2.080-5.64 2.080-4.786 0-8.667-3.88-8.667-8.667s3.88-8.667 8.667-8.667zM12.667 6.667c-3.333 0-6 2.667-6 6s2.667 6 6 6c3.333 0 6-2.667 6-6s-2.667-6-6-6z" />
  </svg>
)

const InputStyled = styled.input`
  ${baseInput(undefined)}
`

const Wrapper = styled.div<{ hasText: boolean }>`
  position: relative;
  input {
    box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
    min-height: 40px;
    height: 100%;
    padding-left: 45px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 24px;
    margin-left: 17px;
  }
  .SearchBox__ {
    &reset {
      position: absolute;
      top: 0;
      right: 20px;
      height: 100%;
      width: 24px;
      margin-left: 17px;
      display: ${(props) => (props.hasText ? 'static' : 'none')};
    }
  }
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string
  className?: string
  CustomSearchIcon?: () => JSX.Element
  CustomCloseIcon?: () => JSX.Element
  hasText?: boolean
  reset?: () => void
  keyDownHandler?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  reference?: React.MutableRefObject<HTMLInputElement | null>
}

const SearchInput = (props: Props) => {
  const {
    inputClassName,
    className,
    CustomSearchIcon,
    CustomCloseIcon,
    hasText = false,
    reset,
    reference,
    ...restProps
  } = props

  function onReset(e: MouseEventButton) {
    e.preventDefault()
    if (reset) reset()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <Wrapper className={className} hasText={hasText}>
      <InputStyled
        className={inputClassName}
        {...restProps}
        onKeyDown={props.keyDownHandler ? props.keyDownHandler : handleKeyDown}
        ref={reference}
      />
      {CustomSearchIcon ? <CustomSearchIcon /> : <SearchIcon />}
      <Anchor onClick={onReset} className="SearchBox__reset" id="search-close-button">
        {CustomCloseIcon ? <CustomCloseIcon /> : <SearchIcon />}
      </Anchor>
    </Wrapper>
  )
}

export default SearchInput
