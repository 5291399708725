/* eslint-disable no-console, @typescript-eslint/no-explicit-any */

export function loadSession<T>(key: string): T | undefined
export function loadSession(key: string): any | undefined

export function loadSession(key: string) {
  if (typeof window === 'undefined') {
    return
  }

  try {
    const serializedState = sessionStorage.getItem(key)

    if (serializedState === null) return undefined

    return JSON.parse(serializedState)
  } catch (error) {
    console.log(error)
    return undefined
  }
}

export function saveSession(key: string, state: any) {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem(key, serializedState)
  } catch (error) {
    console.log(error)
    return error
  }
}
