import React, { useState, useEffect, useRef } from 'react'
import { Country } from '../types'

// Google API typings
type Map = google.maps.Map
type Marker = google.maps.Marker
type MapListener = google.maps.MapsEventListener
export type Coordinates = google.maps.LatLngLiteral
type LatLng = google.maps.LatLng

type ContainerRef = React.RefObject<HTMLDivElement>
type OnChangeResult = Coordinates & { val: string }
export type OnMapChange = (result: OnChangeResult) => void
type HookParams = {
  container: ContainerRef
  onChange: OnMapChange
  country?: Country
  city?: string
  marker?: string
  setDefault?: boolean
}
type RenderParams = Coordinates & {
  markerTitle?: string
  markerIcon?: string
}

type Error = {
  exists: boolean
  message: string
}

type Return = {
  renderMap: (params: RenderParams) => void
  map: Map | null
  error: Error
  ready: boolean
}
type UseMap = (params: HookParams) => Return

export const useMap: UseMap = ({ container, onChange, city, country, marker, setDefault }) => {
  const [currentMap, setCurrentMap] = useState<Map | null>(null)
  const [currentMarker, setCurrentMarker] = useState<Marker | null>(null)
  const [ready, setReady] = useState(false)
  const [error, setError] = useState<Error>({
    exists: false,
    message: '',
  })
  const listener = useRef<MapListener | null>()
  const queryCountry = country === 'CL' ? 'Chile' : 'Bolivia'

  /**
   * Reverse geocode coordinates to get address
   * @param latLng
   */
  const getAddress = (latLng: LatLng | undefined, address?: string): Promise<OnChangeResult> =>
    new Promise((resolve) => {
      const api = window?.google?.maps

      if (!api) setError({ exists: true, message: `You must include the Google maps API` })

      const geocoder = new api.Geocoder()
      geocoder.geocode({ location: latLng, address }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const sanitizedAddress = results[0].formatted_address
              .replace(`, ${city}`, '')
              .replace(`, ${queryCountry}`, '')

            const result = {
              val: sanitizedAddress,
              lat: latLng?.lat() ?? results[0].geometry.location.lat(),
              lng: latLng?.lng() ?? results[0].geometry.location.lng(),
            }

            resolve(result)
          }
        } else {
          throw new Error(`Geocoder failde due to ${status}`)
        }
      })
    })

  /**
   * Update map and marker position
   * @param position
   */
  const updateMap = (position: Coordinates) => {
    if (currentMap && currentMarker) {
      currentMarker.setPosition(position)
      currentMap.panTo(position)
    } else setError({ exists: true, message: `There is no map available` })
  }

  const handleMapClick = async ({ latLng }: { latLng: LatLng }) => {
    try {
      // Clear errors
      setError({ exists: false, message: '' })

      // New coordinates
      const lat = latLng.lat()
      const lng = latLng.lng()

      // Reverse geocode
      const address = await getAddress(latLng)

      updateMap({ lat, lng })

      onChange(address)
    } catch (e) {
      setError({ exists: true, message: e })
    }
  }

  const renderMap = ({ lat, lng, markerTitle, markerIcon }: RenderParams): void => {
    try {
      if (ready) {
        const api = window?.google?.maps

        if (!api) throw new Error('You must include the Google maps API')
        if (!container.current) throw new Error('You must set a map container')

        if (!currentMap && !currentMarker) {
          const position = {
            lat: typeof lat === 'string' ? parseFloat(lat) : lat,
            lng: typeof lng === 'string' ? parseFloat(lng) : lng,
          }

          const map = new api.Map(container.current, {
            zoom: 15,
            center: position,
            streetViewControl: false,
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
            ],
          })

          const mapMarker = new api.Marker({
            position,
            map,
            title: markerTitle,
            icon: markerIcon,
          })

          setCurrentMarker(mapMarker)
          setCurrentMap(map)
        } else {
          updateMap({ lat, lng })
        }
      }
    } catch (e) {
      setError({ exists: true, message: e })
    }
  }

  useEffect(() => {
    if (window.google?.maps && !ready) {
      setReady(true)
    }
  })

  // Add event listener to map
  useEffect(() => {
    if (currentMap) {
      listener.current = currentMap.addListener('click', handleMapClick)
    }
  }, [currentMap])

  // Remove event listener on dismount
  useEffect(() => {
    return () => {
      if (listener.current && window?.google?.maps) window.google.maps.event.removeListener(listener.current)
    }
  }, [])

  // Set default map if required
  useEffect(() => {
    const setInitialMap = async () => {
      const { val, lat, lng } = await getAddress(undefined, `${city}, ${queryCountry}`)

      // Set map
      renderMap({ lat, lng, markerTitle: val, markerIcon: marker })
    }

    if (city && ready && setDefault) setInitialMap()
  }, [city, ready, setDefault])

  return { renderMap, map: currentMap, error, ready }
}
