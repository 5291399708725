import { graphql, useStaticQuery } from 'gatsby'

export interface ContentfulPromotionCouponsWhitelistQueryResult {
  allContentfulGlobal: {
    edges: { node: { promotionCouponsWhitelist: string[] } }[]
  }
}

const extractPromotionCouponsWhitelist = (queryResult: ContentfulPromotionCouponsWhitelistQueryResult) => {
  if (!queryResult.allContentfulGlobal.edges.length) return []
  return queryResult.allContentfulGlobal.edges[0].node.promotionCouponsWhitelist ?? []
}

export const usePromotionCouponsWhitelist = () => {
  const promotionCouponsWhitelist = extractPromotionCouponsWhitelist(
    useStaticQuery(graphql`
      query PromotionCouponsWhitelistQuery {
        allContentfulGlobal {
          edges {
            node {
              promotionCouponsWhitelist
            }
          }
        }
      }
    `),
  )
  return promotionCouponsWhitelist
}
