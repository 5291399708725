/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { capitalize, toCssPrefix, toMonthName } from '../../../utils/format'
import Select from '../../molecules/Select'
import TextField from '../../molecules/TextField'
import { Status } from '../../molecules/TextField/Styled'
import InfoMesage from '../../atoms/InfoMessage'
import { breakpoints } from '../../../utils/breakpoints'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onDateChange: (date: string) => void
  errorMessage?: string
  status?: Status
  disabled?: boolean
}

const { cssPrefix, toPrefix } = toCssPrefix('BirthDateInput__')

const Wrapper = styled.div`
  width: 100%;

  &.status--error {
    label,
    .${cssPrefix}error-message {
      color: ${({ theme }) => theme.colors.error};

      svg {
        fill: ${({ theme }) => theme.colors.error};
      }
    }
  }

  .${cssPrefix} {
    &inputs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
    }

    &month {
      width: 60%;
      max-width: 60%;
    }

    &day,
    &year {
      max-width: 30%;
    }

    &year,
    &day {
      input {
        padding-right: 5px;
      }
    }

    &label {
      display: block;
      font-size: 0.75rem;
      line-height: 1.2;
      margin-bottom: 0;
      transition: all 0.3s ease-in;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &month {
        width: 160px;
        min-width: 160px;
      }
    }
  }
`

const BirthDateInput = ({ className = '', onDateChange, errorMessage, status, disabled = false, ...props }: Props) => {
  const [day, setDay] = useState<number>()
  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState<number>()

  useEffect(() => {
    if (typeof day === `number` && typeof month === `number` && typeof year === `number`) {
      onDateChange(`${day}/${month}/${year}`)
    }
  }, [day, month, year])

  return (
    <Wrapper {...props} className={`${cssPrefix} ${className} status--${status}`}>
      <label htmlFor="birth-day" className={toPrefix(`label`)}>
        Fecha de nacimiento
      </label>
      <div className={toPrefix('inputs')}>
        <TextField
          data-testid="birth-day"
          min={1}
          max={31}
          onChange={({ currentTarget }) => setDay(Number(currentTarget.value))}
          name="birth-day"
          placeholder="Día"
          className={toPrefix('day')}
          type="number"
          status={status}
          disabled={disabled}
        />
        <Select
          name="birth-month"
          dataTest="birth-month"
          className={toPrefix('month')}
          onSelect={(value) => setMonth(Number(value))}
          placeholder="Mes"
          options={Array(12)
            .fill(0)
            .map((_, i) => ({ label: capitalize(toMonthName(i + 1)), value: i + 1 }))}
          status={status}
          disabled={disabled}
        />
        <TextField
          data-testid="birth-year"
          min={1900}
          onChange={({ currentTarget }) => setYear(Number(currentTarget.value))}
          type="number"
          placeholder="Año"
          name="birth-year"
          className={toPrefix('year')}
          status={status}
          disabled={disabled}
        />
      </div>
      <InfoMesage
        className={`${toPrefix('error-message')} TextField__help`}
        isHidden={!errorMessage}
        message={errorMessage}
      />
    </Wrapper>
  )
}

export default BirthDateInput
