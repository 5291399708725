import React, { useEffect, useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useLocation } from '@ecommerce/shared'
import DocumentType from '@ecommerce/shared/src/components/molecules/DocumentType'
import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'
import useDebounce from '@ecommerce/shared/src/hooks/useDebounce'
import Button from '../../atoms/Button'
import InfoMessage from '../../atoms/InfoMessage'
import TextField from '../../molecules/TextField'
import Select from '../../molecules/Select'
import Checkbox from '../../atoms/Checkbox'
import { getSchema } from '../AuthWidget/libs'
import { ALink, CheckboxOption, WrapperForm, toPrefix as createCssPrefix } from '../AuthWidget/styled'
import { formatRut } from '../../../utils/forms'
import useEnterKeyPress from '../../../hooks/useEnterKeyPress'
import BirthDateInput from './BirthDateInput'
import { Zone } from '../../../services/Location/cities'
import useSignUp from './useSignUp'
import useCheckEmail from './useCheckEmail'
import useCheckEmailBolivia from './useCheckEmailBolivia'

const { documentTypes } = secrets

export interface FormData {
  firstName: string
  lastName: string
  dni: string
  email: string
  password: string
  city: number | string
  market: number | string
  birthdate: string
  terms: boolean
  documentType?: string
  dniComplement?: string
  billingName?: string
}

export interface Props {
  onFinished?: (data: FormData) => void
  onClickSignIn?: () => void
  onClickSignUpConfirm?: () => void
  prehome?: boolean
  setLoading: (isLoading: boolean) => void
  loading: boolean
  onSend?: () => void
  onError?: () => void
  className?: string
  title?: string | React.ReactNode
  showBottomContent?: boolean
  showConfirmation?: boolean
  onFormatRedirectURL?: (data: FormData) => string
  disableInputs?: boolean
  isCheckout?: boolean
}

const emailImage =
  'https://images.ctfassets.net/16npdkkoi5mj/3w3FT0mHzzLuLcDRXDppod/038f39a70354cacc89688b81a54a118d/mailenviado.png'

const FormSignUp = (props: Props) => {
  const {
    onClickSignIn,
    onClickSignUpConfirm,
    prehome,
    onFinished,
    onSend,
    onError,
    loading,
    className = '',
    title = 'Crea tu cuenta',
    showBottomContent = true,
    showConfirmation = true,
    onFormatRedirectURL,
    disableInputs = false,
    isCheckout = false,
  } = props

  const {
    state: { markets },
    isBolivia,
  } = useLocation()

  const [selectedDocumentType, setSelectedDocumentType] = useState('ci')
  const [isBillingName, setIsBillingName] = useState(true)
  const [emailValue, setEmailValue] = useState('')
  const [dniValue, setDniValue] = useState('')

  const emailDebounce = useDebounce(emailValue, 1000)
  const dniDebounce = useDebounce(dniValue, 1000)

  const prevDni: string | number = ''

  const yupSchema = getSchema(isBolivia(), true, selectedDocumentType)
  const methods = useForm<FormData>({
    resolver: yupResolver(yupSchema),
    mode: 'all',
  })

  const {
    register,
    clearErrors,
    handleSubmit,
    errors,
    watch,
    setValue,
    trigger,
    control,
    reset,
    setError,
    getValues,
  } = methods

  const [citySelector, setCitySelector] = useState<string | number | undefined>()
  const [regionSelector, setRegionSelector] = useState<string | number | undefined>()
  const [cityOptions, setCityOptions] = useState<Array<{ label: string; value: number }>>([])
  const [cities, setCities] = useState<Zone[] | undefined>([])

  const watchFields = watch()

  const { error, formData, renderConfirmation, confirmationEmail, loadingConfirmation, submit: onSubmit } = useSignUp({
    onSend,
    onError,
    onFinished,
    onFormatRedirectURL,
    onClickSignUpConfirm,
    loading,
    preHome: prehome,
    showConfirmation,
  })

  const { checkEmail, isChecking, setIsChecking } = useCheckEmail({
    getValues,
    setError,
    clearErrors,
  })

  const { checkEmailBolivia, isCheckingBolivia, setIsCheckingBolivia } = useCheckEmailBolivia({
    getValues,
    setError,
    clearErrors,
  })

  useEffect(() => {
    if (getValues().dni !== undefined) {
      setDniValue(getValues().dni)
    }
  }, [getValues().dni])

  useEffect(() => {
    if (emailValue !== '' && dniValue !== '') {
      if (isBolivia()) {
        checkEmailBolivia()
      } else {
        checkEmail()
      }
    }
  }, [emailDebounce, dniDebounce])

  useEnterKeyPress(() => handleSubmit(onSubmit))

  useEffect(() => {
    register({ name: 'market' })
    register({ name: 'city' })
    register({ name: 'birthdate' })
    register({ name: 'billingName' })
  }, [register])

  useEffect(() => {
    const zones = regionSelector ? markets.find((mk) => mk.id === regionSelector)?.zones : []
    setCities(zones)
  }, [regionSelector])

  useEffect(() => {
    const citySelectOptions = Object.values(cities ?? { value: 'empty', label: 'empty' }).map((city) => ({
      label: city.name,
      value: city.id,
    }))
    setCityOptions(citySelectOptions)
  }, [cities])

  useEffect(() => {
    reset({
      ...watchFields,
      documentType: isBolivia() ? 'ci' : 'rut',
    })
  }, [reset])

  useEffect(() => {
    if (!isBillingName) {
      setValue('billingName', `${watchFields.firstName} ${watchFields.lastName}`, { shouldDirty: true })
    } else {
      setValue('billingName', '', { shouldDirty: true })
    }
  }, [isBillingName])

  const errorMessage = error?.description
  const isSubmitDisabled =
    !watchFields.firstName ||
    !watchFields.lastName ||
    !watchFields.dni ||
    !watchFields.email ||
    !watchFields.password ||
    !regionSelector ||
    !watchFields.birthdate ||
    !watchFields.terms ||
    !!Object.keys(errors).length

  const onCitySelect = (value: string): void => {
    setCitySelector(cities?.find((ct) => ct.id === parseInt(value.valueOf().toString(), 10))?.name ?? '')
    setValue('city', parseInt(value.valueOf().toString(), 10), { shouldValidate: true })
  }

  const onRegionSelect = (value: string): void => {
    setRegionSelector(value)
    setValue('market', parseInt(value.valueOf().toString(), 10), { shouldValidate: true })
    if (isBolivia()) {
      const market = markets.filter((mk) => mk.id === parseInt(value, 10))[0]
      if (market && market.zones) {
        setValue('city', market?.zones[0]?.id ?? '', { shouldValidate: true })
        setCitySelector(value)
      }
    } else {
      setCitySelector('')
      setValue('city', 0, { shouldValidate: true })
    }
  }

  function handleDniChange(dni: string) {
    clearErrors('dni')
    let value = dni
    if (!isBolivia()) value = dni.length === 0 || dni === '-' || dni === ' ' ? '' : formatRut(dni)
    return setValue('dni', value)
  }

  const isBO = isBolivia()

  const toPrefix = (prefix: string) => `${createCssPrefix(prefix)} ${className}__${prefix}`

  const onBillingNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('billingName', value, { shouldDirty: true })
    await trigger('billingName')
  }

  const onDateChange = async (date: string) => {
    setValue('birthdate', date)
    await trigger('birthdate')
  }

  return (
    <>
      <FormProvider {...methods}>
        <WrapperForm className={className} height="100%" onSubmit={handleSubmit(onSubmit)} isCheckout={!!isCheckout}>
          {title && <span className={toPrefix('title')}>{title}</span>}
          {!renderConfirmation ? (
            <>
              <span className={toPrefix('subtitle')}>Regístrate para continuar</span>

              <Controller
                control={control}
                name="firstName"
                render={({ value, onChange }) => (
                  <TextField
                    maxLength={isBO ? 50 : undefined}
                    className={toPrefix('input')}
                    label="Nombre"
                    value={value || ''}
                    type="text"
                    status={!errors.firstName ? undefined : 'error'}
                    errorMessage={errors.firstName?.message}
                    onChange={onChange}
                    disabled={disableInputs}
                  />
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ value, onChange }) => (
                  <TextField
                    maxLength={isBO ? 50 : undefined}
                    className={toPrefix('input')}
                    label="Apellido"
                    value={value || ''}
                    type="text"
                    status={!errors.lastName ? undefined : 'error'}
                    errorMessage={errors.lastName?.message}
                    onChange={onChange}
                    disabled={disableInputs}
                  />
                )}
              />

              {isBolivia() && (
                <>
                  <CheckboxOption>
                    <Checkbox
                      disabled={!watchFields.firstName && !watchFields.lastName}
                      onChange={() => setIsBillingName(!isBillingName)}
                      checked={!isBillingName}
                      className="CheckboxOption__input"
                    />
                    <p className="CheckboxOption__label">Utilizar mi nombre y apellidos para facturas.</p>
                  </CheckboxOption>
                  {isBillingName && (
                    <TextField
                      label="Nombre para facturas"
                      name="billingName"
                      status={!errors.billingName ? undefined : 'error'}
                      errorMessage={errors.billingName?.message}
                      value={watchFields.billingName || ''}
                      onChange={onBillingNameChange}
                    />
                  )}
                  <DocumentType
                    isVisible
                    isGuest
                    dni={prevDni}
                    setIsCheckingDni={setIsCheckingBolivia}
                    selectedDocumentType={selectedDocumentType}
                    setSelectedDocumentType={setSelectedDocumentType}
                    types={documentTypes}
                  />
                </>
              )}

              {!isBolivia() && (
                <Controller
                  control={control}
                  name="dni"
                  render={({ value }) => (
                    <TextField
                      className={toPrefix('input')}
                      label="RUT"
                      name="dni"
                      onChange={({ target: { value: dni } }) => handleDniChange(dni)}
                      type="text"
                      value={value || ''}
                      maxLength={isBO ? 20 : 12}
                      status={!errors.dni ? undefined : 'error'}
                      errorMessage={errors.dni?.message}
                      disabled={disableInputs}
                    />
                  )}
                />
              )}

              <Controller
                control={control}
                name="email"
                render={({ value, onChange }) => (
                  <TextField
                    maxLength={isBO ? 50 : undefined}
                    className={toPrefix('input')}
                    label="Correo electrónico"
                    type="email"
                    status={!errors.email ? undefined : 'error'}
                    value={emailValue || ''}
                    errorMessage={errors.email?.message}
                    disabled={disableInputs}
                    autoCapitalize="nope"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (isBolivia()) setIsCheckingBolivia(true)
                      else setIsChecking(true)
                      setValue('email', e.target.value)
                      setEmailValue(e.target.value)
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="password"
                render={({ value, onChange }) => (
                  <TextField
                    className={toPrefix('input')}
                    label="Contraseña"
                    type="password"
                    status={!errors.password ? undefined : 'error'}
                    value={value || ''}
                    errorMessage={errors.password?.message}
                    helpMessage="Mínimo 8 caracteres"
                    autoComplete="new-password"
                    disabled={disableInputs}
                    onChange={onChange}
                  />
                )}
              />

              {!isBolivia() && (
                <>
                  <Select
                    label="Region"
                    name="market"
                    onSelect={onRegionSelect}
                    options={Object.values(markets).map((val) => ({
                      value: val.id,
                      label: val.name,
                    }))}
                    className={toPrefix('select')}
                    placeholder="Seleccionar región"
                    status={!errors.market ? undefined : 'error'}
                    errorMessage={errors.market?.message}
                    disabled={disableInputs}
                  />

                  <Select
                    label="Comuna"
                    name="ciudad"
                    onSelect={onCitySelect}
                    options={cityOptions}
                    className={toPrefix('select')}
                    value={citySelector as string}
                    placeholder="Seleccionar comuna"
                    status={!errors.city ? undefined : 'error'}
                    errorMessage={errors.city?.message}
                    disabled={disableInputs || !regionSelector}
                  />
                </>
              )}

              {isBolivia() && (
                <>
                  <Select
                    label="Ciudad"
                    name="market"
                    onSelect={onRegionSelect}
                    options={Object.values(markets).map((val) => ({
                      value: val.id,
                      label: val.name,
                    }))}
                    className={toPrefix('select')}
                    placeholder="Seleccionar ciudad"
                    status={!errors.market ? undefined : 'error'}
                    errorMessage={errors.market?.message}
                    disabled={disableInputs}
                  />
                </>
              )}

              <BirthDateInput
                errorMessage={errors.birthdate?.message}
                className={toPrefix('input')}
                onDateChange={onDateChange}
                status={!errors.birthdate ? undefined : 'error'}
                disabled={disableInputs}
              />

              <CheckboxOption className={toPrefix('terms-checkbox')}>
                <Checkbox disabled={disableInputs} className="CheckboxOption__input" name="terms" ref={register} />
                <p className="CheckboxOption__label">
                  He leído y acepto los&nbsp;
                  <a className="CheckboxOption__anchor" href="/terminos-y-condiciones" target="_blank">
                    Términos y Condiciones
                  </a>
                  .
                </p>
              </CheckboxOption>
              <InfoMessage
                className={`TextField__help ${toPrefix('terms-error')}`}
                isHidden={!errors.terms}
                message={errors?.terms?.message}
              />
              <Button
                data-test="signin-submit"
                className={toPrefix('submit')}
                whileTap={{ scale: 0.9 }}
                type="submit"
                isDisabled={isSubmitDisabled || loading || isChecking || isCheckingBolivia}
                disabled={isSubmitDisabled || loading || isChecking || isCheckingBolivia}
                isLoading={loadingConfirmation || loading || isChecking || isCheckingBolivia}
              >
                Crear Cuenta
              </Button>
              <div className={toPrefix('status')}>
                <InfoMessage className={toPrefix('error_message')} isHidden={!errorMessage} message={errorMessage} />
                {showBottomContent && (
                  <div className={toPrefix('setting_account')}>
                    <span>¿Ya tienes cuenta?</span>
                    <ALink disabled={loading} onClick={() => (!loading && onClickSignIn ? onClickSignIn() : null)}>
                      Inicia sesión
                    </ALink>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <span className={toPrefix('main-indication')}>
                {`Te enviamos un correo a ${confirmationEmail} para confirmar tu cuenta`}
              </span>
              <img src={emailImage} className={toPrefix('email-image')} alt="confirm" />
              <span className={toPrefix('indication')}>
                ¿No encuentras el correo? Revisa la carpeta de “No deseados”
              </span>
              <Button
                btnType="secondary"
                data-test="signup-submit"
                className={toPrefix('submit-white')}
                whileTap={{ scale: 0.9 }}
                onClick={() => onSubmit(formData as FormData)}
                isLoading={loading}
              >
                Reenviar Correo
              </Button>

              {showBottomContent && !!isCheckout && (
                <div className={toPrefix('setting_account')}>
                  <span>¿Ya tienes cuenta?</span>
                  <ALink disabled={loading} onClick={() => (!loading && onClickSignIn ? onClickSignIn() : null)}>
                    Inicia sesión
                  </ALink>
                </div>
              )}
            </>
          )}
        </WrapperForm>
      </FormProvider>
    </>
  )
}

export default FormSignUp
