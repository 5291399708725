import * as Yup from 'yup'
import { validateInputField, validateRut, getAge } from '../../../utils/forms'
import { AuthFormType } from '../../../types'
import { Props as FormGuestProps } from '../FormGuest'
import { Props as FormSignUpProps } from '../FormSignUp'
import { Props as FormSignInProps } from '../FormSignIn'

export const getDniBoliviaSchema = (type: string) => {
  let dniSchema: Yup.StringSchema<string | undefined> | Yup.NumberSchema<number | undefined> = Yup.number()
  let dniComplementSchema: Yup.StringSchema<string | undefined, Yup.StringSchema> = Yup.string()

  if (type === 'ci') {
    dniSchema = Yup.number()
      .required('Debes completar este campo')
      .moreThan(10000, 'El CI debe contener al menos 5 dígitos')
      .lessThan(99999999, 'El CI ingresado no es válido')
      .typeError('El CI debe de ser un número')
      .default(10000)
    dniComplementSchema = Yup.string().min(0).max(3, 'El CI complementario debe tener como máximo 3 caracteres')
  }
  if (type === 'nit') {
    dniSchema = Yup.number()
      .required('Debes completar este campo')
      .moreThan(10000000, 'El NIT debe contener al menos 8 caracteres')
      .lessThan(999999999999999, 'El NIT ingresado no es válido')
      .typeError('El NIT debe de ser un número')
      .default(10000000)
  }
  if (type === 'email') {
    dniSchema = Yup.string().email('El formato de email no es el correcto').required('Debes completar este campo')
  }
  if (type === 'pasaporte' || type === 'otros' || type === 'carnet_extranjeria') {
    dniSchema = Yup.string().required('Debes completar este campo')
  }
  return {
    dni: dniSchema,
    dniComplement: dniComplementSchema,
  }
}

export const getSchema = (isBolivia: boolean, isSignup: boolean, type: string) => {
  const { dni, dniComplement } = getDniBoliviaSchema(type)
  let dniFieldRules: Yup.StringSchema<string | undefined> | Yup.NumberSchema<number | undefined> = dni
  let dniComplementFieldRules: Yup.StringSchema<string | undefined, Yup.StringSchema> = dniComplement

  if (!isBolivia) {
    dniFieldRules = Yup.string()
      .required('Debes completar este campo')
      .test('Rut Test', 'Por favor, ingresa un rut válido', (value) => validateRut(value || ''))
    dniComplementFieldRules = Yup.string()
  }

  let schemaFields: Record<string, Yup.StringSchema | Yup.BooleanSchema | Yup.NumberSchema> = {
    firstName: Yup.string()
      .required('Debes completar este campo')
      .test('Name Test', 'Debes ingresar un nombre válido', (val) => validateInputField(val ?? '', 'LETTERS_ONLY')),
    lastName: Yup.string()
      .required('Debes completar este campo')
      .test('LastName Test', 'Debes ingresar un apellido válido', (val) =>
        validateInputField(val ?? '', 'LETTERS_ONLY'),
      ),
    dni: dniFieldRules,
    dniComplement: dniComplementFieldRules,
    email: Yup.string().email('El formato de email no es el correcto').required('Debes completar este campo'),
    birthdate: Yup.string()
      .required('Debes completar este campo')
      .test(
        'Birthdate Test',
        'Por favor, ingresa una fecha válida (dd/mm/aaaa). Debes tener más de 18 años.',
        (value) => getAge(value || ''),
      ),
    terms: Yup.boolean().oneOf([true], 'Debes completar este campo'),
  }

  if (isSignup) {
    schemaFields = {
      ...schemaFields,
      password: Yup.string()
        .required('Debes completar este campo')
        .min(8, ({ min }) => `Mínimo ${min} caracteres`),
      city: Yup.number().required('Debes completar este campo'),
      market: Yup.number().required('Debes completar este campo'),
    }
  }

  return Yup.object().shape(schemaFields)
}

export type FormProps<T extends unknown> = Omit<
  T,
  'onClickSignUp' | 'onClickSignIn' | 'onCloseModal' | 'onClickResetPassword' | 'setLoading' | 'loading'
>

export type CommonProps = {
  className?: string
  title: string
  subtitle?: string
  noClose?: boolean
  onClose: () => void
  showWidget: (type: AuthFormType) => void
  closeWidget: () => void
  useModal?: boolean
  setIsLoadingUserData?: (isLoadingUserData: boolean) => void
}

export type Props = CommonProps & {
  formSettings:
    | {
        type: AuthFormType.GUEST
        props: FormProps<FormGuestProps>
      }
    | {
        type: AuthFormType.SIGNUP
        props: FormProps<FormSignUpProps>
      }
    | {
        type: AuthFormType.SIGNIN
        props: FormProps<FormSignInProps>
      }
}
