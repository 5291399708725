import { graphql, useStaticQuery } from 'gatsby'

export interface ContentfulMaxCartProductQueryResult {
  allContentfulGlobal: {
    edges: { node: { maxLineProductQuantity: number; maxLineBundleQuantity: number } }[]
  }
}

const extractMaxCartProductQuantity = (queryResult: ContentfulMaxCartProductQueryResult) => {
  if (!queryResult.allContentfulGlobal.edges.length) return { maxCartProductQuantity: 0, maxCartBundleQuantity: 0 }
  return {
    maxCartProductQuantity: queryResult.allContentfulGlobal.edges[0].node.maxLineProductQuantity ?? 0,
    maxCartBundleQuantity: queryResult.allContentfulGlobal.edges[0].node.maxLineBundleQuantity ?? 0,
  }
}

export const useMaxCartQuantitiesQuery = () => {
  const maxCartQuantities = extractMaxCartProductQuantity(
    useStaticQuery(graphql`
      query MaxProductQuantityQuery {
        allContentfulGlobal {
          edges {
            node {
              maxLineProductQuantity
              maxLineBundleQuantity
            }
          }
        }
      }
    `),
  )
  return maxCartQuantities
}
