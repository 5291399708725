import { AxiosPromise } from 'axios'
import fetch from './fetch'
import { Country } from '../../types'

export interface DistributionCenter {
  id: number
  name: string
  internalName: string
  slug: string
  commerceLayer: {
    market: {
      id: string
      number: number
    }
    priceList: {
      id: string
    }
    paymentMethod: {
      id: string
    }
    shippingMethod: {
      id: string
    }
    inventoryStockLocation: {
      id: string
    }
    stockLocation: {
      id: string
      name: string
      number: number
    }
  }
}

interface Polygon {
  type: string
  coordinates: Array<Array<number>>
}

interface CommerceLayer {
  id?: number
  market: { number: number; id: string }
  priceList: {
    id: string
  }
  paymentMethod: { id: string }
  shippingMethod: { id: string }
}

interface DailyConfiguration {
  id: number
  dayOfWeek: number
  isDeliveryDay: boolean
  deliveryMaxCapacity: number
  closingTime: string
  deliveryMinHours: number
}

export interface Zone {
  id: number
  name: string
  internalName: string
  distributionCenterId: number
  polygon?: Polygon
  dailyConfigurations?: DailyConfiguration[]
  googleEmbedId?: string
}

export interface Market {
  id: number
  name: string
  internalName: string
  slug: string
  zones: Zone[]
  metadata: { commerceMid?: string } | null
  distributionCenters: DistributionCenter[]
}

type ResponseMarkets = Market[]

export function getMarkets(params?: { country: Country }): AxiosPromise<ResponseMarkets> {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    country: (params && params.country) || 'CL',
  }
  return fetch('/markets', { headers, method: 'GET' })
}
