import React from 'react'
import App from './src/App'
import { InitAppDataLayer } from './src/pageWrapper/InitDataLayer'
import { navigateWithUTMQueryParams } from './src/utils/utmParams'

import 'swiper/swiper-bundle.min.css'

export const wrapRootElement = ({ element }) => <App>{element}</App>
export const wrapPageElement = ({ element, props }) => {
  return <InitAppDataLayer pageProps={props}>{element}</InitAppDataLayer>
}

export const onPreRouteUpdate = navigateWithUTMQueryParams

export const onRouteUpdate = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || []
  }
}

/* Load network error page */
const count = 0
export const onPrefetchPathname = ({ loadPage }) => {
  if (count === 0) {
    loadPage('/network-error')
  }
}

export const registerServiceWorker = async () => {
  if (typeof window !== 'undefined') {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('sw.js', {
          scope: './',
        })
        if (registration.installing) {
          // eslint-disable-next-line no-console
          console.info('Service worker installing')
        } else if (registration.waiting) {
          // eslint-disable-next-line no-console
          console.info('Service worker installed')
        } else if (registration.active) {
          // eslint-disable-next-line no-console
          console.info('Service worker active')
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Registration failed with ${error}`)
      }
    }
  }
}

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission()

  if (permission !== 'granted') {
    // eslint-disable-next-line no-console
    console.error(`Notification permission not granted`)
  }
}

// registerServiceWorker()
export const onClientEntry = () => {
  registerServiceWorker()
  requestNotificationPermission()
}
