import qs from 'querystring'
import { keyframes } from 'styled-components'
import secrets from '../../../config/secrets'

type BuildURLParams = {
  sku: number | string
  slug: string
  productId: string
  price: number
}

export const buildLabelStudioURL = ({ productId, price, sku, slug }: BuildURLParams) => {
  const data = {
    productId,
    price,
    token: secrets.JW_LABEL_STUDIO_TOKEN,
    currencyId: 'USD',
    locale: 'es',
    callbackUrl: `${window.location.origin}/jw-label`,
    custom_sku: sku,
    custom_location: slug,
  }

  return `${secrets.JW_LABEL_STUDIO_URL}?${qs.stringify(data)}`
}

export const modalMountAnimation = keyframes`
from {
    opacity: 0;
}

to {
    opacity: 1;
}
`
