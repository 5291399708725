import { AxiosResponse } from 'axios'
import client from './client'
import { toWeekDay } from '../../utils/formatDate'

type Country = 'CL' | 'BO'
type Params = { lat: number; long: number; marketId: number; zoneId: number; country: Country }

type Capacity = { currentCapacity: number; date: string; maxCapacity: number }
export type AvailableDate = {
  value: string
  label: string
  weekDay: number
}

export type ShippingCost = {
  id: number
  shippingCost: number
  freeOver: number
}

/**
 * Check if the address is in range
 * @param lat - latitude
 * @param long - longitude
 * @param id - zoneID
 */
const checkAddress = async ({ lat, long, marketId, zoneId, country }: Params): Promise<boolean> => {
  const { data } = await client(`/markets/${marketId}/zones/${zoneId}/check-limit`, {
    method: 'GET',
    headers: { country },
    params: {
      latitude: lat,
      longitude: long,
    },
  })

  return data.valid
}

/**
 * Get available dates for delivery
 * @param {number} marketId
 * @param {number} zoneId
 * @param {Country} country
 */
const getAvailableDates = async (marketId: number, zoneId: number, country: Country): Promise<AvailableDate[]> => {
  try {
    const { data }: AxiosResponse<{ capacities: Capacity[] }> = await client(
      `/markets/${marketId}/zones/${zoneId}/capacity`,
      {
        method: 'GET',
        headers: { country },
      },
    )

    // Parse dates
    const parsedDates = data.capacities.map(({ date }: { date: string }) => {
      const { label, weekDay } = toWeekDay(date)

      return { value: date, label, weekDay }
    })

    return parsedDates
  } catch (error) {
    throw new Error(error)
  }
}
const getShippingCosts = async (): Promise<ShippingCost[]> => {
  try {
    const { data }: AxiosResponse<ShippingCost[]> = await client(`/distribution-centers/shipping-costs`, {
      method: 'GET',
    })
    return data.map((shippingCost) => {
      return {
        id: shippingCost.id,
        shippingCost: Number(shippingCost.shippingCost),
        freeOver: Number(shippingCost.freeOver),
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export { checkAddress, getAvailableDates, getShippingCosts }
