import { CreatePageArgs, Node } from 'gatsby'
import { AlgoliaProduct } from '@ecommerce/shared'
import { DistributionCenter, Market, Zone } from '../../../../../libs/shared/src/services/Location/cities'
import { formatImageUrl } from '../../graphql/locationProductCatgoriesQuery'
import { FlatLocationProductCategory } from '../../types/PgPages'
import secrets from '../secrets'

export const getMarketsFromNodes = (nodes: Node[]): Market[] => {
  return nodes
    .filter((node) => node.internal.type === 'Market')
    .map((node) => {
      const content = node.internal?.content ?? '{}'

      if (!content) throw new Error(`Node has no content`)

      return { ...JSON.parse(content), id: node.cityId }
    })
}

type LocationNode = Node & {
  slug: string
  productCategories___NODE?: string[]
}

type SubcategoryNode = Node & {
  label: string
  attributeToFilter: string
  categorySlug?: string
  filters___NODE: string[]
}

type FiltersNode = Node & {
  name: string
  filter: string
  label: string
}

type CategoryNode = Node & {
  id: string
  name: string
  title?: string
  description?: string
  keywords?: string[]
  mainCategoryNames: string[]
  slug: string
  image___NODE?: string
  subcategories___NODE?: string[]
}

type ImageNode = Node & {
  id: string
  file: {
    url: string
  }
}

const toHashList = <T extends { id: string }>(arr: T[]) =>
  arr.reduce<Record<string, T>>((hashed, element) => ({ ...hashed, [element.id]: element }), {})

const flatten = <T>(arr: T[][]) => arr.reduce<T[]>((flattened, el) => [...flattened, ...el], [])

export const getLocationProductCategoriesFromNodes = ({
  getNodes,
  getNode,
}: Pick<CreatePageArgs, 'getNodes' | 'getNode'>) => {
  const locationNodes = (getNodes().filter(
    (node) => node.internal.type === 'ContentfulLocation',
  ) as LocationNode[]).filter((node) => node.productCategories___NODE?.length)
  const categoryIds = Array.from(
    new Set(flatten(locationNodes.map((node) => node.productCategories___NODE ?? []))),
  ) as string[]
  const categoryNodes = categoryIds.map((id) => getNode(id)) as CategoryNode[]
  const hashedCategoryNodes = toHashList(categoryNodes)
  const subcategoriesIds = categoryNodes
    .map((node) => node.subcategories___NODE)
    .flat()
    ?.filter((id) => !!id) as string[]
  const subcategoriesNodes = subcategoriesIds.map((id) => getNode(id)) as SubcategoryNode[]
  const hashedSubcategoryNodes = toHashList(subcategoriesNodes)

  const filtersIds = subcategoriesNodes
    .map((node) => node.filters___NODE)
    .flat()
    ?.filter((id) => !!id) as string[]
  const filtersNodes = filtersIds.map((id) => getNode(id)) as FiltersNode[]
  const hashedFiltersNodes = toHashList(filtersNodes)

  const imageIds = categoryNodes.map((node) => node.image___NODE).filter((id) => !!id) as string[]
  const imageNodes = imageIds.map((id) => getNode(id)) as ImageNode[]
  const hashedImageNodes = toHashList(imageNodes)
  return locationNodes.reduce<Record<string, FlatLocationProductCategory[]>>((hashed, location) => {
    return {
      ...hashed,
      [location.slug]:
        location.productCategories___NODE
          ?.map(
            (categoryId): FlatLocationProductCategory => {
              const category = hashedCategoryNodes[categoryId]
              return {
                name: category.name,
                slug: category.slug,
                title: category.title,
                description: category.description,
                keywords: category.keywords,
                mainCategoryNames: category.mainCategoryNames,
                image: formatImageUrl(
                  category.image___NODE ? hashedImageNodes[category.image___NODE].file.url ?? '' : '',
                ),
                subcategories:
                  category.subcategories___NODE
                    ?.map((subcategory) => {
                      const data = hashedSubcategoryNodes[subcategory]

                      return {
                        label: data.label,
                        attributeToFilter: data.attributeToFilter,
                        categorySlug: data.categorySlug,
                        filters:
                          data.filters___NODE
                            ?.map((filter) => {
                              const filterData = hashedFiltersNodes[filter]

                              return {
                                name: filterData.name,
                                filter: filterData.filter,
                                label: filterData.label,
                              }
                            })
                            .filter((filter) => !!filter) ?? [],
                      }
                    })
                    .filter((subcategory) => !!subcategory) ?? [],
              }
            },
          )
          .filter((category) => !!category) ?? [],
    }
  }, {})
}

export const getDefaultMarket = async (markets: Market[]) =>
  markets.filter((market) => market.slug === secrets.DEFAULT_MARKET)[0]

export const getMarketByZone = async (markets: Market[], zoneName: string) =>
  markets.find((market) => market.zones.some((zone) => zone.name === zoneName))

export const getDefaultZone = async (zones: Zone[]) => zones.filter((zone) => zone.name === secrets.DEFAULT_ZONE)[0]

export const getDefaultDistributionCenter = async (distributionCenters: DistributionCenter[]) =>
  distributionCenters.filter((distributionCenter) => distributionCenter.slug === secrets.DEFAULT_DISTRIBUTION_CENTER)[0]

export const generateProductUrl = (title: string, skuCode: string): string => {
  const nameWithoutSpecialCharacters = title
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/--/g, '-')
    .toLowerCase()
  return `${nameWithoutSpecialCharacters.toLowerCase()}-${skuCode}/`
}
