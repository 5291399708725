import { loadState, removeState, saveState } from './localStorage'
import { CustomerGroups, DistributionCenter, Market, ShippingCost, ShoppingCartContext, Zone } from '../..'
import { AuthState } from '../context/Auth/context'
import { loadSession, saveSession } from './sessionStorage'

export const authKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  accessTokenExpirationTimestamp: 'accessTokenExpirationTimestamp',
  auth: 'auth-v5',
  anonymousToken: 'anonymousToken',
}
export type AuthKeys = keyof typeof authKeys
// Add new namespaced keys here
export const cartKeys = {
  cart: 'cart-v4', // Todo: will be deprecated
  cartId: 'cart-id',
  orderId: 'order-id',
}
export type cartKeys = keyof typeof cartKeys

export const userKeys = {
  isAdult: 'user-is-adult',
}
export type userKeys = keyof typeof userKeys

export const locationKeys = {
  distributionCenterVersion: 'diccenver',
  distributionCenter: 'distributionCenter',
  market: 'cityV5',
  zone: 'zone',
  shippingCost: 'shippingCost',
}
export type locationKeys = keyof typeof locationKeys

export const storeKeys = { ...authKeys, ...cartKeys, ...userKeys } // Add all key namespaces here
export type storeKeys = keyof typeof storeKeys
export const selectedSessionCity = {
  isSelected: 'isSelectedCity',
}

// Getters
export const getAuth = () => !!loadState(authKeys.refreshToken) && !!loadState<AuthState>(authKeys.auth)?.email
export const getLogged = () => !!loadState<AuthState>(authKeys.auth)?.isAuth
export const getOwnerId = () => {
  const auth = loadState<AuthState>(authKeys.auth)
  if (auth?.ownerId) return auth.ownerId
  return null
}
export const getGuest = () => {
  const auth = loadState<AuthState>(authKeys.auth)
  if (auth?.guest) return auth.guest
  return { firstName: undefined, lastName: undefined }
}
export const getCustomerEmail = () => {
  const auth = loadState<AuthState>(authKeys.auth)
  if (auth?.email) return auth.email
  return null
}
export const getAuthName = (): { firstName: string; lastName: string } => {
  const auth = loadState<AuthState>(authKeys.auth)
  if (auth?.firstName && auth?.lastName) return { firstName: auth.firstName, lastName: auth.lastName }
  return { firstName: '', lastName: '' }
}
export const getBirthdate = (): string | null => {
  return loadState<AuthState>(authKeys.auth)?.birthdate || null
}
export const getDni = (): string | null => {
  return loadState<AuthState>(authKeys.auth)?.dni || null
}
export const getCustomerGroup = (): CustomerGroups | undefined => {
  return loadState<AuthState>(authKeys.auth)?.customerGroup || undefined
}
export const getAccessToken = () => loadState<string>(authKeys.accessToken)
export const getRefreshToken = () => loadState<string>(authKeys.refreshToken)
export const getAccessTokenExpirationTimestamp = () => loadState<number>(authKeys.accessTokenExpirationTimestamp)

export const getIsUserAdult = () => loadState(userKeys.isAdult) ?? false
export const saveIsUserAdult = () => saveState(userKeys.isAdult, 'true')

export const saveSelectedSessionMarket = () => saveSession(selectedSessionCity.isSelected, 'true')
export const getSelectedSessionCity = () => {
  return loadSession(selectedSessionCity.isSelected) ?? false
}

export const getOrderId = () => loadState<string>(cartKeys.orderId)
export const getCartId = () => loadState<string>(cartKeys.cartId)

export const getStoredDistributionCenterVersion = () => {
  return loadState<number>(locationKeys.distributionCenterVersion)
}
export const getStoredDistributionCenter = () => {
  return loadState<DistributionCenter>(locationKeys.distributionCenter) ?? null
}

export const getStoredMarket = () => {
  return loadState<Market>(locationKeys.market) ?? null
}

export const getStoredZone = () => {
  return loadState<Zone>(locationKeys.zone) ?? null
}

export const getStoredShippingCost = () => {
  return loadState<ShippingCost>(locationKeys.shippingCost) ?? null
}

export const getStoredCartState = () => loadState<ShoppingCartContext['state']>(cartKeys.cart)

// setters
export const setAccessToken = (token: string) => saveState(authKeys.accessToken, token)
export const setAccessTokenExpirationTimestamp = (timestamp: number) =>
  saveState(authKeys.accessTokenExpirationTimestamp, timestamp)

export const setStoredDistributionCenterVersion = (distributionCenterVersion: number) =>
  saveState(locationKeys.distributionCenterVersion, distributionCenterVersion)
export const setStoredDistributionCenter = (distributionCenter: DistributionCenter) =>
  saveState(locationKeys.distributionCenter, distributionCenter)
export const setStoredMarket = (market: Market) => saveState(locationKeys.market, market)
export const setStoredZone = (zone: Zone) => saveState(locationKeys.zone, zone)

export const setStoredShippingCost = (shippingCost: ShippingCost) => saveState(locationKeys.shippingCost, shippingCost)

export const setOrderId = (orderId: string) => saveState(cartKeys.orderId, orderId)
export const setCartId = (cartId: string) => saveState(cartKeys.cartId, cartId)

export const setStoredCartState = (cartState: ShoppingCartContext['state']) => saveState(cartKeys.cart, cartState)

// actions
export const cleanAuth = () => {
  Object.values(authKeys).forEach((key) => removeState({ key }))
}

export const cleanRefreshToken = () => {
  removeState({ key: authKeys.refreshToken })
}

export const cleanCartId = () => removeState({ key: cartKeys.cartId })

export const cleanShoppingCart = () => {
  removeState({ key: cartKeys.orderId })
  removeState({ key: cartKeys.cart })
}

export const cleanOrderId = () => removeState({ key: cartKeys.orderId })

export const signOutStoreClear = () => {
  const signoutKeys = { ...authKeys }
  Object.values(signoutKeys).forEach((key) => removeState({ key }))
}

export const signOutRedirect = () => {
  signOutStoreClear()
  window.location.href = `${window.location.origin}/`
}
export const cleanStore = () => {
  Object.values(storeKeys).forEach((key) => removeState({ key }))
}

export const cleanStoreOnRedirect = (
  clearCart = true,
  clearLocation = true,
  clearAuth = true,
  clearZone = true,
  clearDistributionCenter = true,
  clearDistributionCenterVersion = true,
) => {
  const cartStoreKeys = clearCart ? cartKeys : {}
  const locationStoreKeys =
    clearLocation && clearZone && clearDistributionCenter && clearDistributionCenterVersion ? locationKeys : {}
  const authStoreKeys = clearAuth ? authKeys : {}
  const keys: { [key: string]: string } = { ...cartStoreKeys, ...locationStoreKeys, ...authStoreKeys }

  Object.values(keys).forEach((key) => {
    if (key) removeState({ key })
  })
}

export const setAnonymousToken = (token: string) => saveState(authKeys.anonymousToken, token)
export const getAnonymousToken = () => loadState<string>(authKeys.anonymousToken)
