type OnError = (error: any) => { resolve?: boolean; payload?: any } | void

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export async function autoRetry<T>(
  promise: Promise<T>,
  retryTimeout = 0,
  onError?: OnError,
  maxAtempts = 3,
  currentAtempt = 1,
) {
  try {
    const data = await promise
    return data
  } catch (e) {
    const interrupt = onError ? onError(e) : false
    if (interrupt) {
      if (interrupt.resolve) {
        return interrupt.payload
      }
      throw interrupt.payload ?? e
    } else if (currentAtempt < maxAtempts) {
      const retry = async () => {
        await autoRetry(promise, retryTimeout, onError, maxAtempts, currentAtempt + 1)
      }
      if (retryTimeout)
        setTimeout(async () => {
          await retry()
        }, retryTimeout)
      else await retry()
    } else {
      throw e
    }
  }
}
