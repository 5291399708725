import React from 'react'
import styled, { css } from 'styled-components'
import { RadioButtonSize, RadioInput as Input } from '../../../types'
import { breakpoints } from '../../../utils/breakpoints'

type Props = {
  items: Input[]
  name: string
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>, label?: string) => void | unknown
  dataTest?: string
  isDisabled?: boolean
  addBorders?: boolean
  radioSize?: RadioButtonSize
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div<{ addBorders: boolean; hasImage: boolean; inputType: RadioButtonSize }>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: 20px;

  @media (${breakpoints.phoneSmallLandscape.min}) {
    align-items: center;
  }

  ${({ addBorders }) =>
    addBorders &&
    css`
      padding: 16px 10px;
      border: 1px solid ${({ theme }) => theme.colors.grey};
      border-radius: 5px;
    `}

  span {
    color: ${({ theme }) => theme.colors.black};
    flex-grow: 1;

    @media (${breakpoints.phoneSmall.max}) {
      line-height: ${({ inputType }) => (inputType === RadioButtonSize.BIG ? '24px' : '20px')};
      max-width: calc(100% - ${({ hasImage }) => (hasImage ? '152px' : '40px')});
      align-self: center;
      justify-content: center;

      ${({ hasImage }) =>
        hasImage &&
        css`
          padding-right: 10px;
        `}
    }
  }

  img {
    max-width: 120px;
    max-height: ${({ inputType }) => (inputType === RadioButtonSize.BIG ? '35px' : '24px')};
    object-fit: contain;

    @media (${breakpoints.phoneSmall.max}) {
      align-self: center;
      width: 120px;
    }
  }

  p {
    width: 100%;
    font-size: 15px;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const RadioInput = styled.input.attrs({ type: 'radio' })<{ radioSize?: RadioButtonSize }>`
  background: transparent;
  cursor: pointer;
  appearance: none;
  width: ${({ radioSize }) => radioSize};
  min-width: ${({ radioSize }) => radioSize};
  max-width: ${({ radioSize }) => radioSize};
  height: ${({ radioSize }) => radioSize};
  min-height: ${({ radioSize }) => radioSize};
  max-height: ${({ radioSize }) => radioSize};
  border-radius: 50%;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.red};
  margin-right: ${({ radioSize }) => (radioSize === RadioButtonSize.BIG ? '8px' : '16px')};

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.colors.red};
    left: calc(50% - 4px);
    top: calc(50% - 4px);
    border-radius: 50%;
    opacity: 0;
    transition: 0.05s;
  }

  &:checked {
    &::after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

const RadioButtons = ({
  items,
  onChange,
  name,
  className,
  dataTest,
  isDisabled = false,
  addBorders = false,
  radioSize = RadioButtonSize.NORMAL,
}: Props) => (
  <Wrapper className={className}>
    {items?.map(({ value, label, checked, descriptionText, image }, index) => (
      <div key={label}>
        <InputWrapper addBorders={!!addBorders} hasImage={!!image} inputType={radioSize}>
          <RadioInput
            data-test={`${dataTest}-${index}`}
            onChange={(e) => onChange(e, label)}
            value={value}
            name={name}
            defaultChecked={checked}
            disabled={isDisabled}
            radioSize={radioSize}
          />
          <span>{label}</span>
          {image && <img src={image} alt={label} />}
          {descriptionText && <p>{descriptionText}</p>}
        </InputWrapper>
      </div>
    ))}
  </Wrapper>
)

export default RadioButtons
