import { FirebaseState } from './context'

export type SET_FIREBASE_INSTANCE = { type: 'SET_FIREBASE_INSTANCE'; instance: typeof FirebaseState.firebaseInstance }
export type SET_USER_ID = { type: 'SET_USER_ID'; userId: string }

export type FirebaseActions = SET_FIREBASE_INSTANCE | SET_USER_ID

function reducer(state: FirebaseState, action: FirebaseActions): FirebaseState {
  switch (action.type) {
    case 'SET_FIREBASE_INSTANCE': {
      return { ...state, firebaseInstance: action.instance }
    }
    case 'SET_USER_ID': {
      return { ...state, userId: action.userId }
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

export default reducer
