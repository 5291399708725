import React from 'react'
import styled, { css } from 'styled-components'
import { Tag } from '@ecommerce/chile-customer-webapp/src/components/Tag'
import { ProductCardType } from '../../../types'
import { breakpoints } from '../../../utils/breakpoints'
import { ReturnabilityType } from '../../../utils/returnability'

export const cssPrefix = 'ProductCard__'

export interface StyleProps {
  type?: ProductCardType
  off?: boolean
  isBundle?: boolean
  isRecyclable?: boolean
  hasDiscount?: boolean
  bgColor?: string
  hasLabel?: boolean
  hasStock?: boolean
  isDummy?: boolean
  notify?: boolean
  isLoading?: boolean
  isAuth?: boolean
  tag?: string
  types?: string[]
  isHome?: boolean
}

export type ReturnabilityTagProps = {
  returnabilityType?: ReturnabilityType
}

const getMobileStyles = () => {
  return css`
    flex-direction: column;
    align-items: center;
    width: 347px;

    .${cssPrefix} {
      &content {
        position: relative;
        height: 170px;
      }

      &left-content {
        height: 170px;
        width: 149px;
        min-width: 149px;
        position: relative;
      }

      &content-bottom {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &image {
        width: 149px;
        height: 149px;
      }

      &title {
        letter-spacing: -0.06px;
        -webkit-line-clamp: 3;
      }

      &discount-wrapper {
        justify-content: flex-start;
      }

      &final-price {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.254118px;
      }

      &action-buttons {
        gap: 7px;
      }

      &add-button {
        height: 35px;
        min-width: 114px;
      }
    }
  `
}

const getTabletStyles = ({ tag, isHome }: { tag: string | undefined; isHome: boolean | undefined }) => {
  return css`
    flex-direction: column;
    width: 100%;
    padding: 0;

    .${cssPrefix} {
      &product-card-content {
        flex-direction: column;
        gap: 8px;
        padding: ${isHome ? '0px 16px' : '16px 16px 0px 16px'};
      }

      &content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex-grow: 1;
        gap: 8px;
      }

      &left-content {
        display: flex;
        flex-direction: column;
        padding: 0;
        min-width: 224px;
        width: 100%;
        height: auto;
      }

      &tag {
        margin-block-start: 8px;
        margin-inline-start: 8px;
      }

      &image {
        width: 160px;
        align-self: center;
        height: 160px;
        ${() => {
          if (!tag) {
            return css`
              margin-block-start: ${isHome ? '28px' : '33px'};
            `
          }
        }}
      }

      &recyclable-badge,
      &custom-label-support-text {
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        height: 28px;
      }

      &content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex-grow: 1;
      }

      &title {
        height: 48px;
        text-align: center;
        margin-inline: 24px;
        margin-block: 0;
        letter-spacing: 0.1px;
        -webkit-line-clamp: 2;
      }

      &content-bottom {
        gap: 0;
        position: initial;
      }

      &discount-wrapper {
        height: 17px;
        justify-content: center;
        margin-block-end: 8px;
      }

      &bottom {
        display: flex;
        justify-content: center;
      }

      &final-price {
        margin-block: 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.0970588px;
      }

      &action-buttons {
        justify-content: center;
        gap: 8px;
        padding-inline: 24px;
      }

      &add-button {
        width: 100%;
      }
    }
  `
}

const getDesktopStyles = () => {
  return css`
    width: 100%;

    .${cssPrefix} {
      &product-card-content {
        padding: 0px 16px;
      }

      &image {
        width: 203px;
        height: 203px;
      }

      &recyclable-badge,
      &custom-label-support-text {
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.06px;
      }

      &title {
        margin-inline: 32px;
        margin-block: 0;
        font-size: 16px;
      }

      &discount-badge {
        height: 22px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.6px;
        text-align: center;
      }

      &discount-price {
        font-size: 14px;
        line-height: 16.8px;
        letter-spacing: 0.1px;
      }

      &action-buttons {
        padding-inline: 32px;
      }

      &final-price {
        font-size: 20px;
      }

      &tag {
        margin-inline-start: 0px};
      }
    }
  `
}

export const ProductCardWrapper = styled.div<StyleProps>`
  display: flex;
  position: relative;
  background: ${({ theme, bgColor }) => bgColor ?? theme.colors.white};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.platinum20};

  :hover,
  ::selection {
    box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
    cursor: pointer;
  }

  .${cssPrefix} {
    &product-card-content {
      display: flex;
      gap: 10px;
      padding: 16px 16px 4px 16px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &content {
      display: flex;
      gap: 15px;
      width: 100%;
    }

    &recyclable-badge,
    &custom-label-support-text {
      position: absolute;
      bottom: 0;
      display: grid;
      place-content: center;
      font-weight: bold;
      height: 21px;
      width: 149px;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 0.65px;
    }

    &custom-label-support-text {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blue80};
    }

    &recyclable-badge {
      color: ${({ theme, isBundle }) => (isBundle ? theme.colors.green : theme.colors.white)};
      background-color: ${({ theme, isBundle }) => (isBundle ? theme.colors.grey : theme.colors.green)};
    }

    &content-top {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &title {
      color: ${({ theme }) => theme.semanticColors.text.body.primary};
      margin-top: 0;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      max-height: 48px;
      height: 48px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &content-bottom {
      position: absolute;
      width: 100%;
      bottom: 0;
      gap: 2px;
    }

    &discount-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &discount-badge {
      height: 17px;
      padding-block: 2px;
      padding-inline: 8px;
      border-radius: 4px;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.green};
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.055px;
      text-align: center;
    }

    &discount-price {
      color: ${({ theme }) => theme.colors.platinum80};
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1px;
      text-decoration-line: line-through;
      margin-block: 0;
      padding-block: 1.5px;
    }

    &final-price {
      margin-block: 0;
      font-weight: bold;
    }

    &action-buttons {
      display: flex;
      gap: 7px;
    }

    &add-button {
      height: 35px;
      min-width: 114px;
      width: 100%;
    }

    &add-button-quantity-is-0 {
      width: 100%;
      min-width: 114px;
      justify-content: center;
    }

    &add-favorite {
      background: ${({ theme }) => theme.colors.grey20};
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: ${({ theme }) => theme.borderRadius};
      cursor: pointer;
      padding-inline: 7px;
      padding-block-start: 7.87px;
      padding-block-end: 6.13px;

      &.is-favorite {
        svg {
          fill: ${({ theme }) => theme.colors.red80};
        }
      }
    }
  }
  ${() => getMobileStyles()}

  ${({ tag, isHome }) => {
    return css`
      @media screen and (${breakpoints.tabletPortrait.min}) {
        ${getTabletStyles({
          tag,
          isHome,
        })}
      }
    `
  }}

  ${() => {
    return css`
      @media screen and (${breakpoints.desktop.min}) {
        ${getDesktopStyles()}
      }
    `
  }}

  ${({ type, tag, isHome }) => {
    if (type === 'large') {
      return css`
        ${() => getTabletStyles({ tag, isHome })}

        @media screen and (${breakpoints.tabletPortrait.min}) {
          .${cssPrefix} {
            &image {
              ${() => {
                if (!tag) {
                  return css`
                    margin-block-start: ${isHome ? '33px' : '33px'};
                  `
                }
              }}
            }
          }
        }

        @media screen and (${breakpoints.desktop.min}) {
          ${() => getDesktopStyles()}
        }
      `
    }
  }}
`

export const PromotionLabelWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;

  .shape {
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 15px;
    font-weight: bold;
    padding: 0;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 104px solid transparent;
    border-top: 104px solid ${({ theme }) => theme.colors.black};

    span {
      display: block;
      white-space: nowrap;
    }

    .content-text {
      height: min-content;
      padding-right: 50px;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      left: 0;

      .label-text {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .promotionTooltip {
    position: absolute;
    bottom: 115px;
    width: 242px;
    display: none;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .promotionTooltip {
      display: block;
    }
  }
`

export const PromotionTagStyled = styled(Tag)`
  background: red;
`

export const HeartIcon = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none">
    <path
      d="M18.2349 4.17915C17.7879 3.73203 17.2573 3.37734 16.6733 3.13534C16.0893 2.89335 15.4633 2.7688 14.8311 2.7688C14.1989 2.7688 13.5729 2.89335 12.9889 3.13534C12.4049 3.37734 11.8743 3.73203 11.4274 4.17915L10.4999 5.10665L9.57236 4.17915C8.66963 3.27642 7.44526 2.76927 6.16861 2.76927C4.89196 2.76927 3.66759 3.27642 2.76486 4.17915C1.86213 5.08188 1.35498 6.30624 1.35498 7.5829C1.35498 8.85955 1.86213 10.0839 2.76486 10.9866L3.69236 11.9141L10.4999 18.7216L17.3074 11.9141L18.2349 10.9866C18.682 10.5397 19.0367 10.0091 19.2787 9.42508C19.5207 8.84106 19.6452 8.21507 19.6452 7.5829C19.6452 6.95072 19.5207 6.32474 19.2787 5.74071C19.0367 5.15668 18.682 4.62606 18.2349 4.17915V4.17915Z"
      stroke="#FF4F4F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ReturnabilityTag = styled.p<ReturnabilityTagProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 20px;
  gap: 4px;
  background: ${({ theme, returnabilityType }) => {
    if (returnabilityType === ReturnabilityType.NONE) {
      return 'transparent'
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE) {
      return theme.colors.returnability
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
      return theme.colors.skyBlue
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
      return theme.colors.blue
    }
  }};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin: 0px;
  padding: 4px;

  @media screen and (${breakpoints.desktop.min}) {
    font-size: 12px;
    height: 24px;
  }
`
