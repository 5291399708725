import usePortal from 'react-useportal'

export function useModal() {
  const { isOpen, togglePortal, openPortal, closePortal, Portal, ref } = usePortal({
    closeOnOutsideClick: false,
  })

  return {
    Modal: Portal,
    onToggleModal: togglePortal,
    onOpenModal: openPortal,
    onCloseModal: closePortal,
    isOpen,
    ref,
  }
}
export type useModal = ReturnType<typeof useModal>
