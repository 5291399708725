import { createAddress } from './addresses'
import { setShippingDetails } from './shipments'
import { addPaymentSource } from './payments'
import { setOrderCheckoutData } from '../BFF/orders'
import { Order, PaymentStatus, PaymentTypes } from '../../types'
import { CLOrder, CountryCode, Geocode, getOrder, Market } from '../../..'
import { Zone } from '../Location/cities'

interface ProcessOrderCheckoutParams {
  orderData: Order
  currentMarket: Market
  currentZone: Zone
  currentCountryCode: string
  geocode?: Geocode
  orderId: string
  differentBillingAddress: boolean
  shippingMethod: string
  paymentMethod: string
  isLogged?: boolean
  country: CountryCode
  guest: { firstName?: string; lastName?: string }
}

const getShippingAddressId = async (
  order: CLOrder,
  params: ProcessOrderCheckoutParams,
  guest: { firstName?: string; lastName?: string },
): Promise<string> => {
  if (params.isLogged) {
    if (!params.orderData.addressId) throw new Error('Address id is required')
    return params.orderData.addressId
  }

  if (!guest.firstName || !guest.lastName)
    throw new Error("First name and last name are required (from order's metadata)")
  // Create shipping address
  const shippingAddress = await createAddress({
    line_1: params.orderData.shipping_address,
    city: params.currentMarket.distributionCenters.filter(
      (distributionCenter) => distributionCenter.id === params.currentZone.distributionCenterId,
    )[0].internalName,
    country_code: params.currentCountryCode,
    first_name: guest.firstName,
    last_name: guest.lastName,
    state_code: params.currentMarket.id.toString(),
    zip_code: params.currentZone.name,
    phone: params.orderData.shipping_phone,
    notes: params.orderData.shipping_instructions,
    lat: params.geocode?.latitude,
    lng: params.geocode?.longitude,
    metadata: {
      alias: params.orderData.shipping_name,
      zoneId: params.currentZone.id,
      favorite: false,
      marketId: params.currentMarket.id,
      zoneName: params.currentZone.name,
      marketName: params.currentMarket.name,
    },
  })

  return shippingAddress.data.data.id
}

const getPaymentMethod = (paymentType?: PaymentTypes, paymentId?: string | number) => {
  if (paymentType && paymentType === PaymentTypes.CONTRA_ENTREGA) return paymentId

  return paymentType
}

export const processOrderCheckout = async (params: ProcessOrderCheckoutParams): Promise<void> => {
  const {
    orderId,
    orderData: { payment_type: paymentType, payment: paymentOptionId, billing },
    guest,
  } = params

  const {
    data: { data: order },
  } = await getOrder({ orderId, country: params.country })

  if (order.attributes.status === 'placed') throw new Error('Order is already placed')

  // Associate to order
  const addressId = await getShippingAddressId(order, params, guest)
  let billingAddressId = addressId
  const metadata = {
    paymentMethod: getPaymentMethod(paymentType, paymentOptionId),
    paymentStatus:
      paymentType === PaymentTypes.CONTRA_ENTREGA || paymentType === PaymentTypes.QR
        ? PaymentStatus.PENDING
        : undefined,
  }

  if (params.differentBillingAddress) {
    if (!billing) {
      throw new Error('Falta dirección de facturación')
    }

    const billingAddress = await createAddress({
      line_1: billing.billing_address,
      city: params.currentMarket.distributionCenters.filter(
        (distributionCenter) => distributionCenter.id === params.currentZone.distributionCenterId,
      )[0].internalName,
      country_code: params.currentCountryCode,
      first_name: billing.billing_first_name,
      last_name: billing.billing_last_name,
      state_code: billing.billing_city_code ?? params.currentMarket.id.toString(),
      zip_code: params.currentZone.name,
      phone: params.orderData.shipping_phone,
      metadata: {
        dni: billing?.billing_user_id,
      },
    })

    billingAddressId = billingAddress.data.data.id
  }

  const { shipments } = await setOrderCheckoutData({
    orderId: params.orderId,
    oldMetadata: order.attributes.metadata,
    newMetadata: metadata,
    billingAddressId,
    shippingAddressId: addressId,
    paymentMethodId: params.paymentMethod,
  })

  const shippingDetailsTasks = shipments.map((shipment) =>
    setShippingDetails(shipment.id, params.shippingMethod, params.orderData.date),
  )

  const paymentSourceParams = {
    orderId,
    paymentOptionId: Number(paymentOptionId),
    referenceOrigin:
      paymentType === PaymentTypes.CONTRA_ENTREGA ||
      paymentType === PaymentTypes.QR ||
      paymentType === PaymentTypes.GIFT_CARD ||
      paymentType === PaymentTypes.GIFT_CARD_MACH ||
      paymentType === PaymentTypes.GIFT_CARD_CHEK ||
      paymentType === PaymentTypes.GIFT_CARD_WEBPAY ||
      paymentType === PaymentTypes.GIFT_CARD_FLOWPAY
        ? `${paymentOptionId}-${paymentType}`
        : undefined,
    reference:
      paymentType === PaymentTypes.CONTRA_ENTREGA ||
      paymentType === PaymentTypes.QR ||
      paymentType === PaymentTypes.GIFT_CARD
        ? 'TEMPORAL_REFERENCE'
        : undefined,
  }

  await Promise.all([...shippingDetailsTasks, addPaymentSource(paymentSourceParams)])
}
