import { css } from 'styled-components'
import { Status } from '../components/molecules/TextField/Styled'
import { hexToRGBA } from '../utils/format'

const getBorderColor = (status: Status | undefined) => {
  if (!status) {
    return css`
      border-color: #e8ebf7;
      &:hover,
      &:focus {
        border-color: #030f1c;
      }
      &:focus {
        outline: 0;
      }
    `
  }
  if (status === 'success') {
    return css`
      border-color: ${({ theme }) => theme.colors.green};
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.green};
      }
      &:focus {
        outline: 0;
      }
    `
  }
  if (status === 'error') {
    return css`
      border-color: ${({ theme }) => theme.colors.red};
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.red};
      }
      &:focus {
        outline: 0;
      }
    `
  }
}

const base = (status: Status | undefined) => css`
  display: block;
  width: 100%;
  border: solid 1px;
  padding: 13px 8px;
  border-radius: 0.25rem;
  font-size: 13px; // Will be kept constant by zoom issue on iOS
  line-height: 13px;
  color: #030f1c;

  ${getBorderColor(status)}

  &:disabled {
    background: ${(props) => hexToRGBA(props.theme.colors.black40, 0.25)};
    color: ${(props) => props.theme.colors.platinum80};
  }
`

const basePlaceholder = css`
  &::placeholder {
    color: #5f6f86;
    opacity: 1;
  }
`

export const baseInput = (status: Status | undefined) => css`
  ${base(status)}
  ${basePlaceholder}
`
export const baseSelect = css`
  ${base(undefined)};

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  option[disabled] {
    color: #aaaaaa;
  }

  &::-ms-expand {
    display: none;
  }
`
