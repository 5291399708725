import { createContext } from 'react'
import { Country, Error } from '../../types'
import { LocationActions } from './reducer'
import { DistributionCenter, Market, Zone } from '../../services/Location/cities'
import { ShippingCost } from '../../services/DistributionCenter'

export type ByHashZoneType = {
  market: string
  marketId: string
  marketName: string
} & Zone

export type ByHashMarket = { [K: string]: Market }
export type ByHashZone = { [K: string]: ByHashZoneType }

export const LocationState = {
  isLoading: false,
  error: null as Error | null,
  markets: [] as Market[],
  country: Country.CHILE as Country,

  // FIXME: delete
  byHashCities: {} as ByHashMarket,
  byHashZones: {} as ByHashZone,
  currentCity: null as Market | null,
  currentZone: null as Zone | null,
  distributionCenters: null as DistributionCenter[] | null,
  distributionCenterVersion: null as number | null,
  currentDistributionCenter: null as DistributionCenter | null,
  shippingCost: null as ShippingCost | null,
}

export type LocationState = typeof LocationState
export const LocationContext = createContext<LocationState>(LocationState)
export type LocationDispatch = (action: LocationActions) => void
export const DispatchContext = createContext<LocationDispatch | undefined>(undefined)
