import { AxiosResponse, AxiosPromise } from 'axios'
import fetch from './fetch'
import { Country } from '../../types'
import { CLOrder, CLRetrieveOrderResponse, ResourceType, CLObject } from '../CommerceLayer'
import { log } from '../../utils/log'
import { getStoredDistributionCenter, getStoredMarket } from '../../utils/store'
import secrets from '../../config'
import { PrepareOrderForCheckoutResponse } from './general'
import customerBffFetch from './customer-bff-fetch'

const { COUNTRY } = secrets

interface ListOrdersByCustomerProps {
  customerId: string
  country: Country
}

export const listOrdersByCustomer = async ({ customerId, country }: ListOrdersByCustomerProps): Promise<CLOrder[]> => {
  const response: AxiosResponse<{ data: CLOrder[] }> = await fetch(
    `/customer/orders?customerId=${customerId}&countryCode=${country}`,
  )
  return response.data.data
}

interface GetOrderProps {
  orderId: string
  country: Country
  include?: string
  sparseFields?: string
}

export const getOrder = async ({
  orderId,
  include,
  sparseFields,
  country,
}: GetOrderProps): Promise<AxiosResponse<CLRetrieveOrderResponse>> => {
  const query = encodeURI(
    `/orders/${orderId}?country=${country}${include ? `&include=${include}` : ''}${
      sparseFields ? `&sparseFields=${sparseFields}` : ''
    }`,
  )
  return fetch(query)
}

interface GetFullOrderProps {
  orderId: string
  country: Country
  includeLineItems?: boolean
}

export const getFullOrder = async ({
  orderId,
  includeLineItems = true,
  country,
}: GetFullOrderProps): Promise<CLOrder> => {
  const query = encodeURI(
    `/orders/${orderId}?country=${country}&include=payment_source,shipping_address,shipments${
      includeLineItems ? ',line_items' : ''
    }`,
  )
  const {
    data: { data },
  }: { data: { data: CLOrder } } = await fetch(query)
  return {
    ...data,
    relationships: {
      ...data.relationships,
      line_items: {
        ...data.relationships.line_items,
        objects: data.relationships.line_items.objects?.filter((item) => item.attributes.item_type === 'skus') ?? [],
      },
    },
  }
}

interface UpdateOrderMetadataProps {
  orderId: string
  metadata: Record<string, any>
  country: Country
}

export const updateOrderMetadata = async (props: UpdateOrderMetadataProps) =>
  fetch('/orders/metadata', { data: props, method: 'PATCH' })

interface FinishOrderCheckoutProps {
  orderId: string
  country: Country
  stockLocation: number
  skus: string
}

export const finishOrderCheckout = async (props: FinishOrderCheckoutProps) =>
  fetch(`/orders/checkout/finish`, { method: 'POST', data: props })

export const listOrders = async ({
  country,
  query,
}: {
  country: Country
  query?: string
}): Promise<AxiosResponse<{ data: CLOrder[]; included: any[] }>> => fetch(`/orders?country=${country}&${query ?? ''}`)

type PageSizeRange = 1 | 5 | 10 | 15 | 20 | 25
export async function getLastPendingOrDraftCustomerOrders(
  customerEmail: string,
  country: Country,
  pageSize: PageSizeRange,
  marketNumber = 10,
): Promise<CLOrder[]> {
  const {
    data: { data },
  } = await listOrders({
    country,
    query: `sort=updated_at&filter[q][market_number_eq]=${marketNumber}&filter[q][status_in]=pending,draft&page[size]=${pageSize}&filter[q][customer_email_eq]=${customerEmail}&fields[orders]=status,metadata`,
  })

  if (data.length === 0) return []
  return data
}

export const createOrder = (params?: Partial<Omit<CLOrder, 'type'>>): Promise<AxiosResponse<CLOrder>> => {
  const { ...restParams } = params || {}

  const market = getStoredMarket()
  const distributionCenter = getStoredDistributionCenter()
  if (!market) throw new Error(`Market not found`)

  return fetch('/orders', {
    data: { ...restParams, marketId: distributionCenter?.commerceLayer.market.id, country: COUNTRY },
    method: 'POST',
  })
}

export const setOrderCustomer = (params: {
  orderId: string
  customerEmail: string
}): AxiosPromise<CLRetrieveOrderResponse> => {
  const { orderId, customerEmail } = params

  const data = {
    country: COUNTRY,
    type: 'orders',
    id: orderId,
    attributes: {
      customer_email: customerEmail,
    },
  }

  return fetch(`/orders/${orderId}`, { data, method: 'PATCH' })
}

export const setOrderCheckoutData = async ({
  orderId,
  newMetadata,
  oldMetadata,
  shippingAddressId,
  billingAddressId,
  paymentMethodId,
}: {
  orderId: string
  newMetadata: Record<string, unknown>
  oldMetadata: CLOrder['attributes']['metadata']
  billingAddressId: string
  shippingAddressId: string
  paymentMethodId: string
}) => {
  log.trace('UPDATING ORDER INFO: ', { orderId, newMetadata, oldMetadata, shippingAddressId, billingAddressId })
  if (!oldMetadata.paymentMethod && !newMetadata.paymentMethod)
    throw new Error('setOrderCheckoutData: missing paymentMethodMetadata')
  const start = performance.now()
  const query = `/orders/${orderId}?include=shipments&fields[shipments]=id&fields[orders]=id`
  const data = {
    type: ResourceType.ORDERS,
    id: orderId,
    country: COUNTRY,
    relationships: {
      billing_address: {
        data: {
          type: ResourceType.ADDRESSES,
          id: billingAddressId,
        },
      },
      shipping_address: {
        data: {
          type: ResourceType.ADDRESSES,
          id: shippingAddressId,
        },
      },
      payment_method: {
        data: {
          type: ResourceType.PAYMENT_METHODS,
          id: paymentMethodId,
        },
      },
    },
  }

  const responseList = await Promise.all([
    fetch(query, {
      data,
      method: 'PATCH',
    }),
    updateOrderMetadata({ orderId, metadata: newMetadata, country: COUNTRY }),
  ])

  const {
    data: { included },
  }: AxiosResponse<{ included: CLObject[] }> = responseList[0]

  log.trace(`END UPDATING ORDER INFO: ${Math.trunc((performance.now() - start) / 1000)}s`)

  return { shipments: included }
}

export const getOrderForCheckout = async (id: string, country: Country): Promise<PrepareOrderForCheckoutResponse> => {
  const { data: response } = await fetch(
    encodeURI(`/orders/${id}?country=${country}&useCheckoutFormat=true&include=line_items`),
  )

  return response
}

export const getOrderPaymentStatus = async (orderId: string) => {
  const { data }: AxiosResponse<{ status: string }> = await fetch(`/orders/${orderId}/payment-status`)

  return data
}

// Update order line item and calculate gifts
export const updateOrderLineItem = async (
  orderId: string,
  lineItemId: string,
  quantity: number,
  country: Country,
  market: string,
  distributionCenter: string,
) => {
  return fetch(`/orders-lineitem/${orderId}?country=${country}`, {
    data: {
      lineItemId,
      quantity,
      marketSlug: market,
      distributionCenterSlug: distributionCenter,
    },
    method: 'PATCH',
  })
}

export const getOrderCountToday = async ({ customerId, country }: { customerId: string; country: Country }) => {
  const { data }: AxiosResponse<number> = await customerBffFetch(encodeURI(`/orders/count/${country}/${customerId}`), {
    method: 'GET',
  })

  return data
}
